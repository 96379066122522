 /* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import theme from "../../../../Themes/defaultTheme";

const A_CheckBox = (props) => {
  const [checked, setChecked] = useState(props.checked);
  const handleChange = (event) => {
    console.log("A_CheckBox handleChange is called:", checked);
    let newValue = !checked;
    //console.log(event.target.value);
    setChecked(newValue);
    props.onChange(newValue, props.id);
  };


  return (
    <ThemeProvider theme={theme}>
      <FormControl
        component="fieldset"
        required={props.required}
        error={props.required}
      >
        <FormControlLabel
        className={props.labelClass}
          style={{ color: "black", marginLeft: "0px" }}
          label={props.label}
          labelPlacement={props.labelPlacement}
          value={props.value}
          id={props.id}
          control={
            <Checkbox
              className={props.classes}
              classes={props.allClasses}
              checked={checked}
              name={props.name}
              color={props.color}
              //icon={props.icon}
              checkedIcon={props.checkedIcon}
              disabled={props.disabled}
              required={props.required}
              size={props.size}
              onChange={handleChange}
            />
          }
        />
        {props.helperText ? <FormHelperText>{props.helperText}</FormHelperText>: null}
      </FormControl>
    </ThemeProvider>
  );
};
A_CheckBox.defaultProps = {
  areaLabel: "",
  label: "",
  value: "",
  checked: false,
  name: "",
  color: "default",
  disabled: false,
  size: "small",
  labelClass: '',
  allClasses: {}
};
A_CheckBox.propTypes = {
  areaLabel: PropTypes.string,
  label: PropTypes.string,
  labelPlacement: PropTypes.oneOf(["start", "top", "bottom", "end"]),
  checked: PropTypes.bool,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  size: PropTypes.oneOf(["small", "medium"]),
  onChange: PropTypes.func,
  labelClass: PropTypes.string,
  allClasses: PropTypes.object
};
export default A_CheckBox;
