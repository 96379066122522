import React from "react";
import M_PDFReader from "../Common/PDFReader/M_PDFReader";
export default function ({ pdf, html, redirectlink, directlink }) {
  React.useEffect(() => {
    if (redirectlink && redirectlink != "") {
      window.open(URL, "_blank");
    }
  }, []);
  return (
    <div>
      <M_PDFReader pdf={pdf} directlink={directlink} container="generic" />
      <div className="" dangerouslySetInnerHTML={{ __html: html }}></div>
    </div>
  );
}
