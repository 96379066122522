/* eslint-disable */
import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import PersonIcon from "@material-ui/icons/Person";
import Slide from "@material-ui/core/Slide/Slide";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import userManager from "../../../../../utils/oidc/userManager";
import M_UserAvatar from "./M_UserAvatar";
import A_Divider from "../../../atoms/dataDisplay/dividers/A_Divider";
import A_Paper from "../../../atoms/surfaces/papers/A_Paper";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import { getCurrentUser } from "../../../../../utils/helpers/common";
import TuneIcon from "@material-ui/icons/Tune";
import { withTranslation } from "react-i18next";
import Email from "@material-ui/icons/MailOutline";
import Exit from "@material-ui/icons/ExitToApp";
import Settings from "@material-ui/icons/SettingsOutlined";
import UserPrefrences from "./UserPrefrences";
import { httpPost } from "../../../../../Utils/apis/apis";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import A_Button from "../../../../../components/Factory/Atoms/Inputs/Buttons/A_Button";
import { NavLink } from "react-router-dom";
import {
  excludedClientsForProfileSetting,
  isClientAuthorised,
} from "./M_NavBarhelper";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    marginTop: "-2px",
    zIndex: 999,
    [theme.breakpoints.down("xs")]: {
      marginRight: "-15px",
    },
    //marginRight:'-15px'
  },
  personicon: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "1.2em",
      marginLeft: "-0.5em",
    },
  },
  person: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0%",
    },
    whiteSpace: "nowrap",
    color: "white",
  },

  hideProfileSetting: {
    display: "none",
  },

  nowrap: {
    whiteSpace: "nowrap",
  },
}));

const M_UserArea = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = props;
  const [logoImage, setLogoImage] = useState("");
  const userid = useSelector((state) =>
    state &&
    state.getLoggedInUserReducer &&
    state.getLoggedInUserReducer.data &&
    state.getLoggedInUserReducer.data[0] &&
    state.getLoggedInUserReducer.data[0].id
      ? state.getLoggedInUserReducer.data[0].id
      : ""
  );
  const handleUserMenu = (event) => {
    setAnchorEl(anchorEl !== null ? null : event.currentTarget);
  };

  let loggedInUser = `${user.profile.given_name} ${user.profile.family_name}`;

  const getImage = (userID) => {
    let subdomain = new URL(window.location.href).hostname.split(".")[0];
    fetch(`${process.env.DOCUMENT_HELPER}`, {
      method: "GET",
      headers: {
        filename: "profile_" + userID,
        type: "image",
        "file-operation": "download",
        "process-file": "yes",
        "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
        subdomain: subdomain,
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((success) => success.blob())
      .then((data) => {
        const imageAsUrl = URL.createObjectURL(data);
        setLogoImage(imageAsUrl);
      });
  };
  useEffect(() => {
    if (userid && userid !== "" && userid !== null) {
      getImage(userid);
    }
  }, []);

  const handleClose = (clickedMenu) => {
    setAnchorEl(null);
    switch (clickedMenu) {
      case "logout":
        handleLogout();
        break;
      case "profile_settings":
        handleServerApp_Redirect("profile_settings");
        break;
      case "change_password":
        handleServerApp_Redirect("change_password");
        break;
      default:
        setAnchorEl(null);
    }
  };

  const handleLogout = async () => {
    let data = {
      activityType: "Logout",
      message: "Logged out from system",
    };

    let res = await httpPost(
      `/Common/WriteDBLog?activityType=logout&message=Logged out from system`,
      data
    );
    userManager.signoutRedirect();
    userManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace("/");
    });
  };

  const handleServerApp_Redirect = (redirectTo) => {
    if (redirectTo === "change_password") {
      window.location.href = `${
        process.env.OIDC_AUTHORITY
      }/Account/ForgetPassword?username=${btoa(user.profile.name)}`;
    } else {
      window.location.href = `${
        process.env.OIDC_AUTHORITY
      }/Account/Manage?username=${btoa(user.profile.name)}`;
    }
  };
 
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <React.Fragment>
      <A_Button
        label={loggedInUser ? loggedInUser : ""}
        variant="none"
        startIcon={<PersonIcon className={classes.personicon} />}
        onClick={(e) => handleUserMenu(e)}
        className={classes.person}
      ></A_Button>
      <Popper
        className={classes.paper}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
      >
        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <div>
            <ClickAwayListener onClickAway={(e) => handleUserMenu(e)}>
              <div>
                <A_Paper
                  width={30}
                  // temp fix
                  // height={36}
                  height={"auto"}
                  content={
                    <ProfileMenu
                      handleClose={(e) => handleClose(e)}
                      {...props}
                      logoImage={logoImage}
                    />
                  }
                  size="large"
                />
              </div>
            </ClickAwayListener>
          </div>
        </Slide>
      </Popper>
    </React.Fragment>
  );
};
const ProfileMenu = (props) => {
  //handle profile-menu click event.
  let history = useHistory();
  const classes = useStyles();
  const [radio, setRadio] = useState(false);
  const [currentuser, setCurrentuser] = useState("");
  const [showUser, setShowUser] = useState(false);
  const [value, setValue] = useState("Select");
  let { displayName, userDesignation } = getCurrentUser();
  let loggedInUserDetail =   JSON.parse(localStorage.getItem('contact_crm'));
  const Prefrences = useSelector((state) =>
    state &&
    state.getLoggedInUserReducer &&
    state.getLoggedInUserReducer.data &&
    state.getLoggedInUserReducer.data[0] &&
    state.getLoggedInUserReducer.data[0].properties &&
    state.getLoggedInUserReducer.data[0].properties.IsUserPreferenceActivated
      ? state.getLoggedInUserReducer.data[0].properties
          .IsUserPreferenceActivated
      : loggedInUserDetail &&  loggedInUserDetail.properties && loggedInUserDetail.properties.IsUserPreferenceActivated ? loggedInUserDetail.properties.IsUserPreferenceActivated:""
  );
  const userid = useSelector((state) =>
    state &&
    state.getLoggedInUserReducer &&
    state.getLoggedInUserReducer.data &&
    state.getLoggedInUserReducer.data[0] &&
    state.getLoggedInUserReducer.data[0].id
      ? state.getLoggedInUserReducer.data[0].id
      : loggedInUserDetail && loggedInUserDetail.id
      ? loggedInUserDetail.id
      : ""
  );
  useEffect(() => {
    setCurrentuser(userid ? userid : "");
    getPrefrence();
  }, [userid]);

  async function getPrefrence() {
    setShowUser(Prefrences);
  }
  const { t: translate } = props;
  const handleMenuClick = (clickedMenu) => {
    props.handleClose(clickedMenu);
  };
  const [showProfileSetting, setShowProfileSetting] = useState(
    isClientAuthorised(
      window.location.hostname,
      excludedClientsForProfileSetting
    )
  );

  function mailBox() {
    window.location = `mailto:minervasupport@equipped.ai`;
  }

  async function handleClick() {
    let res = await httpPost(
      `/CRMData/getViewTemplates?pageUrl=contact&crmId=${userid}`
    );
    setValue(
      res.data && res.data.userPreference ? res.data.userPreference : "Select"
    );
    setRadio(true);
  }
  const handleClose = () => {
    setRadio(false);
  };
  const handleClickToRedirect = () => {
    if (currentuser == "") {
      e.preventDefault();
    } else {
      location.assign(`/contact/${currentuser}`);
    }
  };
  return (
    <React.Fragment>
      <List component="nav" aria-label="user area">
        {/* <ListItemLink
           href={`${"/contacts/123"}`}
           onClick={props.handleClose}
           tooltip="View your profile"
         > */}
        <ListItem
          onClick={() => {
            props.handleClose();
          }}
        >
          <ListItemIcon>
            <M_UserAvatar logoImage={props.logoImage} />
          </ListItemIcon>
          <ListItem
            component="a"
            style={{ color: "black", cursor: "pointer" }}
            onClick={handleClickToRedirect}
            disabled={currentuser == "" ? true : false}
          >
            <ListItemText primary={displayName} secondary={userDesignation} />
          </ListItem>
        </ListItem>
        {/* </ListItemLink> */}
        <div className={showProfileSetting ? "" : classes.hideProfileSetting}>
          <A_Divider />
          {/* Commenting this code for now Because This code used in future */}
          {/* <ListItem button onClick={() => handleMenuClick("profile_settings")}>
            <Settings style={{ marginRight: "10px" }} />
            <ListItemText primary={translate("ProfileSettings")} />
          </ListItem> */}
        </div>
        <div className={showProfileSetting ? "" : classes.hideProfileSetting}>
          {/* <A_Divider /> */}
          {showUser === true ? (
            <ListItem button onClick={() => handleClick()}>
              <TuneIcon style={{ marginRight: "10px" }} />
              <ListItemText primary={translate(" Preferences")} />
            </ListItem>
          ) : (
            ""
          )}
        </div>
        <A_SimpleDialog
          open={radio}
          title="User preferences"
          height="110"
          fullWidth="1000px"
          onClose={handleClose}
          dialogContent={
            <UserPrefrences
              values={value}
              LogedUserId={currentuser}
              close={handleClose}
            />
          }
        />
        {/*
         <ListItemLink href="/app_settings/123" onClick={props.handleClose}>
           <ListItemText primary={translate("ApplicationSettings")} />
 
         </ListItemLink>
         <A_Divider />
         <ListItem button onClick={() => handleMenuClick("change_password")}>
           <ListItemText primary={translate("ChangePassword")} />
         </ListItem> */}

        <A_Divider />

        <ListItem
          button
          onClick={() => {
            mailBox("Contact Support");
          }}
        >
          <Email style={{ marginRight: "10px" }} />
          <ListItemText primary={translate("Contact Support")} />
        </ListItem>
        <A_Divider />
        <ListItem button onClick={() => handleMenuClick("logout")}>
          <Exit style={{ marginRight: "10px" }} />
          <ListItemText primary={translate("Logout")} />
        </ListItem>
      </List>
    </React.Fragment>
  );
};
ProfileMenu.propTypes = {
  t: PropTypes.any,
  handleClose: PropTypes.func,
  logoImage: PropTypes.string,
};
M_UserArea.propTypes = {
  callback: PropTypes.func,
  layouts: PropTypes.array,
  currentPageId: PropTypes.string,
  user: PropTypes.object,
};
export default withTranslation()(M_UserArea);
