/* eslint-disable */
import * as React from "react";
import A_Select from "../Selects/A_Select";
import theme from "../../../../Themes/defaultTheme";
import { mergeClasses, ThemeProvider } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formFieldsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "50%",
  },
  formLabelField: {
    minWidth: "150px",
    paddingTop: "28px",
    paddingLeft: "5px",
    width: "155px",
  },
  formControl: {
    minWidth: "150px",
    width: "155px",
  },
}));

const A_FormSelectField = (props) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.formFieldsWrapper}>
        <div className={classes.formLabelField}>Date</div>
        <div>
          <A_Select
            className={classes.formControl}
            label={""}
            variant="standard"
            native={true}
            options={props.options}
            defaultValue={props.value}
            value={props.value}
            displayEmpty={false}
            onChange={props.onChange}
          />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default A_FormSelectField;
