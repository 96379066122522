/* eslint-disable */
import {
    take,
    put
} from "redux-saga/effects";
import axios from "axios";
import {
    dealTeamActionType
} from "../actions/actionTypes";
import * as dealTeamActions from "../actions/dealTeamAction";
import * as commonFunctions from "../../Utils/Helpers/common";
import { setToast } from "../actions/toastActions";
import { toastType, toastSeverity } from "../../Utils/config/toastConfig";
import { message } from "../../Utils/config/messages";

export function* getEdgesSagas() {
    while (true) {
        const action = yield take(dealTeamActionType.GET_DEAL_TEAM_EDGE);
        const  payload  = action.payload;
        let config = commonFunctions.getHttpConfig();
          
        if (
          !config.headers.Authorization ||
          config.headers.Authorization == "Bearer undefined"
        ) {
          const serializedState = sessionStorage.getItem(
            `oidc.user:${process.env.OIDC_AUTHORITY}:${process.env.CLIENT_ID}`
          );
          if (serializedState === null) {
            return undefined;
          }
          const appState = JSON.parse(serializedState);
          if (appState && appState.access_token) {
            config.headers.Authorization = "Bearer " + appState.access_token;
          }
        }
        try {
            const res = yield axios.post(
                `${process.env.API_URL}/CRMData/getEdge`, payload,config
            );
            yield put(
                dealTeamActions.getEdgeSucceeded({
                  data: res,
                })
            );
        } catch (e) {
            yield put(
                dealTeamActions.getEdgeFailed({
                    msg: "Some error occured",
                    getEdgeFailed: true,
                })
            );
        }
    }
}

export function* delEdgeSagas() {
    while (true) {
        const action = yield take(dealTeamActionType.DEL_DEAL_TEAM_EDGE);
        let config = commonFunctions.getHttpConfig();

        if (
          !config.headers.Authorization ||
          config.headers.Authorization == "Bearer undefined"
        ) {
          const serializedState = sessionStorage.getItem(
            `oidc.user:${process.env.OIDC_AUTHORITY}:${process.env.CLIENT_ID}`
          );
          if (serializedState === null) {
            return undefined;
          }
          const appState = JSON.parse(serializedState);
          if (appState && appState.access_token) {
            config.headers.Authorization = "Bearer " + appState.access_token;
          }
        }
        let  id  = action.payload;
        let payload = [...id.payload];
        try {
            const res = yield axios.post(
                `${process.env.API_URL}/CRMData/deleteEdge`, payload,config
            );
            yield put(
                setToast({
                  type: toastType.DELETE_EDGE,
                      severity: toastSeverity.SUCCESS,
                      message: message.EDGE_DELETE_SUCCESS,
                      status: true,
                })
            );
        } catch (e) {
            yield put(
                dealTeamActions.delEdgeFailed({
                    msg: "Some error occured",
                    delEdgeFailed: true,
                })
            );
        }
    }
}

