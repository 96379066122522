 /* eslint-disable */
import React from "react";
import A_TextField from "./A_TextField";

export const renderTextField = ( row,  configurations,  key,{ type = "number", onChange, onBlur, rowIndex } = {}) => {
  const _onChange = (e) => {
    onChange && onChange(e, row, configurations, key)
  }
  const _onBlur = (e) => {
    onBlur && onBlur(e, row, configurations, key, {rowIndex})
  }
  return (
      <>
    <A_TextField
      //className={classes.searchBox}
      onChange={_onChange}
      onBlur={_onBlur}
      label=""
      type={type}
      variant="outlined"
      defaultValue={row[key]}
    />
    </>
  );
}



// export const isNullOrUndefined = (value) => {
//   return typeof value === 'undefined' || value === null;
// }