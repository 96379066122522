/* eslint-disable react/display-name */
/* eslint-disable */
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as httpCall from "../../../../../Utils/apis/apis";
import A_Select from "../../../Atoms/Inputs/Selects/A_Select";
import A_CheckBox from "../../../Atoms/Inputs/Checkboxes/A_Checkbox";
import A_DatePicker from "../../../Atoms/Inputs/DateTimePickers/A_DatePicker";
import TextField from "@material-ui/core/TextField";
import A_Autocomplete from "../../../Atoms/Inputs/Autocompletes/A_Autocomplete";
import A_SummerNote from "../../../Atoms/Inputs/SummerNote/A_SummerNote";
import { getFormattedDate } from "../../../../../Utils/Helpers/dateUtils";
import M_DropableFileUpload from "../../../Molecules/Common/File Upload/M_DropableFileUpload";
import M_RadiosGroupConfig from "../../../Molecules/Common/RadioGroup/M_RadiosGroupConfig";
import { ComputeEngine } from "@cortex-js/compute-engine";
import DisplaySectionHeader from "../DisplaysectionHeader/DisplaySectionHeader";
import { useSelector } from "react-redux";
import { status_Probability } from "../../../../../Utils/Helpers/common";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as manageFormActions from "../../../../../store/actions/manageFormActions";
import M_TabularField from "./M_TabularField";
import A_MultiselectCustom from "../../../Atoms/Inputs/MultiselectCustom/A_MultiselectCustom";
import {
  nameType,
  fieldType,
  regex,
  dealConfig,
} from "../../../../../Utils/config/config";
import * as currentPageActions from "../../../../../store/actions/currentPageActions";
import { useParams } from "react-router";
import {
  lookupDefaultValue,
  updateStatus,
  asyncCheckbox,
} from "./M_FormRendererHelper";
import { handleOptionSetValue } from "../../../../../Utils/Helpers/optionSet.helper";
import { getCRMContactInfo } from "../../../../../Utils/Helpers/common";

const useStyles = makeStyles(() => ({
  container: {
    overflow: "auto",
    overflowX: "hidden",
    height: 500,
    marginTop: "5px",
    marginBottom: "5px",
  },
  inputcontrol: {
    width: 500,
  },
  tableInputcontrol: {
    width: 130,
  },
  inputcontrolTextField1: {
    width: 95,
    margin: -8,
    " underline:before": {
      borderBottom: "none !important",
    },
  },
  inputcontrolTextField: {
    width: 500,
  },
  textColor: {
    color: "darkslategray",
    marginBottom: "1rem",
    marginTop: "1rem",
  },
  inputcontrolsmall: {
    width: 450,
  },
  seperatorRowText: {
    fontSize: "15px",
    fontWeight: "bold",
    height: "30px",
    color: "#927979",
    padding: 5,
    borderRadius: 4,
    textAlign: "left",
  },
}));
const M_FormRenderer = React.memo((props) => {
  const classes = useStyles();
  const optionSets = props.optionSets;
  const [formData, setFormData] = useState(props.lastFormData);
  const [editTabular, setEditTabular] = useState(false);
  const [editFormData, setEditFormData] = useState({});
  const [date, setDate] = useState();
  const user = useSelector((state) => state.oidc.user);
  let { crmId, pageUrl } = useParams();
  const [flag, setFlag] = useState(true);
  //deal's fund coupling with the budget form starts here
  const fundPrimary =
    props &&
    props.elements &&
    props.elements.filter((item) => {
      return item.id && item.id === nameType.CONFIGPRIMARYFUND;
    });
  if (fundPrimary && fundPrimary[0] && fundPrimary[0].id) {
    if (
      (props.currentPage &&
        props.currentPage.data &&
        props.currentPage.data[nameType.VIEWPRIMARYFUND] != undefined) ||
      null
    ) {
      fundPrimary[0].label =
        props.currentPage.data[nameType.VIEWPRIMARYFUND] &&
        JSON.parse(props.currentPage.data[nameType.VIEWPRIMARYFUND])[0].title;
    } else {
      fundPrimary[0].fieldType = "";
    }
  }

  const fundSecondary =
    props &&
    props.elements &&
    props.elements.filter((item) => {
      return item.id && item.id === nameType.CONFIGSECONDARYFUND;
    });
  if (fundSecondary && fundSecondary[0] && fundSecondary[0].id) {
    if (
      (props.currentPage &&
        props.currentPage.data &&
        props.currentPage.data[nameType.VIEWSECONDARYFUND] != undefined) ||
      null
    ) {
      fundSecondary[0].label =
        props.currentPage.data[nameType.VIEWSECONDARYFUND] &&
        JSON.parse(props.currentPage.data[nameType.VIEWSECONDARYFUND])[0].title;
    } else {
      fundSecondary[0].fieldType = "";
    }
  }

  const fundTertiary =
    props &&
    props.elements &&
    props.elements.filter((item) => {
      return item.id && item.id === nameType.CONFIGTERTIARYYFUND;
    });
  if (fundTertiary && fundTertiary[0] && fundTertiary[0].id) {
    if (
      (props.currentPage &&
        props.currentPage.data &&
        props.currentPage.data[nameType.VIEWTERTIARYFUND] != undefined) ||
      null
    ) {
      fundTertiary[0].label =
        props.currentPage.data[nameType.VIEWTERTIARYFUND] &&
        JSON.parse(props.currentPage.data[nameType.VIEWTERTIARYFUND])[0].title;
    } else {
      fundTertiary[0].fieldType = "";
    }
  }

  //deal's fund coupling with the budget form ends here
  ///For Calculative Fields
  function calculativeFunction(calData) {
    var calArray = [];
    for (const item of calData) {
      let itemValue =
        typeof item === fieldType.NUMBER || Array.isArray(item)
          ? [item]
          : item.split(",");
      if (Object.prototype.toString.call(itemValue[0]) === "[object Array]") {
        calArray.push(calculativeFunction(itemValue[0]));
      } else {
        if (typeof formData[itemValue[0]] == "undefined") {
          if (typeof itemValue[0] === fieldType.STRING && itemValue[1]) {
            calArray.push(
              parseFloat(itemValue[1])
                ? parseFloat(handleOptionSetValue(itemValue[1]))
                : 0
            );
          } else {
            calArray.push(itemValue[0]);
          }
        } else if (
          itemValue &&
          itemValue[1] &&
          typeof parseFloat(itemValue[1]) === fieldType.NUMBER &&
          formData[itemValue[0]] === ""
        ) {
          // calArray.push(parseFloat(item.split(',')[1]))
          calArray.push(
            parseFloat(itemValue[1])
              ? parseFloat(handleOptionSetValue(itemValue[1]))
              : 0
          );
        } else {
          calArray.push(
            parseFloat(handleOptionSetValue(formData[itemValue[0]], false))
              ? parseFloat(handleOptionSetValue(formData[itemValue[0]], false))
              : 0
          );
        }
      }
    }
    switch (calArray[0]) {
      case "Divide": {
        if (calArray[0] === "Divide") {
          if (calArray[1] === 0) {
            calArray[1] = 0;
            calArray[2] = 0;
          }
        }
      }
      case "Multiply": {
        if (calArray[0] === "Multiply") {
          if (calArray[1] === 0) {
            let temp = calArray[1];
            calArray[1] = calArray[2];
            calArray[2] = temp;
          }
        }
      }
    }
    return calArray;
  }
  const userDetails = useSelector((state) =>
    state &&
    state.getLoggedInUserReducer &&
    state.getLoggedInUserReducer.data &&
    state.getLoggedInUserReducer.data[0]
      ? state.getLoggedInUserReducer.data[0]
      : ""
  );

  const createLookUpJson = (data) => {
    let { crmContactUserId, crmContactUserEmail, crmContactUserName } =
      getCRMContactInfo();
    let id = userDetails && userDetails.id ? userDetails.id : crmContactUserId;
    let label = data && data.destinationVertex ? data.destinationVertex : "";
    let title =
      userDetails &&
      userDetails.properties &&
      userDetails.properties.firstName &&
      userDetails.properties.lastName
        ? userDetails.properties.firstName +
          " " +
          userDetails.properties.lastName
        : crmContactUserName;
    let email =
      userDetails && userDetails.properties && userDetails.properties.email
        ? userDetails.properties.email
        : crmContactUserEmail;
    let reqObject = {
      data: {
        id: id,
        label: label,
        title: title,
        email: email,
      },
      edgeLabel: data && data.edgeLabel ? data.edgeLabel : "",
      fieldType: data && data.fieldType ? data.fieldType : "",
    };
    return reqObject;
  };

  const getOptions = (optionSetId) => {
    let options = [];
    let currentOptionSet = optionSets.filter((optionSet) => {
      return optionSet.Id == optionSetId;
    });
    if (currentOptionSet.length > 0) {
      options = currentOptionSet[0].options;
    }
    return options;
  };

  const handleIntegers = (e) => {
    /// TODO - validationFieldLevel should be directly accessible instead of props
    if (e.target.value.length === e.currentTarget.maxLength) {
      props.integerFieldMaximumLength(e.target.id, e.target.value);
    } else {
      let field = e.target.id;
      let newFormData = formData;
      const reg = regex.REG;
      let preval = e.target.value;
      const checkValue = reg.test(e.target.value);
      if (e.target.value !== "" && checkValue == true) {
        newFormData[field] = parseInt(e.target.value);
        ///Edit form data will be used for edit purpose only
        setEditFormData({
          ...editFormData,
          [e.target.id]: parseInt(e.target.value),
        });
        setFormData(newFormData);
        props.onFormEdit({
          ...editFormData,
          [e.target.id]: parseInt(e.target.value),
        });
        props.getFormData(newFormData, field);
      } else {
        e.target.value = preval.substring(0, preval.length - 1);
        newFormData[field] = e.target.value;
        setEditFormData({
          ...editFormData,
          [e.target.id]: e.target.value,
        });
        setFormData(newFormData);
        props.onFormEdit({
          ...editFormData,
          [e.target.id]: e.target.value,
        });
        props.getFormData(newFormData, field);
        props.validationFieldLevel(e.target.id, e.target.value);
      }
    }
  };

  //handle Textboxes
  const handleTextboxes = (e) => {
    let field = e.target.id;
    let newFormData = formData;
    newFormData[field] = e.target.value;
    const Values =
      props &&
      props.elements &&
      props.elements.filter((item) => item.calculative);
    const ce = new ComputeEngine();
    const mainData = Values.map((item) =>
      ce.N(calculativeFunction(item.calculative))
    );
    Values.map((item, index) =>
      typeof mainData[index] == fieldType.NUMBER
        ? ((newFormData[item.id] = (Math.floor(mainData[index] * 100) / 100)
            .toFixed(2)
            .toString()),
          (editFormData[item.id] = (Math.floor(mainData[index] * 100) / 100)
            .toFixed(2)
            .toString()))
        : ""
    );
    //calculative Fields in table
    const calcValues = props.elements.filter(
      (item) => item.fieldType == fieldType.TABLE
    );
    let mainObj = {};
    calcValues.map((item) =>
      item.rows.map((rowItem) =>
        rowItem.fields.map((fieldItems) => {
          if (fieldItems.calculative != undefined)
            mainObj[fieldItems.id] = JSON.parse(fieldItems.calculative);
        })
      )
    );
    Object.keys(mainObj).map((key) => {
      let calValue = ce.N(calculativeFunction(mainObj[key]));
      newFormData[key] =
        typeof calValue == "number"
          ? ((Math.floor(calValue * 100) / 100).toFixed(2).toString(),
            (editFormData[key] = (Math.floor(calValue * 100) / 100)
              .toFixed(2)
              .toString()))
          : "";
    });
    for (let key in mainObj) {
      Values &&
        Values.map(
          (item) =>
            item &&
            item.rows &&
            item.rows.map((rowItem) =>
              rowItem.fields.map((fieldItems) => {
                if (fieldItems.calculative != undefined)
                  fieldItems.value = mainObj[key];
                props.getFormData(newFormData, key);
              })
            )
        );
    }
    setFormData(newFormData);
    // For Edit Form, only changed edge or props will be added to the payload
    let dealValue =
      newFormData && newFormData.dealValue ? newFormData.dealValue : "";
    let stakes = newFormData && newFormData.stakes ? newFormData.stakes : "";
    let newEditFormData = {
      ...editFormData,
      [e.target.id]: e.target.value,
      dealValue,
      stakes,
    };
    setEditFormData(newEditFormData);
    props.onFormEdit(newEditFormData);
    props.getFormData(newFormData, field);
    props.validationFieldLevel(e.target.id, e.target.value);
  };

  //handle dropdowns
  const handleDropdowns = (selectedValue, id, reportSection) => {
    let newFormData = formData;
    if (reportSection) {
      newFormData[id] = selectedValue.value;
    } else if (id && id === "currentStatus" && props.entity) {
      if (props.entity.recordId === "") {
        let tempDealConfig = { ...dealConfig };
        newFormData[id] = selectedValue.value;
        const currentDate = new Date();
        const curr = getFormattedDate(currentDate, "DD/MM/YYYY");
        newFormData["statusHistory"] = tempDealConfig.statusHistoryWithTwoValue
          .split("=currentDate")
          .join(curr);
        newFormData["statusHistory"] = newFormData["statusHistory"]
          .split("=status")
          .join(selectedValue.value);
      } else {
        newFormData[id] = selectedValue.value;
        updateStatus(props.entity.recordId, selectedValue.value, pageUrl);
      }
    } else if (id && id === "currentStatus" && props.entity) {
      if (props.entity.recordId === "") {
        let tempDealConfig = { ...dealConfig };
        newFormData[id] = selectedValue.value;
        const currentDate = new Date();
        const curr = getFormattedDate(currentDate, "DD/MM/YYYY");
        newFormData["statusHistory"] = tempDealConfig.statusHistoryWithTwoValue
          .split("=currentDate")
          .join(curr);
        newFormData["statusHistory"] = newFormData["statusHistory"]
          .split("=status")
          .join(selectedValue.value);
      } else {
        newFormData[id] = selectedValue.value;
        updateStatus(props.entity.recordId, selectedValue.value, pageUrl);
      }
    } else {
      newFormData[id] =
        selectedValue.value != ""
          ? JSON.stringify(selectedValue)
          : selectedValue.value;
    }
    if (
      (props && props.entity && props.entity.currentStatus) ||
      (props &&
        props.entity &&
        props.entity.parameters &&
        props.entity.parameters.currentStatus)
    ) {
      for (var char in status_Probability) {
        if (char === selectedValue.label) {
          newFormData.Probability = status_Probability[char];
          editFormData.Probability = status_Probability[char];
          break;
        }
      }
    }
    const Values =
      props &&
      props.elements &&
      props.elements.filter((item) => item.calculative);
    const ce = new ComputeEngine();
    const mainData = Values.map((item) =>
      ce.N(calculativeFunction(item.calculative))
    );
    Values.map((item, index) =>
      typeof mainData[index] == fieldType.NUMBER
        ? ((newFormData[item.id] = (Math.floor(mainData[index] * 100) / 100)
            .toFixed(2)
            .toString()),
          (editFormData[item.id] = (Math.floor(mainData[index] * 100) / 100)
            .toFixed(2)
            .toString()))
        : ""
    );
    // For Edit Form, only changed edge or props will be added to the payload
    let newEditFormData = {
      ...editFormData,
      [id]: newFormData[id],
    };
    setEditFormData(newEditFormData);
    props.onFormEdit(newEditFormData);
    props.getFormData(newFormData, id);
    props.validationFieldLevel(id, selectedValue);
  };

  const handleDropable = (selectedValue, files, id) => {
    let newFormData = formData;
    newFormData.uploadSuccess = true;
    newFormData[id] = { files, fieldType: "document" };
    newFormData["files"] = { files, fieldType: "document" };
    setFormData(newFormData);
    let newEditFormData = {
      ...editFormData,
      [id]: newFormData[id],
    };
    setEditFormData(newEditFormData);
    props.onFormEdit(newEditFormData);
    props.getFormData(newFormData, id);
  };
  //handle handleasyncCheckbox
  const handleasyncCheckbox = async (isChecked, checkboxId) => {
    let newFormData = formData;
    let dropDownmonth = 0;
    if (
      formData.Date &&
      JSON.parse(formData.Date).value &&
      JSON.parse(formData.Date).value.includes("January")
    ) {
      dropDownmonth = 0;
    } else if (
      formData.Date &&
      JSON.parse(formData.Date).value &&
      JSON.parse(formData.Date).value.includes("February")
    ) {
      dropDownmonth = 1;
    } else if (
      formData.Date &&
      JSON.parse(formData.Date).value &&
      JSON.parse(formData.Date).value.includes("March")
    ) {
      dropDownmonth = 2;
    } else if (
      formData.Date &&
      JSON.parse(formData.Date).value &&
      JSON.parse(formData.Date).value.includes("April")
    ) {
      dropDownmonth = 3;
    } else if (
      formData.Date &&
      JSON.parse(formData.Date).value &&
      JSON.parse(formData.Date).value.includes("May")
    ) {
      dropDownmonth = 4;
    } else if (
      formData.Date &&
      JSON.parse(formData.Date).value &&
      JSON.parse(formData.Date).value.includes("June")
    ) {
      dropDownmonth = 5;
    } else if (
      formData.Date &&
      JSON.parse(formData.Date).value &&
      JSON.parse(formData.Date).value.includes("July")
    ) {
      dropDownmonth = 6;
    } else if (
      formData.Date &&
      JSON.parse(formData.Date).value &&
      JSON.parse(formData.Date).value.includes("August")
    ) {
      dropDownmonth = 7;
    } else if (
      formData.Date &&
      JSON.parse(formData.Date).value &&
      JSON.parse(formData.Date).value.includes("September")
    ) {
      dropDownmonth = 8;
    } else if (
      formData.Date &&
      JSON.parse(formData.Date).value &&
      JSON.parse(formData.Date).value.includes("October")
    ) {
      dropDownmonth = 9;
    } else if (
      formData.Date &&
      JSON.parse(formData.Date).value &&
      JSON.parse(formData.Date).value.includes("November")
    ) {
      dropDownmonth = 10;
    } else if (
      formData.Date &&
      JSON.parse(formData.Date).value &&
      JSON.parse(formData.Date).value.includes("December")
    ) {
      dropDownmonth = 11;
    } else {
      dropDownmonth =
        props.toolbarSelections && props.toolbarSelections.filters[1].value == 0
          ? new Date().getMonth()
          : props.toolbarSelections &&
            props.toolbarSelections.filters[1].value - 1;
    }
    let url = "/ChartData/getchartdata";
    let requestPayload = {
      parameters: {
        templateId: props && props.entity && props.entity.templateId,
        Year:
          dropDownmonth == 0
            ? `${Number(new Date().getFullYear()) - 1}`
            : `${new Date().getFullYear()}`,
        Month: dropDownmonth == 0 ? "11" : `${dropDownmonth}`,
      },
      content: {
        fields: asyncCheckbox.fields,
        filterOnDateField: asyncCheckbox.filterOnDateField,
        label: asyncCheckbox.label,
        sourceId: `${crmId}`,
        sourceLabel: `${pageUrl}`,
      },
      chartId:
        localStorage.getItem("chartStorageKeys") &&
        localStorage.getItem("chartStorageKeys").split("__").length >= 3 &&
        JSON.parse(localStorage.getItem("chartStorageKeys").split("__")[2])
          .chartId,
    };
    if (isChecked === false) {
      props.elements[2].label = asyncCheckbox.status;
      props.elements[3].label = asyncCheckbox.Rationale;
    }
    const result =
      isChecked === true ? await httpCall.httpPost(url, requestPayload) : "";
    if (
      isChecked === true &&
      (await result) &&
      result.data &&
      result.data.series &&
      result.data.series != "" &&
      result.data.series.length > 0 &&
      result.data.categories.includes("status")
    ) {
      props.elements[2].label =
        (await result) &&
        result.data &&
        result.data.series &&
        result.data.series != "" &&
        result.data.series.length > 0 &&
        JSON.parse(result.data.series[result.data.series.length - 1].data[0])
          .label;
      props.elements[3].label =
        result &&
        result.data &&
        result.data.series &&
        result.data.series != "" &&
        result.data.series.length > 0 &&
        result.data.series[result.data.series.length - 1].name[0];
      newFormData.status =
        result &&
        result.data &&
        result.data.series &&
        result.data.series != "" &&
        result.data.series.length > 0 &&
        result.data.series[result.data.series.length - 1].data[0];
      editFormData.status =
        result &&
        result.data &&
        result.data.series &&
        result.data.series != "" &&
        result.data.series.length > 0 &&
        result.data.series[result.data.series.length - 1].data[0];
      // newdata['status']='Same as Last Month'
      newFormData["Rationale for operational rating"] =
        result &&
        result.data &&
        result.data.series &&
        result.data.series != "" &&
        result.data.series.length > 0 &&
        result.data.series[result.data.series.length - 1].name[0];
      editFormData["Rationale for operational rating"] =
        result &&
        result.data &&
        result.data.series &&
        result.data.series != "" &&
        result.data.series.length > 0 &&
        result.data.series[result.data.series.length - 1].name[0];
    }
    if (
      isChecked === true &&
      (await result) &&
      result.data &&
      result.data.series &&
      result.data.series != "" &&
      result.data.series.length > 0 &&
      result.data.categories.includes("Rationale for operational rating")
    ) {
      props.elements[2].label =
        (await result) &&
        result.data &&
        result.data.series &&
        result.data.series != "" &&
        result.data.series.length > 0 &&
        Object.keys(result.data.series[result.data.series.length - 1]).length >
          0 &&
        JSON.parse(result.data.series[result.data.series.length - 1].name[0])
          .label;
      props.elements[3].label =
        result &&
        result.data &&
        result.data.series &&
        result.data.series != "" &&
        result.data.series.length > 0 &&
        Object.keys(result.data.series[result.data.series.length - 1]).length >
          0 &&
        result.data.series[result.data.series.length - 1].data[0];
      newFormData.status =
        result &&
        result.data &&
        result.data.series &&
        result.data.series != "" &&
        result.data.series.length > 0 &&
        Object.keys(result.data.series[result.data.series.length - 1]).length >
          0 &&
        result.data.series[result.data.series.length - 1].name[0];
      editFormData.status =
        result &&
        result.data &&
        result.data.series &&
        result.data.series != "" &&
        result.data.series.length > 0 &&
        Object.keys(result.data.series[result.data.series.length - 1]).length >
          0 &&
        result.data.series[result.data.series.length - 1].name[0];
      // newdata['status']='Same as Last Month'
      newFormData["Rationale for operational rating"] =
        result &&
        result.data &&
        result.data.series &&
        result.data.series != "" &&
        result.data.series.length > 0 &&
        Object.keys(result.data.series[result.data.series.length - 1]).length >
          0 &&
        result.data.series[result.data.series.length - 1].data[0];
      editFormData["Rationale for operational rating"] =
        result &&
        result.data &&
        result.data.series &&
        result.data.series != "" &&
        result.data.series.length > 0 &&
        Object.keys(result.data.series[result.data.series.length - 1]).length >
          0 &&
        result.data.series[result.data.series.length - 1].data[0];
    }

    setFormData(newFormData);
    // For Edit Form, only changed edge or props will be added to the payload
    let newEditFormData = {
      ...editFormData,
      [checkboxId]: isChecked,
    };
    setEditFormData(newEditFormData);
    props.onFormEdit(newEditFormData);
    props.getFormData(newFormData, checkboxId);
    props.validationFieldLevel(checkboxId, isChecked);
  };

  //handle checkboxes
  const handleCheckbox = (isChecked, checkboxId) => {
    let newFormData = formData;
    if (checkboxId == "privateRecord") {
      let newSecurity = { ...newFormData.security };
      newSecurity.privateRecord = isChecked;
      newFormData.security = newSecurity;
    } else {
      newFormData[checkboxId] = isChecked;
    }
    setFormData(newFormData);
    // For Edit Form, only changed edge or props will be added to the payload
    let newEditFormData = {
      ...editFormData,
      [checkboxId]: isChecked,
    };
    setEditFormData(newEditFormData);
    props.onFormEdit(newEditFormData);
    props.getFormData(newFormData, checkboxId);
    props.validationFieldLevel(checkboxId, isChecked);
  };

  //handle MultiSelect
  const handleTabsSeletion = (value, id) => {
    let newFormData = formData;
    newFormData[id] = JSON.stringify(value);
    setEditFormData(newFormData);
    props.onFormEdit(newFormData);
    props.getFormData(newFormData);
  };

  //handle Radio-group
  const handleRadioGroup = (selectedValue, radioGroupId) => {
    let newFormData = formData;
    newFormData[radioGroupId] = selectedValue.value;
    setFormData(newFormData);
    // For Edit Form, only changed edge or props will be added to the payload
    let newEditFormData = {
      ...editFormData,
      [radioGroupId]: selectedValue.value,
    };
    setEditFormData(newEditFormData);
    props.onFormEdit(newEditFormData);
    props.getFormData(newFormData);
    props.validationFieldLevel(radioGroupId, selectedValue);
  };

  //handle date-picker
  const handleDatePicker = (selectedValue, datepickerId) => {
    let newFormData = formData;
    newFormData[datepickerId] = selectedValue;
    const newDate = getFormattedDate(selectedValue, "YYYY-MM-DD");
    setDate(newDate);
    setFormData(newFormData);
    // For Edit Form, only changed edge or props will be added to the payload
    let newEditFormData = {
      ...editFormData,
      [datepickerId]: selectedValue,
    };
    setEditFormData(newEditFormData);
    props.onFormEdit(newEditFormData);
    props.getFormData(newFormData, datepickerId);
    props.validationFieldLevel(datepickerId, selectedValue);
  };
  //handle Textboxes
  const handleSummerNote = (el, htmid) => {
    el = el.replaceAll("'", "\'");
    let newFormData = formData;
    newFormData[htmid] = el;
    setFormData(newFormData);
    let newEditFormData = {
      ...newFormData,
      [htmid]: el,
    };
    setEditFormData(newEditFormData);
    props.onFormEdit(newEditFormData);
    props.getFormData(newFormData, htmid);
    props.validationFieldLevel(htmid, el);
  };
  //handle lookup / auto-complete
  const handleLookup = (selectedValue, id, tabular = false) => {
    let newFormData = formData;
    if (selectedValue.data != "" && selectedValue.data != []) {
      newFormData[id] = newFormData[id] = {
        ...selectedValue,
        fieldType: "lookup",
      };
    } else {
      newFormData[id] = undefined;
      setFlag(false);
    }

    setFormData(newFormData);
    let newEditFormData = {
      ...editFormData,
      [id]: newFormData[id],
    };
    setEditFormData(newEditFormData);
    props.onFormEdit(newEditFormData);
    props.getFormData(newFormData, id);
    tabular ? setEditTabular(true) : "";
    !tabular ? props.validationFieldLevel(id, newFormData, selectedValue) : "";
  };
  const handleFileUpload = (files, id, fileConfig) => {
    let newFormData = formData;
    newFormData.uploadSuccess = true;
    newFormData[id] = {
      files,
      fieldType: "document",
      fileConfig: { ...fileConfig },
    };
    setFormData(newFormData);
    let newEditFormData = {
      ...editFormData,
      [id]: newFormData[id],
    };
    setEditFormData(newEditFormData);
    props.onFormEdit(newEditFormData);
    props.getFormData(newFormData, id);
  };
  const handleTabularDropdowns = (selectedValue, id) => {
    let newFormData = formData;

    newFormData[id] = selectedValue.value;

    setFormData(newFormData);

    // For Edit Form, only changed edge or props will be added to the payload

    let newEditFormData = {
      ...editFormData,

      [id]: selectedValue.value,
    };

    setEditFormData(newEditFormData);

    props.onFormEdit(newEditFormData);

    props.getFormData(newFormData, id);
    setEditTabular(true);
  };

  const handleTabularTableField = (e, textId) => {
    let field = textId.name;
    let newFormData = formData;
    newFormData[field] = e.target.value;

    const Values = props.elements.filter((item) => item.fieldType == "table");
    let mainObj = {};
    Values.map((item) =>
      item.rows.map((rowItem) =>
        rowItem.fields.map((fieldItems) => {
          if (fieldItems.calculative != undefined)
            mainObj[fieldItems.id] = JSON.parse(fieldItems.calculative);
        })
      )
    );

    const ce = new ComputeEngine();
    Object.keys(mainObj).map((key) => {
      let calValue = ce.N(calculativeFunction(mainObj[key]));
      newFormData[key] =
        typeof calValue == "number"
          ? ((Math.floor(calValue * 100) / 100).toFixed(2).toString(),
            (editFormData[key] = (Math.floor(calValue * 100) / 100)
              .toFixed(2)
              .toString()))
          : "";
    });
    for (let key in mainObj) {
      Values.map((item) =>
        item.rows.map((rowItem) =>
          rowItem.fields.map((fieldItems) => {
            if (fieldItems.calculative != undefined)
              fieldItems.value = mainObj[key];
            //fieldItems.defaultValue = mainObj[key];
            props.getFormData(newFormData, key);
          })
        )
      );
    }

    // let mainData = Object.assign(newFormData,finalValues)
    setFormData(newFormData);
    let newEditFormData = {
      ...editFormData,
      [e.target.id]: e.target.value,
    };
    setEditFormData(newEditFormData);
    props.onFormEdit(newEditFormData);
    props.getFormData(newFormData, field);
    setEditTabular(true);
  };

  const handleTabularinteger = (e, textId) => {
    let field = textId.name;
    let newFormData = formData;
    newFormData[field] = e.target.value;
    const reg = /^[0-9\b]+$/;
    let preval = e.target.value;
    const checkValue = reg.test(e.target.value);
    if (checkValue) {
      const Values = props.elements.filter((item) => item.fieldType == "table");
      let mainObj = {};
      Values.map((item) =>
        item.rows.map((rowItem) =>
          rowItem.fields.map((fieldItems) => {
            if (fieldItems.calculative != undefined)
              mainObj[fieldItems.id] = JSON.parse(fieldItems.calculative);
          })
        )
      );

      const ce = new ComputeEngine();
      Object.keys(mainObj).map((key) => {
        let calValue = ce.N(calculativeFunction(mainObj[key]));
        newFormData[key] =
          typeof calValue == "number"
            ? ((Math.floor(calValue * 100) / 100).toFixed(2).toString(),
              (editFormData[key] = Math.floor(calValue * 100) / 100)
                .toFixed(2)
                .toString())
            : "";
      });
      for (let key in mainObj) {
        Values.map((item) =>
          item.rows.map((rowItem) =>
            rowItem.fields.map((fieldItems) => {
              if (fieldItems.calculative != undefined)
                fieldItems.value = mainObj[key];
              //fieldItems.defaultValue = mainObj[key];
              props.getFormData(newFormData, key);
            })
          )
        );
      }

      // let mainData = Object.assign(newFormData,finalValues)
      setFormData(newFormData);
      let newEditFormData = {
        ...editFormData,
        [e.target.id]: e.target.value,
      };
      setEditFormData(newEditFormData);
      props.onFormEdit(newEditFormData);
      props.getFormData(newFormData, field);
      setEditTabular(true);
    } else {
      e.target.value = preval.substring(0, preval.length - 1);
    }
  };

  const documentsFetched = (documents, fieldName) => {
    let docs = [];
    try {
      docs = JSON.parse(documents).filter((doc) => {
        let fileProp = doc.split("/")[1].split("__");
        if (fileProp[0] === fieldName) {
          return true;
        }
      });
    } catch (e) {
      docs = [];
    }
    return docs;
  };

  const formElements = props.elements.map((element) => {
    let elementProps = element;
    if (props.validationSummary !== undefined) {
      let fieldValidation = props.validationSummary[elementProps.id];
      if (fieldValidation) {
        elementProps.error = fieldValidation.error;
        elementProps.helperText = fieldValidation.error && fieldValidation.msg;
      }
    }
    //setting default values
    if (formData) {
      var copyDefaultValue = elementProps.prePopulate
        ? elementProps.prePopulate
        : "";
      if (elementProps.fieldType === fieldType.BIT) {
        elementProps.checked = formData[elementProps.id] ? true : false;
      } else {
        if (elementProps.fieldType === fieldType.USERNAME) {
          if (
            formData[elementProps.id] === undefined ||
            formData[elementProps.id] == ""
          ) {
            elementProps.defaultValue =
              user &&
              user.profile &&
              user.profile.given_name &&
              user.profile.family_name
                ? user.profile.given_name + " " + user.profile.family_name
                : "";

            formData[elementProps.id] =
              formData[elementProps.id] === undefined
                ? elementProps.defaultValue
                : formData[elementProps.id];
          } else {
            elementProps.defaultValue =
              formData[elementProps.id] === undefined
                ? ""
                : formData[elementProps.id];
          }
        } else if (
          elementProps &&
          elementProps.fieldType &&
          elementProps.fieldType === fieldType.DATE
        ) {
          elementProps.defaultValue =
            formData[elementProps.id] === undefined
              ? 0
              : formData[elementProps.id];
          elementProps.value =
            formData[elementProps.id] === undefined
              ? 0
              : formData[elementProps.id];
        } else {
          elementProps.defaultValue =
            formData[elementProps.id] === undefined
              ? ""
              : formData[elementProps.id];
          elementProps.value =
            formData[elementProps.id] === undefined
              ? ""
              : formData[elementProps.id];
        }
        if (
          elementProps.fieldType === fieldType.STRING &&
          elementProps.defaultValue !== undefined &&
          elementProps.defaultValue !== ""
        ) {
          const currentDate = new Date();
          const curr = getFormattedDate(currentDate, "DD/MM/YYYY");
          elementProps.defaultValue = elementProps.defaultValue
            .split("=currentDate")
            .join(curr);
          elementProps.defaultValue = elementProps.defaultValue
            .split("=crmId")
            .join(crmId);
        }
        if (props.fromAddEntity == undefined) {
          if (
            props &&
            props.currentPage &&
            props.currentPage.data &&
            props.currentPage.data[copyDefaultValue] != undefined &&
            props.isEditable === undefined
          ) {
            if (
              elementProps.prePopulate &&
              typeof elementProps.defaultValue == "string"
            )
              if (props.currentPage.data[copyDefaultValue] != undefined) {
                elementProps.defaultValue =
                  props.currentPage.data[copyDefaultValue];
              } else {
                elementProps.defaultValue = elementProps.prePopulate;
              }
          }
        }
        if (
          elementProps.fieldType === "Document" &&
          formData &&
          formData.documents
        ) {
          let uploadedDocs = documentsFetched(
            formData.documents,
            elementProps.name
          );
          elementProps.defaultValue = uploadedDocs;
        }
        {
          elementProps &&
          elementProps.lookupdefault == true &&
          elementProps.destinationVertex == "contact" &&
          flag
            ? (elementProps["defaultValue"] =
                elementProps.defaultValue == ""
                  ? createLookUpJson(elementProps)
                  : elementProps.defaultValue)
            : [];
        }
        if (
          elementProps.fieldType === fieldType.LOOKUP &&
          elementProps.prePopulate !== undefined &&
          props.fromAddEntity == undefined &&
          flag
        ) {
          if (
            props &&
            props.currentPage &&
            props.currentPage.data &&
            props.currentPage.data[copyDefaultValue]
          ) {
            elementProps.defaultValue = lookupDefaultValue(
              elementProps,
              crmId,
              props.currentPage.data
            );
          }
        }
        formData[elementProps.id] = elementProps.defaultValue;
      }
    }
    return (
      <Grid item xs={12} key={element.id}>
        {(elementProps.fieldType === fieldType.STRING &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === fieldType.STRING &&
          elementProps.visibility === true) ? (
          <A_TextField
            className={classes.inputcontrolTextField}
            variant="standard"
            label={
              elementProps.label == undefined || elementProps.label === null
                ? elementProps.name
                : elementProps.label
            }
            onChange={handleTextboxes}
            {...elementProps}
          />
        ) : (
          ""
        )}
        {(elementProps.fieldType === fieldType.TEXT &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === fieldType.TEXT &&
          elementProps.visibility === true) ? (
          <>
            <TextField
              className={classes.inputcontrolTextField}
              variant="standard"
              label={
                elementProps.label == undefined || elementProps.label === null
                  ? elementProps.name
                  : elementProps.label
              }
              {...elementProps}
              disabled={true}
            />
          </>
        ) : (
          ""
        )}
        {(elementProps.fieldType === fieldType.PERCENTAGE &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === fieldType.PERCENTAGE &&
          elementProps.visibility === true) ? (
          <TextField
            className={classes.inputcontrolTextField}
            variant="standard"
            label={
              elementProps.label == undefined || elementProps.label === null
                ? elementProps.name
                : elementProps.label
            }
            type="number"
            onKeyDown={(event) => {
              if (event.key == "e") {
                event.preventDefault();
              }
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onChange={handleTextboxes}
            {...elementProps}
          />
        ) : (
          ""
        )}

        {(elementProps.fieldType === fieldType.MULTIPLE &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === fieldType.MULTIPLE &&
          elementProps.visibility === true) ? (
          <TextField
            className={classes.inputcontrolTextField}
            variant="standard"
            label={
              elementProps.label == undefined || elementProps.label === null
                ? elementProps.name
                : elementProps.label
            }
            type="number"
            onKeyDown={(event) => {
              if (event.key == "e") {
                event.preventDefault();
              }
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">x</InputAdornment>,
            }}
            onChange={handleTextboxes}
            {...elementProps}
          />
        ) : (
          ""
        )}
        {(elementProps.fieldType === fieldType.INTEGER &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === fieldType.INTEGER &&
          elementProps.visibility === true) ? (
          <TextField
            className={classes.inputcontrolTextField}
            variant="standard"
            inputProps={{
              maxLength:
                elementProps && elementProps.maxLength
                  ? elementProps.maxLength
                  : 18,
            }}
            label={
              elementProps.label == undefined || elementProps.label === null
                ? elementProps.name
                : elementProps.label
            }
            onChange={handleIntegers}
            {...elementProps}
          />
        ) : (
          ""
        )}
        {(elementProps.fieldType === fieldType.FLOAT &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === fieldType.FLOAT &&
          elementProps.visibility === true) ? (
          <TextField
            className={classes.inputcontrolTextField}
            variant="standard"
            label={
              elementProps.label == undefined || elementProps.label === null
                ? elementProps.name
                : elementProps.label
            }
            type="number"
            onChange={handleTextboxes}
            {...elementProps}
          />
        ) : (
          ""
        )}
        {(elementProps.fieldType === fieldType.DATE &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === fieldType.DATE &&
          elementProps.visibility === true) ? (
          <A_DatePicker
            className={classes.inputcontrol}
            label={
              elementProps.label == undefined || elementProps.label === null
                ? elementProps.name
                : elementProps.label
            }
            defaultValue={date}
            onChange={handleDatePicker}
            isValidDate={props.isValidDate ? props.isValidDate : ""}
            {...elementProps}
          />
        ) : (
          ""
        )}
        {(elementProps.fieldType === fieldType.OPTIONSET &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === fieldType.OPTIONSET &&
          elementProps.visibility === true) ? (
          <A_Select
            className={classes.inputcontrol}
            variant="standard"
            label={
              elementProps.label == undefined || elementProps.label === null
                ? elementProps.name
                : elementProps.label
            }
            helperText={
              elementProps.helperText == undefined ||
              elementProps.helperText === null
                ? elementProps.description
                : elementProps.helperText
            }
            native={true}
            options={getOptions(elementProps.optionSet)}
            onChange={handleDropdowns}
            {...elementProps}
          />
        ) : (
          ""
        )}
        {/* WIP Multi option set functionality   */}
        {elementProps.fieldType === fieldType.MULTISELECT && (
          <A_MultiselectCustom
            data={elementProps}
            label={elementProps.label}
            options={getOptions(elementProps.optionSet)}
            // multiple ={elementProps.createNew}   for future Use Case
            multiple={true}
            handleTabsSeletion={handleTabsSeletion}
          />
        )}
        {(elementProps.fieldType === fieldType.LOOKUP &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === fieldType.LOOKUP &&
          elementProps.visibility === true) ? (
          <A_Autocomplete
            managePage={props.managePage}
            loogedInUserValue={
              (elementProps && elementProps.lookupdefault == true) ||
              elementProps.lookupdefault != ""
                ? true
                : false
            }
            entity={props.entity}
            variant="standard"
            className={classes.inputcontrol}
            label={
              elementProps.label == undefined || elementProps.label === null
                ? elementProps.name
                : elementProps.label
            }
            onChange={handleLookup}
            {...elementProps}
          />
        ) : (
          ""
        )}
        {(elementProps.fieldType === fieldType.RADIOS &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === fieldType.RADIOS &&
          elementProps.visibility === true) ? (
          <M_RadiosGroupConfig
            {...elementProps}
            options={getOptions(elementProps.optionSet)}
            onChange={handleRadioGroup}
          />
        ) : (
          ""
        )}
        {(elementProps.fieldType === fieldType.SECTION &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === fieldType.SECTION &&
          elementProps.visibility === true) ? (
          <DisplaySectionHeader {...elementProps} label={elementProps.label} />
        ) : (
          ""
        )}
        {(elementProps.fieldType === fieldType.BIT &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === fieldType.BIT &&
          elementProps.visibility === true) ? (
          <Grid container>
            <Grid item xs={12}>
              <A_CheckBox
                style={{}}
                {...elementProps}
                required={elementProps.required}
                checked={elementProps.checked}
                onChange={
                  (props &&
                    props.entity &&
                    props.entity.parameters &&
                    props.entity.parameters.pageUrl &&
                    props.entity.parameters.pageUrl === "performance") ||
                  (props &&
                    props.entity &&
                    props.entity.parameters &&
                    props.entity.parameters.pageUrl &&
                    props.entity.parameters.pageUrl === "asset" &&
                    props.entity.parameters.edgeLabel &&
                    props.entity.parameters.edgeLabel === "has_performance") ||
                  (props &&
                    props.entity &&
                    props.entity.parameters &&
                    props.entity.parameters.pageUrl &&
                    props.entity.parameters.edgeLabel === "has_assets")
                    ? handleasyncCheckbox
                    : handleCheckbox
                }
                helperText={
                  elementProps.helperText == undefined ||
                  elementProps.helperText === null
                    ? elementProps.description
                    : elementProps.helperText
                }
              />
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {(elementProps.fieldType.toLowerCase() === fieldType.HTML &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType.toLowerCase() === fieldType.HTML &&
          elementProps.visibility === true) ? (
          <>
            <A_SummerNote
              onChange={handleSummerNote}
              disabled={false}
              {...elementProps}
            ></A_SummerNote>
            {elementProps.helperText && (
              <p style={{ color: "#f44336", fontSize: "0.75rem" }}>
                This field is required
              </p>
            )}
          </>
        ) : (
          ""
        )}
        {(elementProps.fieldType === fieldType.TABLE &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === fieldType.TABLE &&
          elementProps.visibility === true) ? (
          <M_TabularField
            handleTabularDropdowns={handleTabularDropdowns}
            handleTabularinteger={handleTabularinteger}
            handleTabularTableField={handleTabularTableField}
            editTabular={editTabular}
            formData={formData}
            getFormData={props.getFormData}
            onFormEdit={props.onFormEdit}
            elements={props.elements}
            lastFormData={props.lastFormData}
            optionSets={props.optionSets}
            elementProps={elementProps}
            managePage={props.managePage}
            label={
              elementProps.label == undefined || elementProps.label === null
                ? elementProps.name
                : elementProps.label
            }
            entity={props.entity}
            loogedInUserValue={
              (elementProps && elementProps.lookupdefault == true) ||
              elementProps.lookupdefault != ""
                ? true
                : false
            }
            handleLookup={handleLookup}
          />
        ) : (
          ""
        )}
        {elementProps.fieldType.toLowerCase() === fieldType.DOCUMENT && (
          <>
            <span>
              {elementProps.label} {elementProps.required ? "*" : ""}
            </span>
            <M_DropableFileUpload
              content={elementProps}
              onUpload={handleDropable}
              onChange={handleFileUpload}
              enableChips={true}
              {...elementProps}
            />
            {elementProps && elementProps.helperText ? (
              <Typography color="secondary">
                {elementProps.helperText}
              </Typography>
            ) : (
              ""
            )}
          </>
        )}
        {(elementProps.fieldType === fieldType.FINANCIAL &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === fieldType.FINANCIAL &&
          elementProps.visibility === true) ? (
          <TextField
            className={classes.inputcontrolTextField}
            variant="standard"
            inputProps={{
              maxLength:
                elementProps && elementProps.maxLength
                  ? elementProps.maxLength
                  : 18,
            }}
            label={
              elementProps.label == undefined || elementProps.label === null
                ? elementProps.name
                : elementProps.label
            }
            onChange={handleTextboxes}
            {...elementProps}
          />
        ) : (
          ""
        )}
        {(elementProps.fieldType === fieldType.USERNAME &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === fieldType.USERNAME &&
        elementProps.visibility === true &&
        elementProps.defaultValue === user &&
        user.profile &&
        user.profile.given_name &&
        user.profile.family_name
          ? user.profile.given_name + " " + user.profile.family_name
          : "") ? (
          <A_TextField
            className={classes.inputcontrolTextField}
            variant="standard"
            label={
              elementProps.label == undefined || elementProps.label === null
                ? elementProps.name
                : elementProps.label
            }
            onChange={handleTextboxes}
            {...elementProps}
          />
        ) : (
          ""
        )}
      </Grid>
    );
  });

  return <section className={classes.container}>{formElements}</section>;
});

function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    toolbarSelections: state.manageToolbarSelections,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, manageFormActions, currentPageActions),
    dispatch
  );
}

M_FormRenderer.defaultProps = {
  optionSets: [],
  lastFormData: {},
  options: [],
  elements: [],
  validationSummary: {},
  entity: {},
  state: {},
  isValidDate: true,
};
M_FormRenderer.propTypes = {
  options: PropTypes.array,
  optionSets: PropTypes.array,
  lastFormData: PropTypes.object,
  onFormEdit: PropTypes.func,
  getFormData: PropTypes.func,
  validationFieldLevel: PropTypes.func,
  elements: PropTypes.array,
  validationSummary: PropTypes.object,
  entity: PropTypes.object,
  state: PropTypes.object,
  isValidDate: PropTypes.bool,
  managePage: PropTypes.object,
  currentPage: PropTypes.object,
  toolbarSelections: PropTypes.object,
  fromAddEntity: PropTypes.bool,
};
export default connect(mapStateToProps, mapDispatchToProps)(M_FormRenderer);
