const getStyleBasedOnColumType = (config, rowDataType = null) => {
  if (config && config.type) {
    let type = rowDataType ? rowDataType : config["type"];
    switch (type) {
      case "text":
        return { textAlign: "left" };
      case "number":
        return { textAlign: "left" };
      case "numeric":
        return { textAlign: "right" };
      case "percentage":
        return { textAlign: "right" };
      case "Percentage":
        return { textAlign: "right" };
      case "multiple":
        return { textAlign: "right" };
      case "date":
        return { textAlign: "center" };
      case "Accounting":
        return { textAlign: "right" };
      case "financial":
        return { textAlign: "right" };
      default:
        break;
    }
  }
};

export default getStyleBasedOnColumType;
