/* eslint-disable */
import * as React from "react";
import { Box } from "@material-ui/core";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import M_CheckList from "../../../Molecules/Common/Checklist/M_CheckList";
import O_Mail from "../IRC_Mail/O_Mail";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import O_IRCSlots from "../IRCSlots/O_IRCSlots";
import { labelName } from "../../../../../Utils/config/config";
export default function O_CheckList(props) {
  const [data, setData] = React.useState(props.checkListData.checklistData);
  const [isComplete, setIsComplete] = React.useState(
    props.checkListData.isCompleted
  );
  const [bookIRC, setBookIRC] = React.useState(false);
  const handleBookIRC = () => {
    setBookIRC(true);
  };
  const handleIRCForm = () => {
    setBookIRC(false);
  };
  const getReload = () => {
    props.reload();
  };
  return data && data.length > 0 ? (
    <div>
      {props.checkListData && props.checkListData.checklistData ? (
        <M_CheckList
          config={props.content}
          data={props.checkListData.checklistData}
          type="preIRC"
          {...props}
        />
      ) : (
        ""
      )}

      <Box
        bgcolor={"#fcf8e3"}
        color={"#fec85f"}
        width={"100%"}
        padding={"1rem"}
        marginY={"1rem"}
      >
        {props.content && props.content.ircMssg
          ? props.content.ircMssg
          : labelName.IRC_Mssg}
      </Box>

      <Box paddingTop={"2rem"} display={"flex"} justifyContent={"end"}>
        <A_Button
          label="Reschedule"
          color={"primary"}
          onClick={handleBookIRC}
        />
        {isComplete ? (
          <O_Mail
            content={props.content}
            label={
              props.content && props.content.ircMailButtonLabel
                ? props.content.ircMailButtonLabel
                : labelName.IRC_BUTTON
            }
            ircID={
              props.checkListData &&
              props.checkListData.checklistData &&
              Array.isArray(props.checkListData.checklistData) &&
              props.checkListData.checklistData[0] &&
              props.checkListData.checklistData[0].dealChecklist &&
              props.checkListData.checklistData[0].dealChecklist.dealIrcId
            }
            formData={{}}
            reload={getReload}
          />
        ) : (
          <A_Button
            label={
              props.content && props.content.ircMailButtonLabel
                ? props.content.ircMailButtonLabel
                : labelName.IRC_BUTTON
            }
            disabled={true}
            color={"primary"}
          />
        )}
      </Box>
      <A_SimpleDialog
        open={bookIRC}
        formTemplateId
        title="Reschedule IRC Meeting"
        fullScreen={false}
        height="400px"
        fullwidth={true}
        maxWidth={"xs"}
        onClose={handleIRCForm}
        closePopUp={() => {
          handleIRCForm;
        }}
        dialogContent={
          <O_IRCSlots
            onClose={handleIRCForm}
            getReload={getReload}
            modify={true}
            idLabel={
              props.content &&
              props.content &&
              props.content.rescheduleLabel &&
              props.content.rescheduleLabel.id
            }
            ircConfig={props.content && props.content.rescheduleLabel}
            id={props.id}
          />
        }
      />
    </div>
  ) : (
    <></>
  );
}
