/* eslint-disable */
import groupBy from "lodash/groupBy";

export const convertToEntryMetrics = (chartDataApi, formName) => {
  const metrics =
    chartDataApi && chartDataApi.series
      ? chartDataApi.series.map((point) => {
          return {
            name: point.name[0],
            value: point.data[0],
            id: point.data[1],
            date: point.data[2],
            dataType: point.data[3],
          };
        })
      : {};

  const dateGroupedMetrics = groupBy(metrics, (item) => item.date);
  const convertedData = {
    formName: formName,
    keyField:
      chartDataApi && chartDataApi.dimensions ? chartDataApi.dimensions[0] : "",
    points: [],
  };

  convertedData.keyField =
    chartDataApi && chartDataApi.dimensions ? chartDataApi.dimensions[0] : "";
  convertedData.points = [];
  for (const [key, value] of Object.entries(dateGroupedMetrics)) {
    convertedData.points.push({
      date: key,
      metrics: value,
    });
  }
  return convertedData;
};

export const getMockPoints = () => {
  return convertToEntryMetrics(chartDimensionsData, "Update Form");
};

const chartDimensionsData = {
  dimensions: ["Covenant"],
  categories: ["Value", "id", "Date", "Data Type", "investmentid"],
  series: [
    {
      name: ["Total number of insured"],
      data: [
        853.2461127931325,
        7496,
        "2021-10-01T00:00:00",
        "#",
        "8f87af24-978f-4673-864e-5899668f97bd1",
      ],
    },
    {
      name: ["Other receivables"],
      data: [
        177.81385193016771,
        24915,
        "2021-10-01T00:00:00",
        "#",
        "8f87af24-978f-4673-864e-5899668f97bd2",
      ],
    },
    {
      name: ["row 6"],
      data: [
        71.7780604964891,
        10001529,
        "2021-10-01T00:00:00",
        "$",
        "8f87af24-978f-4673-864e-5899668f97bd3",
      ],
    },
    {
      name: ["Total number of insured"],
      data: [
        8.2461127931325,
        7496,
        "2021-11-01T00:00:00",
        "#",
        "8f87af24-978f-4673-864e-5899668f97bd4",
      ],
    },
    {
      name: ["Other receivables"],
      data: [
        71.112312,
        24915,
        "2021-11-01T00:00:00",
        "#",
        "8f87af24-978f-4673-864e-5899668f97bd5",
      ],
    },
    {
      name: ["row 6"],
      data: [
        19.7780604964891,
        10001529,
        "2021-11-01T00:00:00",
        "$",
        "8f87af24-978f-4673-864e-5899668f97bd6",
      ],
    },
    {
      name: ["row 6"],
      data: [
        1.5504964891,
        10001529,
        "2021-12-01T00:00:00",
        "$",
        "8f87af24-978f-4673-864e-5899668f97bd7",
      ],
    },
  ],
};

const formData = {
  formName: "Update Debt Facility Data",
  investmentId: "8f87af24-978f-4673-864e-5899668f97bd",
  keyField: "Covenant",
  points: [
    {
      date: "2021-10-01",
      metrics: [
        {
          id: 12,
          name: "Net Default Ratio",
          value: 0.24,
          dataType: "$",
        },
        {
          id: 13,
          name: "Gross Default Ratio",
          value: 0.54,
          dataType: "$",
        },
        {
          id: 14,
          name: "Portfolio Size",
          value: 0.4,
          dataType: "#",
        },
        {
          id: 15,
          name: "Delinqunecy Ratio",
          value: 2.4,
          dataType: "%",
        },
      ],
    },
    {
      date: "2021-11-01",
      metrics: [
        {
          id: 21,
          name: "Net Default Ratio",
          value: 0.14,
          dataType: "$",
        },
        {
          id: 22,
          name: "Gross Default Ratio",
          value: 0.44,
          dataType: "#",
        },
        {
          id: 23,
          name: "Portfolio Size",
          value: 0.14,
          dataType: "$",
        },
      ],
    },
  ],
};
