import * as httpCall from "../../../../../Utils/apis/apis";
import * as commonFunctions from "../../../../../Utils/Helpers/common";
export const getAnnouncement = async (payload) => {
  const response = await httpCall.httpPost(
    `/GenericGremlin/generic/getAnnoucements`,
    payload
  );
  return response;
};
export const getBulkDocuments = async (payload) => {
  let subdomain = new URL(window.location.href).hostname.split(".")[0];
  const url = `${process.env.BULK_DOCUMENT_HELPER}`;

  const httpConfig = commonFunctions.getHttpConfig();
  let header = {
    ...httpConfig,
    "file-operation": "blob-items-link",
    "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
    subdomain: subdomain,
    Authorization: httpConfig.headers.Authorization,
  };

  let response = await httpCall.httpPost(url, payload, {
    responseType: "application/json",
    headers: header,
  });
  return response;
};
