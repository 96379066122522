import {
    httpPost
} from "../../../../../../../../Utils/apis/apis"

export const getChartData = async (json,payload) => {
    let res = await httpPost("/ChartData/getchartdata", payload);
    let newResponse = newsDataManipulatorEngine(res.data, json)
    return newResponse;
}
export const newsDataManipulatorEngine = (chartData, json) => {
    let tempChartData = {
        ...chartData
    }
    let dataList = [{
            keySet: tempChartData['categories'],
            valueSet: [],
        },
        {
            keySet: chartData['dimensions'],
            valueSet: []
        }
    ]
    let finalDataList = []
    let allSeriesList = tempChartData['series']
    for (let dataValueSet in allSeriesList) {
        let tempJson = {
            ...json
        }
        dataList[0]['valueSet'] = allSeriesList[dataValueSet]['data']
        dataList[1]['valueSet'] = allSeriesList[dataValueSet]['name']
        for (let key in tempJson) {
            tempJson[key] = valueParser(dataList, tempJson[key])
        }
        finalDataList.push(tempJson)
    }
    return finalDataList;
}
const valueParser = (dataLists, key) => {
    let keyIndex = -1
    let value = ""
    for (let dataSet in dataLists) {
        keyIndex = dataLists && dataLists[dataSet] && dataLists[dataSet]['keySet'] ? dataLists[dataSet]['keySet'].indexOf(key) : -1
        if (keyIndex !== -1) {
            value = dataLists && dataLists[dataSet] && dataLists[dataSet]['valueSet'] && dataLists[dataSet]['valueSet'][keyIndex] ? dataLists[dataSet]['valueSet'][keyIndex] : ""
            break;
        }
    }
    return value
}