import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { useParams } from "react-router";
import axios from "axios";
import * as commonFunctions from "../../../../../Utils/Helpers/common";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },

  tableCellWidth: {
    minWidth: "200px",
  },
  tableCell: {
    minWidth: "200px",
    border: "none",
    paddingTop: "5px",
    paddingBottom: "0px",
    marginLeft: "100px",
    padding: "0",
  },
}));

const ChangeLog = (props) => {
  let { crmId } = useParams();
  const classes = useStyles();
  const [selected] = React.useState([]);
  const [changeLogData, setChangeLogData] = React.useState([]);
  const [fieldName, setFieldName] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    let res = {};
    const getReports = async () => {
      const url = `${process.env.CHANGE_LOG_URL}`;
      const httpConfig = commonFunctions.getHttpConfig();
      let subdomain = new URL(window.location.href).hostname.split(".")[0];
      let header = {
        crmId: crmId,
        "x-functions-key": process.env.CHANGE_LOG_KEY,
        subdomain: subdomain,
        Authorization: httpConfig.headers.Authorization,
      };
      axios.get(url, { headers: header }).then((response) => {
        res = response.data;
        setChangeLogData(response.data);
        setLoading(false);
      }).catch(() => {setLoading(false)
        setChangeLogData([]);
      });
    };
    getReports();
    let fields = new Map();
    let templateJson;
    props.currentPage.templates.map((item) => {
      typeof item.templateJson == "string" && item.templateJson !== ""
        ? (templateJson = JSON.parse(item.templateJson))
        : (templateJson = item.templateJson);
      templateJson && templateJson.cards
        ? templateJson.cards.map((card) => {
            if (card.content.type === "crmdata") {
              card &&
              card.content &&
              card.content.content &&
              card.content.content.fields
                ? card.content.content.fields.map((field) => {
                    fields.set(field.id, field.label);
                  })
                : card.content.content.tabs
                ? card.content.content.tabs.map((tab) => {
                    tab.content &&
                      tab.content.fields &&
                      tab.content.fields.map((field) => {
                        fields.set(field.id, field.label);
                      });
                  })
                : "";
            }
          })
        : "";
    });
    setFieldName(fields);
    // templateData.map
  }, []);

  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Record Audit Trail
        </Typography>
      </Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const formattedData = (item) => {
    var date = item * 1000;
    const dateObject = new Date(date);
    const humanDateFormat = dateObject.toLocaleString();
    return <>{humanDateFormat}</>;
  };
  
  function isHTML(str) {
    var a = document.createElement('div');
    a.innerHTML = str;
  
    for (var c = a.childNodes, i = c.length; i--; ) {
      if (c[i].nodeType == 1) return true; 
    }
  
    return false;
  }

  return (
    <div>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : changeLogData.length > 0 ? (
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableCell>Modified By</TableCell>
                <TableCell>Modified Date</TableCell>
                <TableCell>Field Changed</TableCell>
                <TableCell>New Value</TableCell>
                <TableCell>Previous Value</TableCell>
              </TableHead>
              <TableBody>
                {changeLogData.map((item) => (
                  <TableRow key={item} hover role="checkbox">
                    <TableCell className={classes.tableCellWidth}>
                      {item.modifiedBy}
                    </TableCell>
                    <TableCell className={classes.tableCellWidth}>
                      {formattedData(item.modifiedDate)}
                    </TableCell>
                    <TableCell>
                      <Table>
                        {item.fieldChanged.map((item1) => (
                          <TableRow key={item1}>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {fieldName.get(item1)
                                ? fieldName.get(item1)
                                : item1}
                            </TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </TableCell>
                    <TableCell>
                      <Table>
                        {item.newValue.map((item) => (
                          <TableRow key={item}>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {item == null || item == ""
                                ? "-"
                                : typeof item == "number"
                                ? formattedData(item)
                                : isHTML(item) ?<div dangerouslySetInnerHTML={{__html:item}}></div>:item}
                            </TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </TableCell>
                    <TableCell>
                      <Table>
                        {item.previousValue.map((item) => (
                          <TableRow key={item}>
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {item == null || item == ""
                                ? "-"
                                : typeof item == "number"
        
                                ? formattedData(item)
                                :isHTML(item) ?<div dangerouslySetInnerHTML={{__html:item}}></div>:item}
                            </TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ): <div><h5><center>No trail found.</center></h5></div>}
      </Paper>
    </div>
  );
};
export default ChangeLog;
