import React from "react";
import TextField from "@material-ui/core/TextField";
import { InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import DoneIcon from "@material-ui/icons/Done";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { httpPost, httpPut } from "../../../../../Utils/apis/apis";
import { useParams } from "react-router";
import * as commonFunctions from "../../../../../Utils/Helpers/common";
const useStyles = makeStyles(() => ({
  button: {
    display: "flex",
    margin: "2px",
  },
  hidden:{
    display:"none"
  },
  shown:{
    display:"block"
  },
  approveButton: {
    backgroundColor: "#4CAF50",
    textTransform: "none",
    color:"white",
    fontWeight:"600",
  },
  rejectButton: {
    backgroundColor: "red",
    color:"white",
    fontWeight:"600",
    marginLeft: "10px",
    textTransform: "none",
  },
  add: {
    width: "100%",
  },
}));

// eslint-disable-next-line react/display-name
export const ApproveAndReject = React.memo((props) => {
  const [fundaccountant, setfundaccountant] = React.useState(true);
  const [loading, setLoading] = React.useState();
  const [Savedresponse, setSavedresponse] = React.useState(false)
  const { crmId,pageUrl } = useParams();
  const [comments, setcomments] = React.useState("");
  let resultDataforfundaccountant = [];
  const loggedInUserData = useSelector(
    (state) =>
      state.oidc.user &&
      state.oidc.user.profile &&
      state.oidc.user.profile.email
  );
  const classes = useStyles();
  var currentuser = loggedInUserData ? loggedInUserData : "";
  props&&props.content && props.content.fundAccountant&&props.content.fundAccountant[0].toLowerCase() == currentuser.toLowerCase()&&setfundaccountant(false);
  async function handleresponseView() {
    let res = await httpPost(
      `/CRMData/getViewTemplates?pageUrl=${pageUrl}&crmId=${crmId}`
    );
  }

  const approveAndReject = async (sub) => {
    let resUpdate={};
    let updatePayload=
    {
      "invoiceId": crmId,
      "comments": comments,
      "status": sub,
      "stage": props.approvalData
    }
    setLoading(true)
    try {
      let config = commonFunctions.getHttpConfig();
      let urlforUpdate = `${process.env.API_URL}/Finance/UpdateInvoice`;
      resUpdate=await httpPut(urlforUpdate,updatePayload,{ ...config,});
      resUpdate.then(props.getCurrentPage({ pageUrl, crmId }));
      return resUpdate;
      setLoading(false);
    } catch (e) {
      return (resUpdate = { message: e.message });
    }
  };
  function handleChange(event) {
    setcomments(event.target.value);
  }
  return (loading ? (
    <div style={{ textAlign: "center" }}>
      <CircularProgress style={{width:"50px",height:"50px",marginTop:"15px"}}/>
    </div>
  ) :/*condition*/fundaccountant===true &&(<> 
  <div className="" style={{fontFamily:"Roboto"}}>
  <strong>
    <h3 style={{color: "rgb(182 149 98)"}}>Comments</h3>
  </strong>
  <div className="">
    <InputLabel>
      <div className=""> My Comments: </div>
      <div className="">
        <TextField
         id="standard-basic"
          label="+Add"
          onChange={handleChange}
          className={classes.add}
        ></TextField>
      </div>
    </InputLabel>
    <div className={classes.button}>
      <Button
        className={classes.approveButton}
        onClick={()=>{
            approveAndReject(true)}
          }
          // sendEmail("Approve");
          //epoc date , any rejection/approval date in epoc, api will decide email should be sent or not , sendEmail true in payload if reqd
      >
    
        <DoneIcon />
        Approve
      </Button>

      <Button
        className={classes.rejectButton}
        onClick={()=>{
          approveAndReject(false);}
        }
      > <CloseIcon />
        Reject
      </Button>
    </div>
  </div>
</div></>)
  );
});
