import React, { useEffect, useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { useSelector } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useParams } from "react-router";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { onDownload } from "../../../Atoms/DataDisplay/DataGrid/helper/download.helper";
import GetAppIcon from "@material-ui/icons/GetApp";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import M_PDFReader from "./M_PDFReader";
import A_Divider from "../../../atoms/dataDisplay/dividers/A_Divider";
import propTypes from "prop-types";
const useStyles = makeStyles(() => ({
  h1: {
    "white-space": "nowrap",
    overflow: "hidden",
    "text-overflow": "ellipsis",
    width: "235px",
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
  },
  page: {
    display: "flex",
    alignItems: "left",
    justifyContent: "left",
    marginTop: "18px",
  },
}));

export default function ManageInvoices(props) {
  const classes = useStyles();
  const user = useSelector((state) => state.oidc.user);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = React.useState([]);
  let { crmId } = useParams();
  const [pdf, setPdf] = React.useState("");
  const [arr, setArr] = React.useState([]);
  const [fileDisplayName, setFileDisplayName] = React.useState("invoice.pdf");
  const handleChange = (event) => {
    setPdf(event.target.value);
  };

  let fileNamecontent = crmId + "/" + "invoice.pdf";
  props.data && props.data.invoicenumber ? props.data.invoicenumber : {};
  let container = props.content.container;
  const handleDataChange = async () => {
    let url = `${process.env.DOCUMENT_HELPER}`;
    let subdomain = new URL(window.location.href).hostname.split(".")[0];
    let header = {
      filename: fileNamecontent,
      type: container,
      "file-operation": "blob-items",
      "process-file": "yes",
      "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
      subdomain: subdomain,
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.access_token,
    };
    const response = await axios.post(url, "", { headers: header });
    response && response.statusText == "No Content" && setIsLoading(false);
    setData(response.data);
    if (response.data && response.data.length > 0) {
      response.data.map((item) => {
        arr.push(item.file.split("/")[1].toLowerCase());
      });
      setArr(arr);
    }
  };

  React.useEffect(() => {
    handleDataChange();
    if (props.data && props.data.invoicenumber) {
      setFileDisplayName(`INV_${props.data.invoicenumber}.pdf`);
    }
  }, [props.data && props.data.invoicenumber]);
  const array = [];
  if (data.length > 0) {
    data.map((item) => {
      let pdfData = {
        name: item.file.split("/")[1].toLowerCase(),
        path: item.file,
      };
      array.push(pdfData);
      return;
    });
  }
  useEffect(() => {
    if (array.length > 0 && pdf === "") {
      setPdf(array[0].path);
      setIsLoading(true);
    }
  }, [array]);

  return (
    <div>
      {array && array.length > 0 ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginTop: "-3%",
            }}
          >
            <FormControl sx={{ m: 1 }}>
              <InputLabel
                style={{
                  color: "black",
                  fontSize: "1.2rem",
                  fontWeight: "450",
                  marginBottom: "50px",
                  marginLeft: "15px",
                }}
              ></InputLabel>
              {array && array.length > 0 && (
                <Select
                  style={{
                    width: "250px",
                    border: "1px solid gray",
                    borderRadius: "4px",
                    visibility: "hidden",
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  value={pdf}
                  onChange={handleChange}
                  label="pdf"
                >
                  {array &&
                    array.length > 0 &&
                    array.map((item, index) => (
                      <Tooltip
                        key={index}
                        title={item.name}
                        value={item.path}
                        placement="bottom"
                        disableFocusListener
                        disableTouchListener
                        arrow
                      >
                        <MenuItem
                          key={item.name}
                          value={item.name}
                          style={{ maxWidth: "250px", fontSize: "14px" }}
                        >
                          <span className={classes.h1}>{item.name}</span>
                        </MenuItem>
                      </Tooltip>
                    ))}
                </Select>
              )}
            </FormControl>
          </div>
          <br />
          <A_Divider></A_Divider>
          <GetAppIcon
            style={{
              color: "#1976D2",
              cursor: "pointer",
              fontSize: "30px",
              position: "relative",
              marginTop: "-57px",
              marginLeft: "94%",
            }}
            onClick={function () {
              onDownload(fileNamecontent, container, true, fileDisplayName);
            }}
          ></GetAppIcon>
          <M_PDFReader pdf={pdf} container={container} />
        </>
      ) : isLoading ? (
        <>
          <Skeleton variant="text" width={"20%"} height={80} />
          <Skeleton variant="text" width={"40%"} height={80} />
          <Skeleton variant="text" width={"80%"} height={80} />
          <Skeleton variant="text" width={"80%"} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
        </>
      ) : (
        <div className={classes.noData}>
          <p>WE ARE SORRY, NO CONTENT FOUND!</p>
        </div>
      )}
    </div>
  );
}

ManageInvoices.propTypes = {
  data: propTypes.any,
  content: propTypes.any,
};
