import * as React from "react";
import A_TextField from "../TextFields/A_TextField";
import theme from "../../../../Themes/defaultTheme";
import { ThemeProvider } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  mainDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "50%",
  },
  formValueField: {
    maxWidth: "150px",
    paddingTop: "20px",
    paddingLeft: "5px",
    width: "155px",
    wordBreak: "break-word",
  },
  formValueFieldContainer: {
    width: "155px",
    minWidth: "155px",
  },
  percentageContainer: {
    width: "155px",
    minWidth: "150px",
    display: "flex",
    flexDirection: "row",
    padding: 0,
  },
}));

const A_FormTextField = (props) => {
  const classes = useStyles();

  const roundof = (value) => {
    if (typeof value === "number") return parseFloat(value.toFixed(4));
    else return value;
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.mainDiv}>
        <div className={classes.formValueField}>{props.label}</div>
        {props.dataType && props.dataType === "percentage" ? (
          <div className={classes.percentageContainer}>
            <A_TextField
              label=""
              variant="standard"
              defaultValue={roundof(props.value)}
              inputType="value"
              onChange={props.onChange}
              type="number"
            ></A_TextField>
            <div style={{ marginTop: "10px" }}>
              <p>%</p>
            </div>
          </div>
        ) : (
          <div className={classes.formValueFieldContainer}>
            <A_TextField
              label=""
              variant="standard"
              defaultValue={roundof(props.value)}
              inputType="value"
              onChange={props.onChange}
              type="number"
            ></A_TextField>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};
A_FormTextField.defaultProps = {
  label: "",
  dataType: "",
  value: "",
};
A_FormTextField.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  dataType: PropTypes.string,
  value: PropTypes.string,
};
export default A_FormTextField;
