import React from "react";

export const handleOptionSetValue = (value, sendLabel = true) => {
  let tempValue = value;
  try {
    let tempValueAsJSON = JSON.parse(tempValue);
    let finalValue = {
      label: "",
      value: "",
    };
    if (Array.isArray(tempValueAsJSON)) {
      for (let option in tempValueAsJSON) {
        finalValue["label"] += `${option > 0 ? "," : ""} ${handleOptionSetValue(
          tempValueAsJSON[option]
        )}`;
        finalValue["value"] += `${option > 0 ? "," : ""} ${handleOptionSetValue(
          tempValueAsJSON[option],
          false
        )}`;
      }
    } else {
      finalValue = tempValueAsJSON;
    }
    tempValue = sendLabel ? finalValue.label : finalValue.value;
  } catch {}
  return tempValue ? tempValue : value;
};
export const currency = {
  "pound sterling": "£ ",
  rupee: "₹ ",
  euro: "€ ",
  "us dollar": "$ ",
  "dansk krone": "kr ",
  "svensk krona": "kr ",
  "schweizer franken": "CHF ",
  "new zealand dollar": "$ ",
  złoty: "zł ",
  "norwegian krone": "kr ",
  leu: "lei ",
  "canadian dollar": "$ ",
  "australian dollar": "$ ",
  "koruna česká": "Kč ",
  usd: "$ ",
  aud: "A$ ",
  eur: "€ ",
  gbp: "£ ",
};
export const getIcon = (crmData, val) => {
  let icon, value;
  try {
    value = JSON.parse(crmData.currency || crmData.Currency);
    value = value[0].title.toLowerCase();
  } catch (e) {
    value =
      crmData && crmData.currency
        ? crmData.currency.toLowerCase()
        : crmData.Currency.toLowerCase();
  }
  var newVal = handleOptionSetValue(value, false);
  icon = currency[newVal] == undefined ? "" : currency[newVal];
  try {
    let orgValue = val;
    if (!isNaN(val)) {
      val = val ? Number(val).toLocaleString("en-GB") : "";
    } else {
      val = orgValue;
    }
  } catch {}
  return (
    <>
      {val ? icon : ""}
      {val}
    </>
  );
};
