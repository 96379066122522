import Moment from "moment";
import { dateConstants } from "../config/config";
import { handleOptionSetValue } from "./optionSet.helper";

export const getFormattedDate = (
  anyDate,
  anyFormat,
  fallBackFormat = "MMM-yy-dd"
) => {
  if (anyDate === undefined || anyDate === 0) return "";
  const newDate = Moment(anyDate).format(
    anyFormat ? anyFormat : fallBackFormat
  );
  return newDate == "Invalid date" ? "" : newDate;
};

export const customDateFormat = (anyDate, format) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  if (anyDate && anyDate != 0) {
    typeof anyDate === "string" ? (anyDate = new Date(anyDate)) : "";
    var halfMonth = months[anyDate.getMonth()].substring(0, 3);
    var fullMonth = months[anyDate.getMonth()];
    var fullYear = anyDate.getFullYear();
    var numericMonth = ("0" + (anyDate.getMonth() + 1)).slice(-2);
    var utcDate = ("0"+ anyDate.getDate()).slice(-2);
    var utcMonth = anyDate.getMonth() + 1;
    switch (format) {
      case 1:
        return utcDate + "/" + utcMonth + "/" + fullYear;
      case 2:
        return halfMonth + "-" + fullYear;
      case "MMM-YYYY":
        return halfMonth + "-" + fullYear;
      case "DD/MM/YYYY":
        return utcDate + "/" + numericMonth + "/" + fullYear;
      case "DD/MM/YY":
        return utcDate + "/" + numericMonth + "/" + (fullYear % 100);
      case "MM/DD/YY":
        return numericMonth + "/" + utcDate + "/" + (fullYear % 100);
      case "YY/MM/DD":
        return (fullYear % 100) + "/" + numericMonth + "/" + utcDate;
      case "DD MMMM YYYY":
        return utcDate + " " + fullMonth + " " + fullYear;
      case "DD-MMMM-YYYY":
        return utcDate + "-" + fullMonth + "-" + fullYear;
      case "DD/MMMM/YYYY":
        return utcDate + "/" + fullMonth + "/" + fullYear;
      case "DD MMM YYYY":
        return utcDate + " " + halfMonth + " " + fullYear;
      case "DD-MMM-YYYY":
        return utcDate + "-" + halfMonth + "-" + fullYear;
      case "DD/MMM/YYYY":
        return utcDate + "/" + halfMonth + "/" + fullYear;
      default:
        return utcDate + "/" + utcMonth + "/" + fullYear;
    }
  }
};

export const FromEpochFormat = (dateValue) => {
  return typeof dateValue === "number" && dateValue > 0
    ? new Date(dateValue * 1000)
    : dateValue;
};

export const toEpochFormat = (dateValue) => {
  let convertedDateValue = Math.floor(dateValue.getTime() / 1000.0);
  return convertedDateValue ? convertedDateValue : dateValue;
};

export const timeReplaceFromEpoch = (dateValue, time) => {
  let timeValue = handleOptionSetValue(time);
  let newDateValue =
    typeof dateValue === "number" ? new Date(dateValue * 1000) : dateValue;
  if (typeof newDateValue === "object") {
    let tempDate = new Date(
      newDateValue.getFullYear(),
      newDateValue.getMonth(),
      newDateValue.getDate(),
      timeValue.split(":")[0],
      timeValue.split(":")[1]
    );
    newDateValue = toEpochFormat(tempDate);
  }
  return newDateValue;
};

// New helper function that can format dates into most of the date formats if anyone wants to use date formatting from epoch to date please use it it will take 2 parameters 1st as an integer and 2nd as a string which is the date format
export const epochToDateFormatHelper = (
  dateInEpoch = 0,
  formatInString = ""
) => {
  try {
    if (dateInEpoch === "") {
      return dateInEpoch;
    }
    let dateStringInEpoch = dateInEpoch;
    let dateTimeString = new Date(
      typeof dateStringInEpoch === "string"
        ? dateStringInEpoch
        : typeof dateStringInEpoch === "number"
        ? dateStringInEpoch * 1000
        : ""
    );
    const months = dateConstants.MONTHS;
    let day = dateTimeString.getUTCDate().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    let month = dateTimeString.getUTCMonth();
    let year = dateTimeString.getUTCFullYear();
    let hour = dateTimeString.getUTCHours().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    let minute = dateTimeString.getUTCMinutes().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    let second = dateTimeString.getUTCSeconds().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    let monthDisplay = month.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    let monthShort = months[month].slice(0, 3);
    let monthLong = months[month];
    let format = formatInString;
    const formattedDate = format
      .replace("YYYY", year)
      .replace("YY", year.toString().slice(-2))
      .replace("MMMM", monthLong)
      .replace("MMM", monthShort)
      .replace("MM", monthDisplay)
      .replace("DD", day)
      .replace("HH", hour)
      .replace("mm", minute)
      .replace("ss", second);
    return formattedDate;
  } catch (e) {
    return dateInEpoch;
  }
};
