import React, { useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { httpPut } from "../../../../../Utils/apis/apis";
import defLogo from "../../../../../Assets/Images/Defaults/meetingDef.jpg";
import {
  FromEpochFormat,
  getFormattedDate,
} from "../../../../../Utils/Helpers/dateUtils";
import PropTypes from "prop-types";
import {
  meetingPreference,
  dateFormat,
} from "../../../../../Utils/config/config";
const useStyles = makeStyles({
  Label: {
    fontWeight: "600",
  },
  main: {
    height: "335px",
    width: "720px",
    paddingLeft: "20px",
    marginBottom: "10px",
  },
  calender: {
    border: "3px solid grey",
    backgroundColor: "#f5f5f0",
    display: "flex",
    padding: "20px 10px 0px 5px",
  },
  icon: {
    height: "80px",
    width: "60px",
    color: "#3d5afe",
    paddingBottom: "20px",
  },
  uppardiv: {
    paddingLeft: "10px",
  },
  color: {
    color: "black",
  },
  selected: {
    backgroundColor: "rgba(1,1,1,0.05)",
  },
  heading: {
    fontWeight: "bold",
    margin: "15px 0px 15px 0px",
  },
  boldText: {
    fontWeight: "bold",
    color: "#337AB7",
  },
  innerdiv: {
    marginTop: "5px",
  },
  smallRadioButton: {
    "& svg": {
      width: "0.7em",
      height: "0.7em",
    },
  },
});

export default function M_MeetingDetails(props) {
  const classes = useStyles();
  const [result, setResult] = React.useState([]);
  const [checkboxVal, SetCheckboxVal] = React.useState("none");
  const [disable, SetDisable] = React.useState(false);
  const [prefObj, SetPrefObj] = React.useState([]);

  useLayoutEffect(() => {
    SetCheckboxVal("none");
    if (props.modify) {
      let outJson = [];
      if (props.data && props.data && props.data.attendees) {
        outJson = props.data.attendees;
      }
      let completeAddress = [];
      for (var i = 0; i < outJson.length; i++) {
        completeAddress.push(
          outJson[i]
            ? outJson[i].split("@").length > 1
              ? outJson[i].split("@")[1]
              : ""
            : ""
        );
      }
      completeAddress = completeAddress.filter((e) => e !== "equipped.ai");
      const mySet = [...new Set(completeAddress)];
      setResult(mySet);
      if (mySet.length == 0) {
        SetDisable(true);
      } else {
        SetDisable(false);
      }
    }
    SetPrefObj([]);
  }, [props]);

  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  function getTime(data) {
    var time = data;
    if (typeof time != "object") {
      time = FromEpochFormat(time);
      let filterTime = getFormattedDate(time, "HH:mm");
      return filterTime;
    }
    if (props.modify) {
      let newDateJSON = time;
      let newDate = new Date(newDateJSON.dateTime + "Z");
      let h = newDate.getHours();
      let m = addZero(newDate.getMinutes());
      let Timee = h + ":" + m;
      return Timee;
    }
  }

  const getDates = (data) => {
    if (data != "") {
      var date = data;
      if (typeof date != "object") {
        date = FromEpochFormat(date);
        let filterDate = getFormattedDate(date, dateFormat.meetingPreference);
        return filterDate;
      }
      if (props.modify) {
        let newDateJSON = date;
        let newDate = new Date(newDateJSON.dateTime + "Z");
        let year = newDate.getFullYear();
        let Month = newDate.getMonth() + 1;
        let day = newDate.getDate();
        let Datee = year + "-" + Month + "-" + day;
        return Datee;
      }
    } else {
      return meetingPreference.NODATA;
    }
  };
  function avail(data, preferences) {
    var retData = true;
    for (let i = 0; prefObj.length > i; i++) {
      if (prefObj[i].domain == data && prefObj[i].preference == preferences) {
        retData = false;
      } else {
        if (prefObj[i].domain == data) {
          prefObj.splice(i, 1);
        }
        retData = true;
      }
    }
    return retData;
  }
  function handleChanges(event) {
    let domain = event.target.name;
    let preference = event.target.value;
    if (avail(domain, preference)) {
      var formData = {
        domain: domain,
        preference: preference,
      };
      SetPrefObj([...prefObj, formData]);
    }
  }

  async function handleChangess() {
    if (result.length == prefObj.length) {
      SetDisable(true);
      SetCheckboxVal("none");
      let convertedFormData = {
        id: props.loggedinUser,
        label: "contact",
        properties: {
          meetingPrefernce: JSON.stringify(prefObj),
        },
      };
      await httpPut(
        `${process.env.API_URL}/CRMData/update?pageUrl=contact`,
        convertedFormData
      );
      let convertedFormData2 = {
        id: props.data.id,
        label:
          props && props.content && props.content.payloadLabel
            ? props.content.payloadLabel
            : "meeting",
        properties: {
          IsTagged: true,
        },
      };

      await httpPut(
        `${process.env.API_URL}/CRMData/update?pageUrl=contact`,
        convertedFormData2
      );
      props.callback();
      SetCheckboxVal("none");
      SetDisable(false);
    } else {
      SetCheckboxVal();
    }
  }

  return (
    <>
      <div className={classes.main}>
        <div className={classes.calender}>
          <div>
            <img src={defLogo} className={classes.icon} />
          </div>
          <div className={classes.uppardiv}>
            <div>
              <h6>
                Date:{" "}
                {getDates(
                  props.data && props.data && props.data.startDT
                    ? JSON.parse(props.data.startDT)
                    : ""
                )}{" "}
                Time:{" "}
                {getTime(
                  props.data && props.data && props.data.startDT
                    ? JSON.parse(props.data.startDT)
                    : ""
                )}
              </h6>
              <h5>
                {props.data && props.data && props.data.subject
                  ? props.data.subject
                  : ""}
              </h5>
            </div>
          </div>
        </div>

        {result.map((item) => (
          <>
            <h6 className={classes.heading}>
              {meetingPreference.MEETINGSYNC}{" "}
              <span className={classes.boldText}>{item}</span>
            </h6>

            <div className={classes.innerdiv}>
              <RadioGroup
                row
                key={props.data && props.data.id ? props.data.id : ""}
                onChange={handleChanges}
              >
                <FormControlLabel
                  className={classes.smallRadioButton}
                  size="small"
                  value="All"
                  name={item}
                  control={<Radio color="primary" />}
                  label={meetingPreference.ALWAYSTAG}
                />

                <FormControlLabel
                  className={classes.smallRadioButton}
                  defaultValue={""}
                  size="small"
                  name={item}
                  value="Select"
                  control={<Radio color="primary" />}
                  label={meetingPreference.ASKBEFORE}
                />
                <FormControlLabel
                  className={classes.smallRadioButton}
                  defaultValue={""}
                  size="small"
                  name={item}
                  value="None"
                  control={<Radio color="primary" />}
                  label={meetingPreference.NEVERTAG}
                />
              </RadioGroup>
            </div>
          </>
        ))}
        <div>
          <A_Button
            style={{ marginTop: result.length == 0 ? "8px" : "" }}
            disabled={disable}
            onClick={() => handleChangess()}
            color="primary"
            label="SAVE"
          />
          <span style={{ display: checkboxVal, color: "red" }}>
            {meetingPreference.SELECTALLDOMAIN}
          </span>
        </div>
      </div>
    </>
  );
}
M_MeetingDetails.propTypes = {
  modify: PropTypes.bool,
  data: PropTypes.object,
  callback: PropTypes.func,
  loggedinUser: PropTypes.string,
  process: PropTypes.string,
};
