/* eslint-disable */
import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import { useRef } from "react";
import { httpPost } from "../../../../../Utils/apis/apis";
import A_Snackbar from "../../Feedback/Snackbars/A_Snackbar";
import "./A_ProfilePic.css";
import { useParams } from "react-router";

const A_ProfilePicture = (props) => {
  const inputFile = useRef(null);
  let { crmId } = useParams();

  function editPic() {
    inputFile.current.click();
  }
  const savePic = async (e) => {
    let resultFiles = [];
    let files = e.target.files;
    for (let file of files) {
      resultFiles.push(file);
    }
    console.log(files);
    if (files[0].type.match(/image.*/)) {
      var formData = new FormData();
      formData.append("file", resultFiles[0]);

      console.log(formData);

      let res = await httpPost(
        `/SecureImageBlob/uploadResizedImageBlob?crmId=${crmId}`,
        formData
      );
       props.callback();
    } else {
      // <A_Snackbar message="Please Select an Image" />;
      Console.log("Please Select an Image");
    }
  };

  return (
    <div className="profile">
      <img className="img" src={props.src} alt={props.alt} />
      <div className="edit">
        <EditIcon
          onClick={() => {
            editPic();
          }}
          className="Icon"
        />
        <input
          type="file"
          id="file"
          accept="image/*"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={(e) => {
            savePic(e);
          }}
        />
      </div>
    </div>
  );
};
A_ProfilePicture.defaultProps = {
  alt: "Logo",
  src: "",
};
export default A_ProfilePicture;
