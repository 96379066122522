 /* eslint-disable */
import React from "react";
import PropTypes from 'prop-types';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReportIcon from '@material-ui/icons/Report'; 
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import {log_error, log_info } from "../../../../../utils/logger/logger";
import {supportedBrowsersList,getClientBrowser} from "../../../../../utils/helpers/common";
import "./ErrorBoundary.scss";
import A_Card from "../../../atoms/surfaces/cards/A_Card";
import A_Button from "../../../atoms/inputs/buttons/A_Button";
import A_Link from "../../../atoms/navigation/links/A_Link";
import A_AppBar from "../../../atoms/surfaces/appbars/A_AppBar";
const ErrorSummary=()=>{
    return(
      <React.Fragment >
          {/* <A_AppBar content={<span>&nbsp;&nbsp;Unsupported browser</span>} /> */}
          <span className="error-summary">Unsupported browser</span>
      </React.Fragment>
      
    )
  };
  const ErrorDetail=(props)=>{
    let errDetail="";
    let currentBrowser=props.clientBrowser;
    supportedBrowsersList.map(browser => {
      if(browser.name===currentBrowser.name){
        currentBrowser.upgradeLink=browser.upgradeLink;
      }
    });

    if(props.browserType ==="unsupported"){
      errDetail= (<span className="error-detail">
        For best experience, you can try from below browsers.
        {
          supportedBrowsersList.map(browser => {
            return <A_Link key={browser.name} href={browser.upgradeLink} text={browser.name}/>;
          })
        } 
        </span>);
    }
    if(props.browserType==="older"){
      errDetail = (
        <span className="error-detail">For best experience, upgrade your browser.
          <br/><br/> You can try from below browsers.
          <A_Link href={currentBrowser.upgradeLink} text={currentBrowser.name}/>
          {
            supportedBrowsersList.map(browser => {
              if(browser.name !== currentBrowser.name){
                return <A_Link key={browser.name} href={browser.upgradeLink} text={browser.name}/>;
              }
              
            })
          }        
        </span>
      );
    }
    return(
      <React.Fragment>
        <b className="error-message">{props.browserType ==='older' ? 'Your browers is not updated' : 'This browser is not supported.'}</b>
        <br/>
        {errDetail}
      </React.Fragment>
    )
  }
  
  const M_UnsupportedBrowser=(props)=>{
    let clientBrowser=getClientBrowser();
    let browserType="unsupported";
    if(supportedBrowsersList.some((browser)=>{return browser.name===clientBrowser.name && parseFloat(browser.version) > parseFloat(clientBrowser.version)})){
      browserType="older";
    }

    return(
        <A_Card 
        title={<ErrorSummary/>} 
        content={<ErrorDetail browserType={browserType} clientBrowser={clientBrowser} />}
        >
        </A_Card>
      )
  }

export default M_UnsupportedBrowser;