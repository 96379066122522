/* eslint-disable */
import { httpPut } from "../../../../Utils/apis/apis";
import { toEpochFormat } from "../../../../Utils/Helpers/dateUtils";
export const tradeApproval = async (
  comments,
  type,
  recordId,
  userid,
  pageUrl,
  handleSnackbar,
  label
) => {
  let contactCrm = localStorage.getItem("contact_crm");
  userid = userid ? userid : contactCrm ? contactCrm : "";
  const security = {
    read: {
      groupids: [""],
      users: [""],
    },
    update: {
      groupids: [""],
      users: [""],
    },
    full: {
      groupids: [""],
      users: [],
    },
    privateRecord: false,
  };
  let currentDate = toEpochFormat(new Date());
  if (type == true) {
    var updatedData = {
      id: recordId,
      label: pageUrl,
      properties: {
        approvalStatus: type,
        comment: comments,
        approvalDate: currentDate,
      },

      security: security,
      edges: [
        {
          label: label,
          guid: [userid],
        },
      ],
    };
  } else {
    updatedData = {
      id: recordId,
      label: pageUrl,
      properties: {
        approvalStatus: type,
        comment: comments,
        approvalDate: currentDate,
      },

      security: security,
      edges: [
        {
          label: label,
          guid: [userid],
        },
      ],
    };
  }

  await httpPut(`/CRMData/${"update"}?pageUrl=${pageUrl}`, updatedData);
  handleSnackbar(type, pageUrl);
};
