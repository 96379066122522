/* eslint-disable */
import React from "react";
import A_Divider from "../../../atoms/dataDisplay/dividers/A_Divider";
import TextField from "@material-ui/core/TextField";
import * as commonFunctions from "../../../../../Utils/Helpers/common";
import ClearIcon from "@material-ui/icons/Clear";
import CircularProgress from "@material-ui/core/CircularProgress";
import DoneIcon from "@material-ui/icons/Done";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import {
  Box,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/styles";
import { httpGet, httpPost } from "../../../../../Utils/apis/apis";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useParams } from "react-router";
const useStyles = makeStyles(() => ({
  colorClass: {
    color: "#fff",
  },
  budgetApprovalCard: {
    padding: "0px 10px",
    fontFamily: "Roboto",
  },
  columnHeader: {
    backgroundColor: "#045484",
  },
  rowColor: {
    background: "#fafafa",
  },
  nextApproverName: {
    fontWeight: "bold",
    fontFamily: "Roboto",
  },
  textFieldRender: {
    marginLeft: "130px",
    marginTop: "-28px",
    fontFamily: "Roboto",
  },
  editIcon: {
    cursor: "pointer",
  },
  rejectButton: {
    marginLeft: "10px",
  },
  button: {
    marginTop: "10px",
  },
  closeIcon: {
    cursor: "pointer",
  },
  approveButtontwo: {
    display: "none",
  },
  approveButton: {
    display: "block",
  },
}));

function submissionApproval(props) {
  const [openPopOver, setOpenPopOver] = React.useState(false);
  const { crmId, pageUrl } = useParams();
  const [fundaccountant, setfundaccountant] = React.useState(true);
  const [loading, setLoading] = React.useState();
  const [nextApproverData, setNextApproverData] = React.useState([]);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const loggedInUserData = useSelector(
    (state) =>
      state.oidc.user &&
      state.oidc.user.profile &&
      state.oidc.user.profile.email
  );
  const [showAdmin, setShowAdmin] = React.useState(true);
  const [nextApprover, setNextApprover] = React.useState("");
  const [adminval, setadminval] = React.useState("");
  let resultData = [];
  let resultDataforfundaccountant = [];
  let resultDataValue = [];
  let Invoicereadystatus = [];
  let Invoicegenerator = [];

  React.useEffect(() => {
    getApprovalsData();
    var currentuser = loggedInUserData ? loggedInUserData : "";
  }, []);

  const classes = useStyles();

  const getApprovalsData = async () => {
    let config = commonFunctions.getHttpConfig();

    let urlforUpdate = `${process.env.API_URL}/Submission/getApproverRecords?recordId=${crmId}`;
    const payload = {
      id: crmId,
    };
    resultDataValue = await httpGet(urlforUpdate, { ...config });
    const indexVal =
      (await resultDataValue) &&
      resultDataValue &&
      resultDataValue.findIndex((object) => {
        return object.Approved === null || object.Approved != true;
      });
    for (let i = 0; i < 3; i++) {
      indexVal <= -1 &&
      resultDataValue[Math.abs(indexVal - 1)].Approved != true &&
      resultDataValue[Math.abs(indexVal + 1)].Approved != true &&
      resultDataValue[Math.abs(indexVal + 2)].Approved != true
        ? props.onChangeSubmission(
            resultDataValue[0].Email,
            indexVal + 1,
            true,
            resultDataValue[Math.abs(indexVal - 1)].Approved,
            true
          )
        : indexVal > -1 &&
          props.onChangeSubmission(
            resultDataValue[indexVal].Email,
            indexVal + 1,
            true,
            resultDataValue[Math.abs(indexVal - 1)].Approved,
            true
          );
      indexVal < 0 &&
        setadminval("Submission is fully reviewed") &&
        setLoading(Invoicereadystatus);
      indexVal >= 0 &&
        setadminval(
          resultDataValue && indexVal >= 0 && resultDataValue[indexVal].Name
        );
      setLoading(Invoicereadystatus);
      setNextApproverData(resultDataValue);
      break;
    }
  };

  function generateTableCell(rowKey, rowData) {
    if (rowKey == "Name") {
      let name = rowData.Name;
      return (
        <TableCell key="Name" style={{ textAlign: "left" }}>
          {name}
        </TableCell>
      );
    }
    if (rowKey == "Approved") {
      //for now manually enabled
      return rowData.Approved == true ? (
        <TableCell key="Approved" style={{ textAlign: "left" }}>
          <CheckIcon style={{ color: "rgb(0 183 53)" }} />
        </TableCell>
      ) : rowData.Approved == false ? (
        <TableCell key="Approved" style={{ textAlign: "left" }}>
          <ClearIcon style={{ color: "red" }} />
        </TableCell>
      ) : (
        <TableCell key="Approved"></TableCell>
      );
    }
    if (rowKey == "Date") {
      let date = rowData.Date;
      const standarddate = new Date(date * 1000);
      let valDate = standarddate.toLocaleDateString("en-GB");

      return date == null ? (
        <TableCell key="Date" style={{ textAlign: "left" }}></TableCell>
      ) : (
        <TableCell key="Date" style={{ textAlign: "left" }}>
          {valDate}
        </TableCell>
      );
    }
    if (rowKey == "Stage") {
      let stage = rowData.Stage;
      return <TableCell key="Stage">{stage}</TableCell>;
    } else {
      return (
        <TableCell key={rowData[rowKey]} style={{ textAlign: "left" }}>
          {rowData[rowKey]}
        </TableCell>
      );
    }
  }
  const generateRows = (row) => {
    delete row.Email;
    let rowKeys = Object.keys(row);
    let rw = rowKeys.map((rowKey) => {
      return generateTableCell(rowKey, row);
    });
    return <TableRow>{rw}</TableRow>;
  };
  return (
    <>
      <Box className={classes.budgetApprovalCard}>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow className={classes.columnHeader}>
                <TableCell
                  className={classes.colorClass}
                  style={{ textAlign: "left" }}
                >
                  Stage
                </TableCell>
                <TableCell
                  className={classes.colorClass}
                  style={{ textAlign: "left" }}
                >
                  Reviewed
                </TableCell>
                <TableCell
                  className={classes.colorClass}
                  style={{ textAlign: "left" }}
                >
                  Date
                </TableCell>
                <TableCell
                  className={classes.colorClass}
                  style={{ textAlign: "left" }}
                >
                  Comments
                </TableCell>
                <TableCell
                  className={classes.colorClass}
                  style={{ textAlign: "left" }}
                >
                  Name
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {nextApproverData &&
                nextApproverData.map((row) => generateRows(row))}
            </TableBody>
          </Table>
        </TableContainer>

        <A_Divider />
      </Box>
    </>
  );
}

export default submissionApproval;
