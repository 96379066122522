/* eslint-disable */
import { httpPut } from "../../../../../Utils/apis/apis";
import { toEpochFormat } from "../../../../../Utils/Helpers/dateUtils";

export const saveCheckboxData = async (selectedData, data, callBack) => {
  const security = {
    read: {
      groupids: [""],
      users: [""],
    },
    update: {
      groupids: [""],
      users: [""],
    },
    full: {
      groupids: [""],
      users: [],
    },
    privateRecord: false,
  };
  if (selectedData && data.length > 0) {
    selectedData.map(async (item) => {
      for (let i in data[0].property) {
        if (data[0].property[i] === "currentDate") {
          const currentDate = new Date();
          data[0].property[i] = toEpochFormat(currentDate);
        }
      }
      let convertedFormData = {
        id: item,
        label: data[0].pageUrl,
        type: "vertex",
        properties: data[0].property,
        security: security,
        edges: "",
      };

      let response = "";
      response = await httpPut(
        `/CRMData/${"update"}?pageUrl=${data[0].pageUrl}`,
        convertedFormData
      );
    });
    callBack(data[0].snackBarMsg);
    return;
  }
};
export const approveSelected = async (
  selectedData,
  callBack,
  clickedBy,
  edgeLabel,
  properties
) => {
  if (selectedData) {
    let formData = selectedData.map((item) => ({
      id: item,
      label: edgeLabel ? edgeLabel : "",
      properties: properties,
      edges: [],
    }));
    await httpPut(`/CRMData/updateMultipleCRMRecords`, formData);
    callBack("Approved success");
  }
};
