 /* eslint-disable */
 import {
   take,
   put,
   select
 } from "redux-saga/effects";
 import axios from "axios";
 import {
   esgRecordActionType
 } from "../actions/actionTypes";
 import * as esgRecordAction from "../actions/esgActions";
 import * as commonFunctions from "../../Utils/Helpers/common";
 import handleEmailUsers from "../../components/Factory/Pages/Common/Esg/Helper/EsgHelper.api";

 export function* getEsgRecordSagas() {
   while (true) {
     yield take(esgRecordActionType.GET_ESG_RECORD);
     yield select();
     let config = commonFunctions.getHttpConfig();

     if (
       !config.headers.Authorization ||
       config.headers.Authorization == "Bearer undefined"
     ) {
       const serializedState = sessionStorage.getItem(
         `oidc.user:${process.env.OIDC_AUTHORITY}:${process.env.CLIENT_ID}`
       );
       if (serializedState === null) {
         return undefined;
       }
       const appState = JSON.parse(serializedState);
       if (appState && appState.access_token) {
         config.headers.Authorization = "Bearer " + appState.access_token;
       }
     }

     try {
       const res = yield axios.get(
         `${process.env.PortalAdminAPIUrl}/api/Survey/esg/getall`,
         config

       );
       yield put(
         esgRecordAction.getEsgRecordSucceeded({
           data: res.data,
           getEsgRecordSucceeded: true,
         })
       );
     } catch (e) {
       yield put(
         esgRecordAction.getEsgRecordFailed({
           msg: "Some error occurred",
           getEsgRecordFailed: true,
         })
       );
     }
   }
 }


 export function* getEsgRecordByIdSagas() {
   while (true) {
     const action = yield take(esgRecordActionType.GET_ESG_RECORD_BY_ID);
     yield select();
     let config = commonFunctions.getHttpConfig();
     const {
       id
     } = action.payload
     if (
       !config.headers.Authorization ||
       config.headers.Authorization == "Bearer undefined"
     ) {
       const serializedState = sessionStorage.getItem(
         `oidc.user:${process.env.OIDC_AUTHORITY}:${process.env.CLIENT_ID}`
       );
       if (serializedState === null) {
         return undefined;
       }
       const appState = JSON.parse(serializedState);
       if (appState && appState.access_token) {
         config.headers.Authorization = "Bearer " + appState.access_token;
       }
     }

     try {
       const res = yield axios.get(
         `${process.env.PortalAdminAPIUrl}/api/Survey/esg/get?id=${id}`,
         config
       );
       yield put(
         esgRecordAction.getEsgRecordSucceeded({
           data: res.data,
           getEsgRecordSucceeded: true,
         })
       );
     } catch (e) {
       yield put(
         esgRecordAction.getEsgRecordFailed({
           msg: "Some error occurred",
           getEsgRecordFailed: true,
         })
       );
     }
   }
 }


 export function* insertEsgFormSagas() {
   while (true) {
     const action = yield take(esgRecordActionType.INSERT_ESG_RECORD);

     const {questionObj,emailString,currentUser} = action.payload;
     console.log("insert esgform  is called:", action.payload);

     const state = yield select();
     let config = commonFunctions.getHttpConfig();
     try {
       const res = yield axios.post(
         `${process.env.PortalAdminAPIUrl}/api/Survey/esg/insert`,
         questionObj,
         config
       );
       handleEmailUsers(emailString,currentUser,res.data);

       yield put(
         esgRecordAction.insertEsgRecordSucceeded({
           insertData: res.data,
           insertEsgRecordSucceeded: true,
         })
       );
     } catch (e) {
      console.log("error",e)
       yield put(
        
         esgRecordAction.insertEsgRecordFailed({
           data: questionObj,
           insertEsgRecordFailed: true,
           errorMessage: e && e.response && e.response.data && e.response.data.Message,
         })
       );
     }
   }
 }

