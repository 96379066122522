
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import A_TextField from "../../../../../Atoms/Inputs/TextFields/A_TextField";
import A_Select from "../../../../../Atoms/Inputs/Selects/A_Select";
import A_Button from "../../../../../atoms/inputs/buttons/A_Button";
import A_CircularIndeternment from "../../../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import * as validators from "../../../../../../../Utils/Helpers/validations";
import * as httpCall from "../../../../../../../Utils/apis/apis";
import M_RadiosGroup from "../../../../../Molecules/Common/RadioGroup/M_RadiosGroup";
import A_CheckBox from "../../../../../Atoms/Inputs/Checkboxes/A_Checkbox";
import A_MultiSelect from "../../../../../Atoms/Inputs/Selects/A_MultiSelect";
import PropTypes from "prop-types";
const useStyles = makeStyles(() => ({
  container: {
    width: "430px",
    marginLeft: "10%",
  },
  inputcontrol: {
    width: 430,
  },
  inputcontrolbalacemetric: {
    width: 80,
  },
  inputcontrolrowbreak: {
    width: 79,
  },

  inputcontrolreport: {
    width: 80,
  },
  inputcontrolflipcolors: {
    width: 101,
  },
  inputcontrol1: {
    width: 430,
  },
  inputcontrolcheckbox: {
    width: 430,
    "& .MuiFormControlLabel-labelPlacementStart-700": {
      marginLeft: "4px",
    },
  },
  seperatorRow: {
    //border: "1px solid red",
    width: 400,
  },
  seperatorRowText: {
    fontSize: "15px",
    backgroundColor: "lightblue",
    height: "30px",
    color: "grey",
    padding: 5,
    borderRadius: 4,
    textAlign: "center",
    //border: "1px solid green",
  },
}));
const dataTypes = [
  { id: "General", label: "General", value: "General" },
  { id: "Percentage", label: "Percentage", value: "Percentage" },
  { id: "Multiple", label: "Multiple", value: "Multiple" },
];
const rowTypes = [
  { id: "standard", label: "Standard", value: "standard" },
  { id: "preCalculated", label: "Pre calculated", value: "preCalculated" },
  { id: "ratio", label: "Ratio", value: "ratio" },
];

const annualisationPeriodBasisOptions = [
  { id: "d", label: "Days", value: "d" },
  { id: "m", label: "Months", value: "m" },
];
const formattingClasses = [
  { label: "indent", value: "indent" },
  { label: "indent2", value: "indent2" },
  { label: "bold", value: "bold" },
  { label: "small", value: "small" },
  { label: "total row", value: "total_row" },
  { label: "italic", value: "italic" },
];

const M_ManageRow = (props) => {
  const getScalingData = (data) => {
    let scaling = data.scaling == 0 ? 1 : data.scaling;
    return {...data, scaling}
   };
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const isAdd =
    props.currentRow && props.currentRow.id === undefined ? true : false;

  const [formData, setFormData] = useState(getScalingData(props.currentRow));
  

  const [validationSummary, setValidationSummary] = useState(undefined);

  


  //handle Textboxes
  const handleTextboxes = (e) => {
    let field = e.target.id;
    let newFormData = { ...formData };
    newFormData[field] = e.target.value;
    setFormData(newFormData);
    //props.getFormData(newFormData, field);
  };

  //handle Radio-group
  const handleRadioGroup = (selectedValue, radioGroupId) => {
    let newFormData = { ...formData };
    newFormData[radioGroupId] = selectedValue.value;
    setFormData(newFormData);
    //props.getFormData(newFormData);
  };
  //handle dropdowns
  const handleDropdowns = (selectedValue, id) => {    
    let validations = {};
    validations.metricId = {
      error: false,
      msg: "",
    };
    setValidationSummary(validations);
    let newFormData = { ...formData };
    newFormData[id] = selectedValue.value;
    setFormData(newFormData);
  };

  //handle multi-select dropdown
  const handleMultiSelect = (selectedValue, id) => {
    let newFormData = { ...formData };
    let selectedItemsArr = [];
    if (selectedValue.length > 0) {
      selectedItemsArr = selectedValue.map((val) => val.value);
    }
    newFormData[id] = selectedItemsArr.join();
    setFormData(newFormData);
    // props.getFormData(newFormData);
  };
  //handle checkboxes
  const handleCheckbox = (isChecked, checkboxId) => {  
    let newFormData = { ...formData };
    newFormData[checkboxId] = isChecked;
    setFormData(newFormData);
    // props.getFormData(newFormData);
  };

  //metric-id is required bug : 19672
  React.useEffect(()=>{
    // let metrixId=props.metricesNew
    //  && 
    // props.metricesNew[Object.keys(props.metricesNew)[0]] && props.metricesNew[Object.keys(props.metricesNew)[0]][0] && props.metricesNew[Object.keys(props.metricesNew)[0]][0].id
    // setFormData({...formData,'metricId':metrixId})
  },[])
  const handleValidations = () => {
    let isValidated = true;
    let validations = {};

     //metric-id is required
     
    if (!validators.isRequired(formData.metricId)) {
      isValidated = false;
      validations.metricId = {
        error: true,
        msg: "This field is required",
      };
    }

    //scaling for numbers
    if (validators.isRequired(formData.scaling) && isNaN(formData.scaling)) {
      isValidated = false;
      validations.scaling = {
        error: true,
        msg: "Only numbers allowed",
      };
    }

    //decimal-places for numbers
    if (
      validators.isRequired(formData.decimalPlaces) &&
      isNaN(formData.decimalPlaces)
    ) {
      isValidated = false;
      validations.decimalPlaces = {
        error: true,
        msg: "Only numbers allowed",
      };
    }

    setValidationSummary(validations);
    return isValidated;
  };
  const handleSave = async () => {
    if (handleValidations()) {
      setIsLoading(true);

      if (isAdd) {
        let url = `${process.env.PortalAdminAPIUrl}/api/ReportEditor/portfolio-report-editor`;  
        let requestPayload =  {...formData};
        requestPayload.reportId = props.currentReport.reportId;
        requestPayload.sortOrder = props.rows.length + 1;
        requestPayload.displayName = !validators.isRequired(
          requestPayload.displayName
        )
          ? props.metrices.filter((metric) => {
              return requestPayload.metricId == metric.id;
            })[0].name
          : requestPayload.displayName;

        const result = await httpCall.httpPost(url, requestPayload);
        if ("message" in result) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setValidationSummary(undefined);
          let newFormData = { ...result };
          props.onRowSave(isAdd, newFormData);
        }
      } else {
        let url = `${process.env.PortalAdminAPIUrl}/api/ReportEditor/portfolio-report-editor/${formData.id}`;
        let requestPayload = { ...formData };
        requestPayload.displayName = !validators.isRequired(
          requestPayload.displayName
        )
          ? props.metrices.filter((metric) => {
              return requestPayload.metricId == metric.id;
            })[0].name
          : requestPayload.displayName;
        const result = await httpCall.httpPut(url, requestPayload);
        if ("message" in result) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setValidationSummary(undefined);
          let newFormData = { ...result };
          props.onRowSave(isAdd, newFormData);
        }
      }
    }
  };

  const groupingUpTheOptions = () => {
    let groupedOptions = [];
    props.metricesNew ? 
    Object.keys(props.metricesNew).map((metric) => {
      groupedOptions.push({title : metric, options : props.metricesNew[metric]})
    })
    : "";
    return groupedOptions;
  }
  return (
    <section className={classes.container}>
      <Grid container spacing={1} justify="center">
        <Grid item xs={12}>
          <M_RadiosGroup
            id="rowType"
            value={formData.rowType}
            defaultValue={formData.rowType}
            label="Metric type"
            radios={rowTypes}
            onChange={handleRadioGroup}
          />
        </Grid>
        <Grid item xs={12}>
          <A_Select
            id="metricId"
            variant="filled"
            className={classes.inputcontrol}
            label={
              formData.rowType == "preCalculated"
                ? "Current Month Metric"
                : "" || formData.rowType == "ratio"
                ? "Numerator Metric"
                : "" || formData.rowType == "standard"
                ? "Metric"
                : ""
            }
            error={
              validationSummary &&
              validationSummary.metricId &&
              validationSummary.metricId.error
            }
            helperText={
              validationSummary &&
              validationSummary.metricId &&
              validationSummary.metricId.msg
            }
            native={true}
            required={true}
            value={formData.metricId}
            defaultValue={formData.metricId}
            grouping={true}
            groupedOptions={groupingUpTheOptions()}
            options={
              props &&
              props.metrices.map((row) => {
                row.label = row.name;
                row.value = row.id;
                return row;
              })
            }
            onChange={handleDropdowns}
          />
        </Grid>
        {formData.rowType === "preCalculated" && (
          <Grid item xs={12}>
            <A_Select
              className={classes.inputcontrol}
              variant="filled"
              id="ytdMetric"
              label="YTD metric"
              helperText=""
              defaultValue={formData.ytdMetric}
              value={formData.ytdMetric}
              native={true}
              grouping={true}
              groupedOptions={groupingUpTheOptions()}
              options={
                props &&
                props.metrices.map((row) => {
                  row.label = row.name;
                  row.value = row.id;
                  return row;
                })
              }
              onChange={handleDropdowns}
            />
          </Grid>
        )}
        {formData.rowType === "ratio" && (
          <Grid item xs={12}>
            <A_Select
              id="ratioDenominator"
              variant="filled"
              className={classes.inputcontrol}
              label="Denominator Metric"
              helperText=""
              native={true}
              defaultValue={formData.ratioDenominator}
              value={formData.ratioDenominator}
              grouping={true}
              groupedOptions={groupingUpTheOptions()}
              options={
                props &&
                props.metrices.map((row) => {
                  row.label = row.name;
                  row.value = row.id;
                  return row;
                })
              }
              onChange={handleDropdowns}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography
            style={{ color: "black", position: "relative", top: "10px" }}
            variant="h6"
          >
            Display Options
          </Typography>
          {/* <Alert severity="info">Display options</Alert> */}
        </Grid>
        <Grid item xs={12}>
          <A_TextField
            id="displayName"
            variant="standard"
            className={classes.inputcontrol}
            label="Alternative display name"
            required={false}
            error={
              validationSummary &&
              validationSummary.displayName &&
              validationSummary.displayName.error
            }
            helperText={
              validationSummary &&
              validationSummary.displayName &&
              validationSummary.displayName.msg
            }
            value={formData.displayName}
            defaultValue={formData.displayName}
            onChange={handleTextboxes}
          />
        </Grid>

        <Grid item xs={12}>
          <A_MultiSelect
            variant="standard"
            id="classes"
            className={classes.inputcontrol}
            label="Row Styling"
            multiple={true}
            helperText=""
            value={formData.classes}
            defaultValue={formData.classes}
            options={formattingClasses}
            onChange={handleMultiSelect}
          />
        </Grid>
        <Grid item xs={12}>
          <A_TextField
            id="decimalPlaces"
            variant="standard"
            className={classes.inputcontrol}
            defaultValue={formData.decimalPlaces}
            label="Decimal Places"
            required={false}
            error={
              validationSummary &&
              validationSummary.decimalPlaces &&
              validationSummary.decimalPlaces.error
            }
            helperText={
              validationSummary &&
              validationSummary.decimalPlaces &&
              validationSummary.decimalPlaces.msg
            }
            value={formData.decimalPlaces}
            //defaultValue={formData.decimalPlaces}
            onChange={handleTextboxes}
          />
        </Grid>
        <Grid container style={{ marginLeft: "5px" }}>
          <Grid item xs={6}>
            <A_CheckBox
              classes={classes.inputcontrolrowbreak}
              id="rowBreakAfter"
              label="Row break after"
              labelPlacement="start"
              checked={formData.rowBreakAfter}
              onChange={handleCheckbox}
            />
          </Grid>
          <Grid item xs={6}>
            <A_CheckBox
              id="inverseParity"
              classes={classes.inputcontrolflipcolors}
              label="Flip variance colours"
              labelPlacement="start"
              checked={formData.inverseParity}
              onChange={handleCheckbox}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <A_CheckBox
            id="enabled"
            label="Show in report"
            labelPlacement="start"
            classes={classes.inputcontrolreport}
            checked={formData.enabled}
            onChange={handleCheckbox}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div>
                <Typography style={{ color: "black" }} variant="h6">
                  {" "}
                  Calculation Options
                </Typography>
              </div>
              {/* <Alert severity="info">Display options</Alert> */}
            </Grid>
            {formData.rowType === "ratio" && (
              <Grid item xs={12}>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    style={{ position: "relative"}}
                  >
                    <A_CheckBox
                      id="ratioAnnualise"
                      className={classes.inputcontrol}
                      label="Annualise numerator"
                      labelPlacement="start"
                      checked={formData.ratioAnnualise}
                      onChange={handleCheckbox}
                    />
                  </Grid>
                  {formData.ratioAnnualise && (
                    <Grid item xs={6}>
                      <M_RadiosGroup
                        id="annualisationPeriodBasis"
                        value={formData.annualisationPeriodBasis}
                        defaultValue={formData.annualisationPeriodBasis}
                        label="Period basis to use"
                        radios={annualisationPeriodBasisOptions}
                        onChange={handleRadioGroup}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={6}
                    style={{ position: "relative", right: "12px" }}
                  >
                    <A_CheckBox
                      id="ratioNPlusOneAvg"
                      label="n+1 months average denominator"
                      labelPlacement="start"
                      checked={formData.ratioNPlusOneAvg}
                      onChange={handleCheckbox}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <A_CheckBox
                id="isBalance"
                classes={classes.inputcontrolbalacemetric}
                label="Balance Metric"
                labelPlacement="start"
                checked={formData.isBalance}
                onChange={handleCheckbox}
              />
            </Grid>
            {formData.rowType === "ratio" && (
              <Grid item xs={12}>
                <A_Select
                  id="ratioDataType"
                  variant="filled"
                  className={classes.inputcontrol}
                  label="Data type"
                  helperText=""
                  defaultValue={formData.ratioDataType}
                  value={formData.ratioDataType}
                  native={true}
                  options={dataTypes}
                  onChange={handleDropdowns}
                />
              </Grid>
            )}
            <Grid item xs={12} style={{ position: "relative", bottom: "18px" }}>
              <A_TextField
                id="scaling"
                inputType="value"
                variant="standard"
                className={classes.inputcontrol1}
                label="Scaling"
                required={false}
                error={
                  validationSummary &&
                  validationSummary.scaling &&
                  validationSummary.scaling.error
                }
                helperText={
                  validationSummary &&
                  validationSummary.scaling &&
                  validationSummary.scaling.msg
                }
                value={formData.scaling}
                defaultValue={formData.scaling}
                onChange={handleTextboxes}
              />
            </Grid>
          </Grid>
        </Grid>

        {isLoading && (
          <section>
            {" "}
            <span>Saving</span> <A_CircularIndeternment />
          </section>
        )}
        {!isLoading && (
          <Grid style={{ position: "relative", left: "8rem" }}>
            <A_Button
              label="Cancel"
              color="default"
              onClick={props.onManageRowClose}
            />
            <A_Button
              label="Save"
              //disabled={isLoadingSaveReport}
              color="primary"
              onClick={handleSave}
            />
          </Grid>
        )}
      </Grid>
    </section>
  );
};
M_ManageRow.propTypes = {
  currentRow: PropTypes.any,
  metricesNew: PropTypes.any,
  onManageRowClose: PropTypes.func,
  metrices: PropTypes.any,
  onRowSave: PropTypes.func,
  rows: PropTypes.any,
  currentReport: PropTypes.any,
};
export default M_ManageRow;
