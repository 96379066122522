/* eslint-disable */
import {
    dealTeamActionType
  } from "../actions/actionTypes";
  
  let initialState = {};
  const dealTeamReducer = (state = initialState, action) => {
    switch (action.type) {
        case dealTeamActionType.DEL_DEAL_TEAM_EDGE:
            return {dealTeamReducer: true};
        case dealTeamActionType.DEL_DEAL_TEAM_EDGE_SUCCEEDED:
            return action.payload;
        case dealTeamActionType.DEL_DEAL_TEAM_EDGE_FAILED:
            return action.payload;
        case dealTeamActionType.GET_DEAL_TEAM_EDGE:
            return {dealTeamReducer: true};
        case dealTeamActionType.GET_DEAL_TEAM_EDGE_SUCCEEDED:
            return action.payload;
         case dealTeamActionType.GET_DEAL_TEAM_EDGE_FAILED:
            return action.payload;
      default:
        return state;
    }
  };
  export default dealTeamReducer;