/* eslint-disable */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router";
import * as managePageActions from "../../../../../../store/actions/managePageActions";
import * as currentPageActions from "../../../../../../store/actions/currentPageActions";
import * as toastActions from "../../../../../../store/actions/toastActions";
import A_SimpleDialog from "../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_HorizontalStepper from "../../../../Atoms/Navigation/Stepper/A_HorizontalStepper";
import { httpPost } from "../../../../../../Utils/apis/apis";
import { showField } from "../../../../../../Utils/Helpers/common";
import ErrorBoundary from "../../../../Molecules/Common/error/ErrorBoundary";
import * as manageEntityHelper from "./M_ManageEntityForm.helper";
import { handleUpdate } from "./handleUpdate";
import { security } from "./M_ManageEntityForm.Config";
import { useSelector } from "react-redux";
import { handleFileUpload } from "../../File Upload/M_fileUpload.helper";
import {
  toastType,
  toastSeverity,
} from "../../../../../../Utils/config/toastConfig";
import { message } from "../../../../../../Utils/config/messages";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import A_CircularIndeternment from "../../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import {
  fieldType,
  labelName,
  dealConfig,
  envName,
} from "../../../../../../Utils/config/config";
import {
  getFormattedDate,
  timeReplaceFromEpoch,
} from "../../../../../../Utils/Helpers/dateUtils";

import * as loggedInUserIdActions from "../../../../../../store/actions/loggedInUserIdActions";
import { hasPageFilter } from "../../Renderers/Views/Charts/M_ChartCard.Helper";
import { handleOptionSetValue } from "../../../../../../Utils/Helpers/optionSet.helper";

const useStyles = makeStyles(() => ({
  loader: {
    textAlign: "center",
  },
}));

const M_ManageEntityForm = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(props.open);
  const [showFormWarning, setShowFormWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [formSavedResponse, setFormSavedResponse] = useState(false);
  const [fileUploadConfig, setFileUploadConfig] = useState({});
  const [submitToStoredProc, setSubmitToStoredProc] = useState("");
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  let crmData = props.crmData ? props.crmData : {};
  let checkData = {
    security,
    ...crmData,
  };
  const [formData, setFormData] = useState(checkData);
  const [editFormData, setEditFormData] = useState({});
  const [steps, setSteps] = useState([]);
  const [allSteps, setAllSteps] = useState([]);
  const [fields, setFields] = useState([]);
  const [optionSets, setOptionSets] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  // eslint-disable-next-line react/prop-types
  const pageFilters = props.toolbarSelections.filters;
  let { crmId } = useParams();
  let { pageUrl } = useParams();
  let currentUserId =
    props.loggedIn && props.loggedIn.data && props.loggedIn.data[0]
      ? props.loggedIn.data[0].id
      : "";

  let recordId = crmId;
  let fileName;
  var filelist = [];
  const user = useSelector((state) => state.oidc.user);
  if (
    props.entity != null &&
    props.entity.recordId == "" &&
    props.editChart == true
  ) {
    props.entity.recordId = crmId;
  }

  if (props.entity != null && props.entity.recordId == null) {
    props.entity.recordId = crmId;
  }

  useEffect(() => {
    props.getPage(props.entity, recordId, props.templateId);
  }, [props.entity]);

  useEffect(() => {
    let templateJson = undefined;
    if (
      props.managePage &&
      props.managePage.data &&
      props.managePage.data.template
    ) {
      templateJson = props.managePage.data.template.templateJson;
      setFields(props.managePage.data.pageConfig.fields);
      setOptionSets(props.managePage.data.pageConfig.optionSets);
    }
    templateJson =
      templateJson !== "" && templateJson !== undefined
        ? JSON.parse(templateJson)
        : {};
    if (templateJson.submitToStoredProc) {
      setSubmitToStoredProc(templateJson.submitToStoredProc);
    }
    const stepsRaw = "stepper" in templateJson ? templateJson.stepper : [];
    if (stepsRaw.length > 0) {
      let formDataCopy = { ...formData };
      let elementsWithDefaultValues =
        manageEntityHelper.getDefaultValues(stepsRaw);
      elementsWithDefaultValues.forEach((ele) => {
        let elementValue = formDataCopy[ele.id];
        if (!elementValue || (elementValue && elementValue == "")) {
          formDataCopy[ele.id] = ele.defaultValue;
        }
      });
      if (!props.editChart) {
        setFormData(formDataCopy);
      } else {
        if (props.entity && props.entity.hasContainer) {
          setFormData(props.managePage.data.data);
        } else {
          if (
            props.managePage &&
            props.managePage.data &&
            props.managePage.data.data &&
            !props.edgeId
          ) {
            setFormData(props.managePage.data.data);
          } else {
            setFormData(crmData);
          }
        }
      }

      setAllSteps(stepsRaw);
      //check for show-hide conditon on first load of form
      let visibleSteps = handleShowHide(stepsRaw);
      setSteps(visibleSteps);
    }
  }, [props.managePage]);

  const handleClose = () => {
    if (formSavedResponse || isError) {
      handleFinish();
    } else {
      setShowFormWarning(true);
    }
    setFormSavedResponse(false);
    setConfirmationPopup(false);
  };
  const handleWarningOkButton = () => {
    setShowFormWarning(!showFormWarning);
  };
  const handleWarningCloseButton = () => {
    setOpen(false); //closing form-dialog
    setFormSavedResponse(false);
    setConfirmationPopup(false);
    props.onClose();
    props.resetManagePage();
  };
  const handleFinish = () => {
    setOpen(false); //closing form-dialog
    setFormSavedResponse(false);
    setConfirmationPopup(false);
    setShowFormWarning(false); //closing incomplete-form warning.
    props.onClose(); //closing add-entities-list
    props.resetManagePage();
  };

  const handleFormSaved = async () => {
    setIsLoading(true);
    let newFormData = { ...formData };
    // its just a patch for John Laing.
    try {
      if (
        props &&
        props.currentPage &&
        props.currentPage.pageConfig &&
        props.currentPage.pageConfig.description == "Portfolio Investments"
      ) {
        newFormData.Date = manageEntityHelper.getEcpochDateOfOptionSetMonth(
          newFormData.Date
        );
      }
    } catch (e) {
      console.log(e);
    }
    for (let i in newFormData) {
      if (
        newFormData[i] &&
        newFormData[i].fieldType &&
        newFormData[i].fieldType == fieldType.DOCUMENT
      ) {
        filelist.push(newFormData[i]);
        newFormData[i] = undefined;
      }
    }
    let edge = manageEntityHelper.setEdgeLabelData(newFormData);
    let security = { ...newFormData.security };
    delete newFormData.security;
    let label = "";
    if (props.isIRCSlot) {
      newFormData.ircDate = props.IRCSlotDate;
      newFormData.ircTime = props.IRCTime;
    }
    if (props.showPopup == true) {
      label =
        props.entity &&
        props.entity.parameters &&
        props.entity.parameters.pageUrl
          ? props.entity.parameters.pageUrl
          : "";
    } else {
      label =
        props.entity && props.entity.pageName && props.entity.pageName
          ? props.entity.pageName
          : "";
    }

    if (label === labelName.IRC_SLOTES) {
      newFormData.ircDate = timeReplaceFromEpoch(
        newFormData.ircDate,
        newFormData.ircTime
      );
    } else if (label === labelName.INVESTMENT) {
      let hostName = window.location.hostname;
      if (hostName === envName.VELDCAPITAL) {
        let tempDealConfig = { ...dealConfig };
        const currentDate = new Date();
        const curr = getFormattedDate(currentDate, "DD/MM/YYYY");
        tempDealConfig.statusHistory = tempDealConfig.statusHistory
          .split("=currentDate")
          .join(curr);
        newFormData = { ...newFormData, ...tempDealConfig };
      }
    }
    if (label === labelName.MEETING) {
      newFormData["IsTagged"] = false;
      newFormData["IsCancelled"] = false;
      newFormData["isOutlook"] = false;
      edge = [
        {
          label: "organiser",
          guid: [currentUserId],
          "two-way-label": "has_event",
        },
        ...edge,
      ];
    }
    let convertedFormData = {
      id: null,
      label: label,
      type: "vertex",
      properties: newFormData,
      security: security,
      edges: edge,
      submitToStoredProc: submitToStoredProc,
    };
    if (
      props.entity &&
      props.entity.parameters &&
      props.entity.parameters.hasContainer
    ) {
      if (
        props.entity &&
        props.entity.parameters &&
        props.entity.parameters.hasContainer &&
        props.entity.parameters.updatePayloadDocumentDb &&
        props.entity.parameters.hasContainer ===
          props.entity.parameters.updatePayloadDocumentDb
      ) {
        await manageEntityHelper.schedulerCustomPayload(
          convertedFormData,
          crmId,
          props.entity
        );
      }
      const response = await httpPost(
        `/GenericDocument/${props.entity.parameters.hasContainer}/insert`,
        convertedFormData.properties
      );
      if ("message" in response) {
        setIsError(response);
        setIsLoading(false);
      } else {
        location.reload();
      }
    } else {
      let res = await manageEntityHelper.CRMEdgeVertexEngine(
        convertedFormData,
        props
      );
      //This if condition is used for geeting image saved as crmId name (use for ImageCard component)
      if (
        props &&
        props.entity &&
        props.entity.parameters &&
        props.entity.parameters.label &&
        props.entity.parameters.label.toLowerCase() == "imagecard"
      ) {
        res.id = crmId;
      }
      fileName = res.id + "/";
      if (filelist && filelist.length > 0) {
        handleFileUpload(
          "",
          res.id,
          user,
          filelist,
          fileUploadConfig,
          fileName,
          "",
          "",
          false,
          !props.refresh,
          false
        );
      }

      if (
        props.isIRCSlot &&
        res.edges &&
        res.edges[0] &&
        res.edges[0].guid[0]
      ) {
        let payloadChecklist = {
          dealId:
            res && res.edges[0] && res.edges[0].guid[0]
              ? res.edges[0].guid[0]
              : "",
          dealWorkFlowState: "OnBoarding",
          dateActive: props.IRCSlotDate,
          ircPurpose: handleOptionSetValue(
            res.properties.ircPurpose
              ? res.properties.ircPurpose
              : res.properties["Purpose (Other)"]
              ? res.properties["Purpose (Other)"]
              : "Other"
          ),
          dealStatus: "priority",
          action: "insert",
          dealIrcId: res.id,
        };
        await httpPost(`/DealChecklist/Insert`, payloadChecklist);

        setSteps([]);
        setOpen(false);
      }

      if ("message" in res) {
        setIsError(res);
        setIsLoading(false);
      } else {
        handleCrmEngineSuccess(res);
      }
    }
    setIsLoading(false);
  };
  const handleCrmEngineSuccess = (obj) => {
    if ("id" in obj) {
      setShowFormWarning(false);
      props.fromAddEntity ? setFormSavedResponse(obj) : "";
      if (props.showPopup === false) {
        setConfirmationPopup(false);
      } else {
        setConfirmationPopup(true);
      }
    }
    setIsLoading(false);
    if (!props.refresh && filelist.length == 0) {
      if (props.IRCSlotCardType === "IRCSlotCardType") {
        props.IRCSlotPayloadCallback();
      } else {
        location.reload();
      }
    }
  };
  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  const handleFormEdit = () => {
    let newPageUrl =
      props &&
      props.chartConfigurations &&
      props.chartConfigurations.action &&
      props.chartConfigurations.action.pageUrl
        ? props.chartConfigurations.action.pageUrl
        : pageUrl;
    setIsLoading(true);
    setOpen(false);
    setSteps([]);
    let newFormData = { ...editFormData };
    for (const key in newFormData) {
      if (newFormData.hasOwnProperty(key)) {
        if (isJsonString(newFormData[key])) {
          if (
              JSON.stringify(newFormData[key]).includes('title')
          ) {
            delete newFormData[key];
          }
        }
      }
    }
    try {
      if (
        props &&
        props.currentPage &&
        props.currentPage.pageConfig &&
        props.currentPage.pageConfig.description == "Portfolio Investments"
      ) {
        newFormData.Date = manageEntityHelper.getEcpochDateOfOptionSetMonth(
          newFormData.Date
        );
      }
    } catch (e) {
      console.log(e);
    }
    const apiResponse = handleUpdate(
      newFormData,
      props,
      newPageUrl,
      crmId,
      fileUploadConfig,
      user,
      labelName,
      submitToStoredProc
    );
    apiResponse.then((res) => {
      if (res && res.status) {
        props.setToast({
          type: toastType.CRM_FORM_UPDATE,
          severity: toastSeverity.SUCCESS,
          message: message.CRM_FORM_UPDATE,
          status: res.status,
        });
        setShowFormWarning(false);
        setFormSavedResponse(apiResponse.result);
        location.reload();
      } else {
        setIsError(res.result);
        props.setToast({
          type: toastType.CRM_FORM_UPDATE,
          severity: toastSeverity.ERROR,
          message: message.CRM_FORM_UPDATE_ERROR,
          status: true,
        });
        location.reload();
      }
    });

    setIsLoading(false);
    props.resetManagePage();
  };
  const handleActiveStep = (actStep) => {
    setActiveStep(actStep);
  };
  const handleFormData = (formData, fieldId) => {
    let visibleSteps = handleShowHide(allSteps, fieldId);
    setSteps(visibleSteps);
    setFormData(formData);
  };
  const handelShowhide = (formData, fieldId) => {
    let visibleSteps = handleShowHide(allSteps, fieldId);
    setSteps(visibleSteps);
  };
  const handleEditFormData = (editFormData) => {
    setEditFormData(editFormData);
  };
  const handleShowHide = (allSteps, fieldId = undefined) => {
    let filterFormData = {};
    if (pageFilters && hasPageFilter(props.currentPage)) {
      // eslint-disable-next-line react/prop-types
      pageFilters.forEach((pageFilter) => {
        filterFormData[pageFilter.filterName] = pageFilter.value;
      });
    }
    let visibleSteps = allSteps.filter((step) => {
      if (
        !step.show ||
        (step.show &&
          showField(step.show, { ...formData, filter: { ...filterFormData } }))
      ) {
        return step;
      }
    });
    let visibleStepsNfields = visibleSteps.map((step) => {
      let visibleFields = step.content.filter((field) => {
        if (fieldId && field.id == fieldId) {
          field.focus = true;
        } else {
          field.focus = false;
        }
        if (
          !field.show ||
          (field.show &&
            showField(field.show, {
              ...formData,
              filter: { ...filterFormData },
            }))
        ) {
          return field;
        }
      });
      let newStep = {
        label: step.label,
        content: visibleFields,
      };

      return newStep;
    });
    return visibleStepsNfields;
  };

  return (
    <>
      <A_SimpleDialog
        style={{ margin: "auto", width: 300 }}
        open={open}
        IRCdate={props.IRCdate ? props.IRCdate : ""}
        title={
          props.managePage &&
          props.managePage.data &&
          props.managePage.data.template
            ? props.managePage.data.template.name
            : ""
        }
        maxWidth="sm"
        onClose={handleClose}
        disableEscapeKeyDown={true}
        dialogContent={
          <React.Fragment>
            {props.managePage && !props.managePage.openManagePageForm && (
              <div className={classes.loader}>
                <A_CircularIndeternment />
              </div>
            )}
            {steps.length > 0 && (
              <ErrorBoundary>
                <A_HorizontalStepper
                  fileUploadConfig={(data) => {
                    setFileUploadConfig(data);
                  }}
                  crmData={props.crmData}
                  isEditable={props.isEditable}
                  onloading={handelShowhide}
                  showPopup={props.showPopup}
                  steps={steps}
                  fields={fields}
                  optionSets={optionSets}
                  onFinish={props.isEditable ? handleFormEdit : handleFormSaved}
                  closeFormWarning={showFormWarning}
                  onHandleWarningOkButton={handleWarningOkButton}
                  onHandleWarningCloseButton={handleWarningCloseButton}
                  onSave={props.isEditable ? handleFormEdit : handleFormSaved}
                  isLoading={isLoading}
                  formSavedResponse={formSavedResponse}
                  confirmationPopup={confirmationPopup}
                  isError={isError}
                  activeStep={activeStep}
                  onActiveStepChanged={handleActiveStep}
                  onFormDataChange={handleFormData}
                  onFormEdit={handleEditFormData}
                  formData={formData}
                  entity={props.entity}
                  fromAddEntity={props.fromAddEntity}
                />
              </ErrorBoundary>
            )}
          </React.Fragment>
        }
      ></A_SimpleDialog>
    </>
  );
};

M_ManageEntityForm.defaultProps = {
  title: "Add",
  label: [{ textFieldLabel: " Date" }, { textFieldLabel: " time" }],
  color: "primary",
};

function mapStateToProps(state) {
  return {
    managePage: state.managePage,
    currentPage: state.currentPage,
    loggedIn: state.getLoggedInUserReducer,
    toolbarSelections: state.manageToolbarSelections,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign(
      {},
      managePageActions,
      currentPageActions,
      toastActions,
      loggedInUserIdActions
    ),
    dispatch
  );
}
M_ManageEntityForm.propTypes = {
  resetManagePage: PropTypes.func,
  open: PropTypes.bool,
  crmData: PropTypes.object,
  entity: PropTypes.object,
  editChart: PropTypes.bool,
  managePage: PropTypes.object,
  getPage: PropTypes.func,
  templateId: PropTypes.id,
  onClose: PropTypes.func,
  isIRCSlot: PropTypes.any,
  IRCSlotDate: PropTypes.any,
  IRCTime: PropTypes.any,
  showPopup: PropTypes.bool,
  IRCRecordId: PropTypes.any,
  addEdge: PropTypes.any,
  IRCdate: PropTypes.any,
  isEditable: PropTypes.bool,
  resetManage: PropTypes.bool,
  refresh: PropTypes.any,
  setToast: PropTypes.func,
  currentPage: PropTypes.any,
  edgeId: PropTypes.any,
  IRCSlotPayload: PropTypes.any,
  getIrcSlot: PropTypes.any,
  IRCSlotCardType: PropTypes.any,
  IRCSlotPayloadCallback: PropTypes.func,
  fromAddEntity: PropTypes.bool,
  loggedIn: PropTypes.any,
  chartConfigurations: PropTypes.any,
};
export default connect(mapStateToProps, mapDispatchToProps)(M_ManageEntityForm);
