import * as React from "react";
import * as httpCall from "../../../../../Utils/apis/apis";
import { Typography } from "@material-ui/core";
import axios from "axios";
import { Box } from "@material-ui/core";
import propTypes from "prop-types";
import * as commonFunctions from "../../../../../Utils/Helpers/common";
import "./M_Tabs.css";
export default function M_TabsRemark(props) {
  const [remarkData, setRemarkData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const getRemark = async () => {
    try {
      let payload = props.payload ? props.payload : "";
      if (props.id && payload) {
        setIsLoading(true);
        if (
          payload &&
          payload.sourceId &&
          typeof payload.sourceId === "string" &&
          payload.sourceId.toLowerCase() == "=ircid"
        ) {
          payload.sourceId = props.id ? props.id : "";
        }
        let response = await httpCall.httpPost(
          `/CRMChartData/getGenericCRMData`,
          payload
        );
        if (response) {
          setRemarkData(Array.isArray(response) ? response : []);
        } else {
          setRemarkData([]);
        }
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };
  React.useEffect(() => {
    getRemark();
  }, [props.id]);
  const renderDocument = (item) => {
    let files =
      item.attachments && typeof item.attachments === "string"
        ? item.attachments.split(",")
        : [];
    if (files.length == 0) {
      return <></>;
    }
    return (
      <div>
        <Typography className="document-head">Documents</Typography>
        {files.map((value, index) => {
          let path = value.split("/");
          let fileName = path[path.length - 1];
          return (
            <div key={index}>
              <Typography className="document-filename">
                <span
                  className="document"
                  onClick={() => {
                    fileDownload(fileName, value);
                  }}
                >
                  {fileName}
                </span>
              </Typography>
            </div>
          );
        })}
      </div>
    );
  };
  const fileDownload = (filename, filepath) => {
    const url = `${process.env.DOCUMENT_HELPER}`;
    const httpConfig = commonFunctions.getHttpConfig();
    //important
    // let subdomain = "dev";
    // new URL(window.location.href).hostname.split(".")[0]
    let subdomain = new URL(window.location.href).hostname.split(".")[0];
    let header = {
      filename: filepath,
      type: "email",
      "file-operation": "download",
      "process-file": "yes",
      "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
      subdomain: subdomain,
      Authorization: httpConfig.headers.Authorization,
    };
    const formData = {};
    axios
      .post(url, formData, { responseType: "blob", headers: header })
      .then((response) => {
        const url1 = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url1;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });
  };
  return (
    <Box style={{ paddingTop: "1.2rem" }}>
      {!isLoading && remarkData
        ? remarkData.map((item, index) => {
            return (
              <div key={index} className="">
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.remark ? item.remark : "",
                  }}
                ></div>
              </div>
            );
          })
        : ""}
    </Box>
  );
}

M_TabsRemark.propTypes = {
  content: propTypes.object,
  id: propTypes.string,
};
