/* eslint-disable */
import { createMuiTheme } from "@material-ui/core/styles";
import { lightBlue, blue } from "@material-ui/core/colors";
const defaultTheme = createMuiTheme({
  //   palette: {
  //     type: "dark",
  //   },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#1976D2',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#6E0027',
    },
    typography: {
      // In Chinese and Japanese the characters are usually larger,
      // so a smaller fontsize may be appropriate.
      fontSize: 1,
    },
  },
});

export default defaultTheme;
