/* eslint-disable */
import React from "react";
import { useState, useRef } from "react";
import { getHttpConfig } from "../../../../../Utils/Helpers/common";
import axios, { post } from "axios";
import "./dropzoneupload.scss";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import CircularDeterminate from "../../../Atoms/Inputs/CircularProgressBar/CircularProgressBar";
import { CloudUpload } from "@material-ui/icons";
import PreviousTemplate from "../Renderers/Views/PreviousTemplate/PreviousTemplate";
import { Divider } from "@material-ui/core";
import { Chip } from "@material-ui/core";
const M_FormDropableFileUpload = (props) => {
  const { content } = props;
  const inputFile = useRef(null);
  const [hover, setHover] = useState(false);
  const user = useSelector((state) => state.oidc.user);
  let fileName = "";
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState([]);
  const [changesSaved, setChangesSaved] = React.useState({
    status: false,
    type: "success",
    msg: "",
  });

  let { pageUrl, crmId } = useParams();

  var inputProps = {
    accept:
      content && content.allowedExtensions
        ? content.allowedExtensions
        : "pdf,pptx,docx,xlsx",
    multiple: content && content.multiple ? content.multiple : false,
    directory: content && content.directory ? content.directory : null,
  };

  const handleFileUpload = async (e, dropedFiles = null) => {
    let resultFiles = [];

    let files = dropedFiles ? dropedFiles : e.target.files;
    let directory =
      inputProps && inputProps.directory ? inputProps.directory + "/" : "";

    for (let file of files) {
      resultFiles.push(file);
    }

    props.setFiles(e, [...props.files, ...resultFiles]);
    e.target.value = "";
    e.preventDefault();
  };

  const downloadPreview = (file) => {
    const url1 = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement("a");
    link.href = url1;
    link.setAttribute("download", file.name);
    document.body.appendChild(link);
    link.click();
  };
  const removeFile = (event, fileIndex) => {
    let newFile = file.filter((item, index) => index !== fileIndex);
    props.setFiles(event, newFile);
  };
  const handleSnackbarClose = () => {
    setChangesSaved({
      status: false,
      type: "",
      msg: "",
    });
  };

  const handleClick = () => {
    inputFile.current.click();
  };

  const handleDropFiles = (event) => {
    stopEvent(event);
    setHover(false);
    const { files } = event.dataTransfer;
    handleFileUpload(event, files);
  };

  const stopEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDragOver = (event) => {
    stopEvent(event);
    setHover(true);
  };
  const onDragLeave = (event) => {
    stopEvent(event);
    setHover(false);
  };
  return (
    <div>
      <section style={{ minWidth: "20px" }}>
        <div
          className={
            hover ? "drop-zone-container hover" : "drop-zone-container"
          }
        >
          <CloudUpload color="primary" />
          {!uploading ? (
            <p style={{ color: "#054af7" }}>
              Drag or Drop Files here to upload{" "}
            </p>
          ) : (
            ""
          )}
          {uploading ? <CircularDeterminate /> : ""}
          {!uploading ? (
            <A_Button
              onClick={handleClick}
              onDrop={handleDropFiles}
              onDragLeave={onDragLeave}
              onDragOver={onDragOver}
              color="primary"
              label="Click to Upload"
            />
          ) : (
            ""
          )}
          <input
            {...inputProps}
            className={"fileinput"}
            type="file"
            ref={inputFile}
            onChange={handleFileUpload}
          />
        </div>

        <A_Snackbar
          open={changesSaved.status}
          message={changesSaved.msg}
          type={changesSaved.type}
          autoHideDuration={2000}
          horizontal="center"
          handleClose={handleSnackbarClose}
        />
        {/* <div>{file && file.length > 0 ? file : ""}</div> */}
      </section>
      <Divider />
      <br />
      {props.files.map((item, index) => {
        return (
          <div
            className=""
            style={{
              height: "2.6rem",
              margin: "0.2rem",
              display: "inline",
            }}
          >
            <Chip
              label={item.name}
              onClick={() => {
                downloadPreview(item);
              }}
              onDelete={(e) => {
                removeFile(e, index);
              }}
            />
          </div>
        );
      })}

      <section>{/* <PreviousTemplate /> */}</section>
    </div>
  );
};

export default M_FormDropableFileUpload;
