 /* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as managePageActions from "../../../../../../store/actions/managePageActions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import A_Divider from "../../../../atoms/dataDisplay/dividers/A_Divider";
import M_ManageEntityForm from "./M_ManageEntityForm";
import HomeIcon from "@material-ui/icons/Home";
import Assessment from "@material-ui/icons/Assessment";
import PieChart from "@material-ui/icons/PieChart";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import Payment from "@material-ui/icons/Payment";
import ListItemIcon from "@material-ui/core/ListItemIcon";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    marginTop: "-2px",
    marginRight: "-15px",
  },
  AddEntityIcon: {
    padding: "0px",
  },
  listspacing: {
    padding: "2px",
  },
}));

const M_AddEntityList = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [entity, setEntity] = React.useState({});

  
  const renderIcons = (icon) => {
    let val;
    switch (icon) {
      case "AccountCircle":
        val = <HomeIcon />;
        break;
      case "Assessment":
        val = <Assessment />;
        break;
      case "PieChart":
        val = <PieChart />;
        break;
      case "AccountBalanceicon":
        val = <AccountBalanceIcon />;
        break;
      case "Payment":
        val = <Payment />;
        break;
      default:
        val = "";
        break;
    }
    return val;
  };

  const handleClickOpen = (entity) => {
    //props.getPage(entity);
    setEntity(entity);
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    props.onFormClose();
    props.resetManagePage();
  };

  return (
    <>
      {props.entitiesList &&
        props.entitiesList.length > 0 &&
        props.entitiesList.map((item, index) =>
          item.name == null ? (
            ""
          ) : (
            <List
              key={index}
              component="nav"
              aria-label="user area"
              className={classes.listspacing}
            >
              <ListItem button onClick={() => handleClickOpen(item)}>
                <ListItemIcon>{renderIcons(item.icon)}</ListItemIcon>
                <ListItemText className="List-Font" primary={item.name} />
              </ListItem>

              <A_Divider />
            </List>
          )
        )}

      {open && (
        <M_ManageEntityForm fromAddEntity={true} entity={entity} open={open} onClose={handleClose} refresh={true} />
      )}
    </>
  );
};

M_AddEntityList.defaultProps = {
  title: "Add",
  label: [{ textFieldLabel: " Date" }, { textFieldLabel: " time" }],
  color: "primary",
};

function mapStateToProps(state) {
  return {
    managePage: state.managePage,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(managePageActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(M_AddEntityList);
