/* eslint-disable */
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as currentPageActions from "../../../../store/actions/currentPageActions";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import { filter } from "lodash";
import * as httpCall from "../../../../Utils/apis/apis";
import { useParams } from "react-router";
import * as commonFunctions from "../../../../Utils/Helpers/common";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Radio from "@material-ui/core/Radio";
import debounce from "lodash/debounce";
import Box from "@material-ui/core/Box";

const SearchinRecord = (props) => {
  let { pageUrl, crmId, templateId } = useParams();
  console.log("props", props);

  const [searchValues, setSearchValues] = React.useState([]);
  const [defaultValue, setDefaultValue] = React.useState(props.value);
  const [test, setTest] = React.useState(props.values);

  const handleValue = (e, value) => {
    props.handledata(value);
  };

  const debouncedSave = React.useCallback(
    debounce((nextValue) => handleSearch(nextValue), 2000),
    [] // will be created only once initially
  );
  let templateIds = [
    { label: "deal", value: "52309c38-c369-4ec0-81ed-bc81539fa84a" },
    { label: "company", value: "fe36c115-d7e9-478c-b9af-f2ca74bffac7" },
    { label: "contact", value: "068a3392-8d84-4d7f-b388-543502098d93" },
    { label: "meeting", value: "2754f93a-1e94-4105-8bc2-20a35257a2d4" },
  ];
  var SearchVariable =
    props.seachValue && props.seachValue.seachOn != undefined
      ? props.seachValue.seachOn
      : "";
  const handleSearch = async (e) => {
    let value = e;
    let NewPageUrl = pageUrl;
    var id;
    for (var i in templateIds) {
      if (templateIds[i].label == pageUrl) {
        id = templateIds[i].value;
      }
    }

    let requestParam = [
      {
        label: pageUrl,
        keyword: value,
        searchOn: [SearchVariable],
        templateId: id,
      },
    ];
    const httpConfig = commonFunctions.getHttpConfig();
    //let url ="https://mcportalcommon.azurewebsites.net/api/CRMData/searchCRMRecords";
    let url = "/CRMData/searchCRMRecords";
    let requestPayload = requestParam;
    const result = await httpCall.httpPost(url, requestPayload, {
      headers: { Authorization: httpConfig.headers.Authorization },
    });
    var removeCommon = result.filter((item) => item.id != crmId);
    setSearchValues(removeCommon);

    console.log("results", result);
  };

  const handleInputChange = async (e, newInputValue) => {
    if (newInputValue && e && e.type === "change") {
      debouncedSave(newInputValue);
    } else {
      setSearchValues([]);
    }
  };

  return (
    <Autocomplete
      id="combo-box-demo"
      options={searchValues}
      getOptionLabel={(options) => options.title || ""}
      defaultValue={defaultValue}
      style={{ width: 300 }}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {props && props.title ? props.title : ""}
          <br />
          {/*   <small>{props && props.title ? props.title: ""}</small> */}
        </Box>
      )}
      onChange={(event, newValue) => {
        handleValue(event, newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          value={defaultValue}
          defaultValue={defaultValue}
          label="Search Record"
          variant="outlined"
        />
      )}
      onInputChange={(event, newInputValue) => {
        //  setDefaultValue(event)
        handleInputChange(event, newInputValue);
      }}
      value={defaultValue}
    />
  );
};

export default SearchinRecord;
