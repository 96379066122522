/* eslint-disable no-console */

import * as commonFunctions from "../../../../../../Utils/Helpers/common";
import { httpPost } from "../../../../../../Utils/apis/apis";

export const onDownload = async (
  fileName,
  type,
  modify,
  displayName = null
) => {
  const url = `${process.env.DOCUMENT_HELPER}`;
  let subdomain = new URL(window.location.href).hostname.split(".")[0];
  let header = {
    filename: fileName,
    type: type,
    "file-operation": "download",
    "process-file": "yes",
    "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
    subdomain: subdomain,
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  let config = commonFunctions.getHttpConfig();

  config.headers = {
    ...config.headers,
    ...header,
  };
  httpPost(url, "", {
    ...config,
    responseType: "blob",
  })
    .then((response) => {
      if (response) {
        const url1 = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url1;
        let fileDisplayName = fileName
          .split(modify ? "_" : "/")
          .pop()
          .split("/")
          .pop();
        if (displayName) {
          fileDisplayName = displayName;
        }
        link.setAttribute("download", fileDisplayName);
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getFileName = async (crmId, type) => {
  const url = `${process.env.DOCUMENT_HELPER}`;
  let subdomain = new URL(window.location.href).hostname.split(".")[0];
  let header = {
    filename: crmId,
    type: type,
    "file-operation": "blob-items",
    "process-file": "no",
    "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
    subdomain: subdomain,
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  let config = commonFunctions.getHttpConfig();

  config.headers = {
    ...config.headers,
    ...header,
  };
  const response = await httpPost(url, "", config);
  return response;
};
