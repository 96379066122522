import React from "react";
import { useHistory } from "react-router";
import propTypes from "prop-types";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import calendarIcon from "../../../../../../src/Assets/Images/Defaults/meetingDef.jpg";
import Grid from "@material-ui/core/Grid";
import A_Divider from "../../../atoms/dataDisplay/dividers/A_Divider";
import {
  FromEpochFormat,
  getFormattedDate,
} from "../../../../../Utils/Helpers/dateUtils";
import {
  groupBy,
  diff_hours,
  sortByKey,
  dateCheck,
} from "./O_MeetingSlotCard.helper";
import { makeStyles } from "@material-ui/core";
import { O_MeetingSlotCardCss } from "./O_MeetingSlotCardCss";
import { Box, Typography } from "@material-ui/core";
const useStyles = makeStyles(O_MeetingSlotCardCss);
const IrcSlotComponent = (props) => {
  const history = useHistory();
  const classes = useStyles();
  // default props
  const {
    IRCSlotDefault,
    IRCSlotsValues,
    content,
    setRecordId,
    setCurrentIRCDate,
    setCurrentTime,
    setCurrentDate,
    setIsOpen,
  } = props;

  const IRCRenderData = () => {
    let finalDataListComponent = [];

    if (IRCSlotsValues && Array.isArray(IRCSlotsValues)) {
      for (let i in IRCSlotDefault) {
        IRCSlotsValues.map((record) => {
          if (IRCSlotDefault[i].id === record.id) {
            IRCSlotDefault[i] = { ...IRCSlotDefault[i], ...record };
          }
        });
      }
    }
    let outJSON = IRCSlotDefault;

    let formatedDataList = [];
    for (let i in outJSON) {
      let convertedDate = FromEpochFormat(
        outJSON[i].properties && outJSON[i].properties.ircDate
      );
      if (outJSON[i].properties && outJSON[i].properties.ircDate) {
        outJSON[i].ircDate = convertedDate;
        formatedDataList.push(outJSON[i]);
      }
    }

    const sortedData = sortByKey(formatedDataList, "ircDate");
    let groubedByDate = groupBy(sortedData, "ircDate");
    let closedAfter = content && content.closedAfter ? content.closedAfter : 0;
    let warningAfter =
      content && content.warningAfter ? content.warningAfter : 0;

    for (let i in groubedByDate) {
      let disableBook = diff_hours(i) <= closedAfter;
      let displayMessage =
        closedAfter <= warningAfter &&
        diff_hours(i) > closedAfter &&
        diff_hours(i) <= warningAfter;

      // TODO A_Button component
      let deals = [];
      for (let record in groubedByDate[i]) {
        let dealData = groubedByDate[i][record].deal && (
          <A_Button
            className={classes.margin}
            label={
              groubedByDate[i][record].deal ? groubedByDate[i][record].deal : ""
            }
            color="default"
            onClick={() => {
              let templateID =
                content && content.templateId ? content.templateId : "";
              let pageurl =
                content && content.redirectUrl ? content.redirectUrl : "";
              let dealId =
                content &&
                content.redirectionId &&
                groubedByDate[i][record] &&
                groubedByDate[i][record][content.redirectionId]
                  ? groubedByDate[i][record][content.redirectionId]
                  : "0";
              history.push(`/${pageurl}/${dealId}/${templateID}`);
              window.location.reload();
            }}
          />
        );
        deals.push(dealData);
      }

      let detail = (
        <Box className={classes.detailFirstBox}>
          <Grid container spacing={8} alignItems="start">
            <Grid item xs={2}>
              <img src={calendarIcon} alt="" />
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.detailSecondBox}>
                <Typography className={classes.typographyStyle}>
                  {getFormattedDate(i, "MMM DD yyyy HH:mm")}
                </Typography>
                <Box>{deals ? deals : ""}</Box>
              </Box>
            </Grid>

            <Grid item xs={4} justifyContent="end" className="text-center">
              <A_Button
                onClick={() => {
                  setRecordId(groubedByDate[i].id);
                  setCurrentIRCDate(getFormattedDate(i, "MMM DD yyyy HH:mm"));
                  setCurrentTime(getFormattedDate(i, "HH:mm"));
                  setCurrentDate(groubedByDate[i][0].properties.ircDate);
                  setIsOpen(true);
                }}
                color={displayMessage ? "secondary" : "primary"}
                label="Book"
                disabled={disableBook ? true : false}
              />{" "}
              {displayMessage ? (
                <span className={classes.timePeriod}>
                  {content &&
                  (content.closingText || content.closingText === "")
                    ? content.closingText
                    : "Closing Soon!"}
                </span>
              ) : null}
            </Grid>
          </Grid>
          <Box style={{ paddingTop: "0.8rem" }}>
            <A_Divider />
          </Box>
        </Box>
      );
      if (dateCheck(i, props.content.noOfDays)) {
        let componentJSON = {
          Date: i,
          Detail: detail,
        };

        finalDataListComponent.push(componentJSON);
      }
    }
    return finalDataListComponent.map((e) => {
      return e.Detail;
    });
  };
  return <>{IRCRenderData()}</>;
};

IrcSlotComponent.propTypes = {
  IRCSlotDefault: propTypes.any,
  IRCSlotsValues: propTypes.any,
  content: propTypes.any,
  setRecordId: propTypes.any,
  setCurrentIRCDate: propTypes.any,
  setCurrentTime: propTypes.any,
  setCurrentDate: propTypes.any,
  setIsOpen: propTypes.any,
};
export default IrcSlotComponent;