import React from "react";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { message } from "../../../../Utils/config/messages";
const useStyles = makeStyles({
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
  },
  Image:{
      height:"80%",
      width:"100%" 
      // please change the width only if it is necessary 
  }
});
const Image = (props) => {
  const classes = useStyles();
  let { crmId } = useParams();
  const [isValidImage, setIsValidImage] = React.useState(true); 
  var url =
    props && props.content && props.content.url ? props.content.url : "";
  var image =
    props &&
    props.currentPage &&
    props.currentPage.data &&
    props.currentPage.data.imageFile
      ? props.currentPage.data.imageFile
      : "";
  var newURL = url + "/" + crmId + "/" + image;

  return (
    <>
      {newURL && newURL != "" && isValidImage ? (
        <img
          className={classes.Image}
          src={newURL}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            setIsValidImage(false);
          }}
        />
      ) : (
        <div className={classes.noData}>
          <p>{message.NO_CONTENT}</p>
        </div>
      )}
    </>
  );
};
Image.propTypes = {
  content: propTypes.any,
  currentPage: propTypes.any,
};

function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
  };
}

export default connect(mapStateToProps, undefined)(Image);
