import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import M_NavBar from "../../../Molecules/Common/Navigations/M_NavBar";
import M_MainDrawer from "../../../Molecules/Common/Navigations/M_MainDrawer";
import M_SearchResult from "../../../Molecules/Common/Renderers/Views/GlobalSearchRenderers/M_SearchResult";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

const O_Navigation = (props) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openSearchResult, setOpenSearchResult] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  let history = useHistory();

  //handle main-Drawer open/close event.
  const handleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleSearchDebounce = React.useCallback(
    debounce((e) => {
      setSearchValue(e);
    }, 500),
    []
  );

  const handleSearchBox = (e) => {
    if (e.target.value && e.target.value.length > 0) {
      setOpenSearchResult(true);
      handleSearchDebounce(e.target.value.trim());
    } else if (openSearchResult) {
      setOpenSearchResult(false);
      setSearchValue(null);
    }
  };

  const handleClick = (e) => {
    const { id, pageId, pageUrl } = e;
    const templateString = localStorage.getItem("searchTemplateNewData") ? localStorage.getItem("searchTemplateNewData") : "[]"; 
    let templates = [];
    try{
      templates = JSON.parse(templateString);
    }
    catch(e){
      templates = [];
    }
    const requiredTemplate = Array.isArray(templates) ? templates.filter((template) => template.label === pageUrl) : [];
    const templateId = requiredTemplate.length > 0 && requiredTemplate[0].templateId ?  requiredTemplate[0].templateId : ""; 
    let crmIdValue = id;
    let pageIdValue = pageId;
    let pageUrlValue = pageUrl;

    if (!pageIdValue || !crmIdValue || !pageUrlValue) {
      return;
    }
    let url = "/" + pageUrlValue + "/" + crmIdValue + "/" + templateId;
    setOpenSearchResult(false);
    setSearchValue(null);
    history.push(url);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      let selectSearchElement = document.getElementById("globalsearch");
      let selectSearchScrollView = document.getElementById("SearchScrollView");
      let selectSearchFullView = document.getElementById("SearchFullView");
      if (selectSearchElement && !selectSearchElement.contains(event.target)) {
        if (
          selectSearchScrollView !== null &&
          selectSearchScrollView.contains(event.target)
        ) {
          return;
        } else if (
          selectSearchFullView !== null &&
          selectSearchFullView.contains(event.target)
        ) {
          return;
        } else {
          setOpenSearchResult(false);
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <React.Fragment>
      <M_NavBar
        defaultValue={searchValue}
        {...props}
        handleDrawer={(e) => handleDrawer(e)}
        onChange={handleSearchBox}
      />

      <M_MainDrawer
        openDrawer={openDrawer}
        user={props.user}
        handleDrawer={(e) => handleDrawer(e)}
      />
      {openSearchResult && searchValue && searchValue.length > 1 ? (
        <M_SearchResult
          user={props.user}
          searchKey={searchValue}
          handleClick={handleClick}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

O_Navigation.propTypes = {
  user: PropTypes.string,
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(O_Navigation);
