import React from "react";
import BusinessIcon from "@material-ui/icons/Business";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import PeopleIcon from "@material-ui/icons/People";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import EventNoteIcon from "@material-ui/icons/EventNote";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { httpGet } from "../../../../../../../Utils/apis/apis";

export const renderIcons = (image) => {
    let val;
    switch (image) {
        case "InsertChartIcon":
            val = <InsertChartIcon />;
            break;
        case "EventNoteIcon":
            val = <EventNoteIcon />;
            break;
        case "LocationOnIcon":
            val = <LocationOnIcon />;
            break;
        case "AccountBalanceIcon":
            val = <AccountBalanceIcon />;
            break;
        case "PersonPinIcon":
            val = <PersonPinIcon />;
            break;
        case "MonetizationOnIcon":
            val = <MonetizationOnIcon />;
            break;
        case "PeopleIcon":
            val = <PeopleIcon />;
            break;
        case "BusinessIcon":
            val = <BusinessIcon />;
            break;
        default:
            val = "";
            break;
    }
    return val;
};

export const getMeetings = async (crmId,isCompany) => {
  const result = await httpGet(
    `${process.env.MEETING_PREFERENCE}/Meeting/GetMeetings?userId=${crmId}&isComp=${isCompany}`
  );
  let data = result.map((item) => ({
    id: item.id ? item.id : "",
    label: item.subject ? item.subject : "",
    properties: item,
  }));
  return data;
};
