/* eslint-disable */
import {
  take,
  put,
  select
} from "redux-saga/effects";
import axios from "axios";
import {
  pptDownloadActionTypes
} from "../actions/actionTypes";
import * as pptActions from "../actions/pptActions";
import * as commonFunctions from "../../Utils/Helpers/common";
import * as httpCall from "../../Utils/apis/apis"
export function* getPPTSaga() {
  while (true) {
    const action = yield take(pptDownloadActionTypes.GET_PPT_DOWNLOAD);
    const {
      payload,
      pptId,
      pptName,
      id,
      dealid,
    } = action.payload;
    const state = yield select();
    let config = commonFunctions.getHttpConfig();
    if (
      !config.headers.Authorization ||
      config.headers.Authorization == "Bearer undefined"
    ) {
      const serializedState = sessionStorage.getItem(
        `oidc.user:${process.env.OIDC_AUTHORITY}:MinervaPortal`
      );
      if (serializedState === null) {
        return undefined;
      }
      const appState = JSON.parse(serializedState);
      if (appState && appState.access_token) {
        config.headers.Authorization = "Bearer " + appState.access_token;
      }
    }
    try {
      const res = yield axios.post(
        `${process.env.PPT_DOWNLOAD_URL}/PPT/download/${pptId}`,
        payload, {
          ...config,
          responseType: "blob",
        }
      );
      const url1 = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url1;
      link.setAttribute("download", pptName + ".pptx");
      link.click();
      if(id && dealid ){
        let checklistPayload = {
          checklistCompleted: true,
          action: "update",
          id: id,
          dealId: dealid,
        };
         httpCall.httpPut("/DealChecklist/Update", checklistPayload).then((response)=>{
          location.reload()
         });
      }
     
      yield put(
        pptActions.getPPTDownloadSucceeded({
          ...res.data,
          getPPTDownloadSucceeded: true,
          isLoading: false,
        })
      );
    } catch (e) {
      yield put(
        pptActions.getPPTDownloadFailed({
          msg: "Some error occurred",
          data: "PPT",
          getPPTDownloadSucceeded: false,
          isLoading: false,
        })
      );
    }
  }
}