import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { Popover } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import M_ManageEntityForm from "../Navigations/AddEntity/M_ManageEntityForm";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import { ListItemIcon, ListItemText } from "@material-ui/core";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { recordSoftDelete } from "../../../Organisms/Common/GenericList/Helper/GenericList.Helper";
import propTypes from "prop-types";
import "./M_ActionGroup.css";
import { Divider } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { httpDelete } from "../../../../../Utils/apis/apis";
import { message } from "../../../../../Utils/config/messages";
import { cardType } from "../../../../../Utils/config/cardType";
import * as managePageActions from "../../../../../store/actions/managePageActions";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import AccouncementDetail from "../../../Molecules/Announcment/AnnouncementDetails";
import * as httpCall from "../../../../../Utils/apis/apis";
import * as toastActions from "../../../../../store/actions/toastActions";
import {
  toastType,
  toastSeverity,
} from "../../../../../Utils/config/toastConfig";

function M_ActionGroup(props) {
  const { card, edgeId, onDelete } = props;
  const [paramEntity, setParamEntity] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openPopOver, setOpenPopOver] = useState(false);
  const [chartDetails, setChartDetails] = useState({});
  const [cardData, setCardData] = useState(
    props.data ? props.data : props.currentPage.data
  );
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const deleteCRMData = async(id) => {  
      let resultMessage = await recordSoftDelete(id, props.label, props.handleDataChange, props.modify);
      if(resultMessage === true){
      props.setToast({
        type: toastType.DELETE_RECORD,
        severity: toastSeverity.SUCCESS,
        message: message.RECORD_DELETE_SUCCESS,
        status: true,
      });
      handlePopup();
    }
    else {
      props.setToast({
        type: toastType.DELETE_RECORD,
        severity: toastSeverity.ERROR,
        message: message.RECORD_DELETE_ERROR,
        status: true,
      });
    }
  };
  //TODO - Analyse and remove setTimeout
  //TODO - page load should not be there instead make it async
  const deleteDocumentRecord = (id) => {
    httpDelete(`/GenericDocument/${props.hasContainer}/${id}/delete`);
    handlePopup();
    props && props.handleDataChange && props.handleDataChange();
  };

  useEffect(() => {
    if (props.currentPage.data && !props.data) {
      setCardData(props.currentPage.data);
    }
  }, [props.currentPage, props.currentPage.data]);

  const renderConfirmPopup = (id) => {
    return (
      <div>
        <div style={{ paddingTop: "20px" }}>
          <A_Button
            onClick={() => {
              onDelete ? onDelete(id) : "";
              props.hasContainer ? deleteDocumentRecord(id) : deleteCRMData(id);
            }}
            color="primary"
            label="Yes"
          />
          <A_Button onClick={handlePopup} color="default" label="Close" />
        </div>
      </div>
    );
  };
  let handlePopup = () => {
    let data = !isOpen;
    setIsOpen(data);
  };
  const handleEdit = () => {
    let templateID =
      props.content && props.content.editTemplateId
        ? props.content.editTemplateId
        : props.content.edit &&
          props.content.edit.parameters &&
          props.content.edit.parameters.templateId
        ? props.content.edit.parameters.templateId
        : "";
    let recordId = props.id;
    //Deal Team case - Id will be taken from data.id
    recordId = recordId
      ? recordId
      : props && props.data && props.data.id
      ? props.data.id
      : null;

    const NewData = {
      templateId: templateID,
      recordId: recordId,
      hasContainer: props.hasContainer,
      updatePayloadDocumentDb: props.updatePayloadDocumentDb,
      parameters : {"excelProcessDates" : props.excelProcessDates }
    };
    props.resetManagePage();
    setParamEntity(NewData);
    setOpenEditForm(!openEditForm);
  };
  const handleCloseForm = () => {
    setOpenEditForm(false);
    setParamEntity(null);
  };

  const handlePreview = () => {
    setLoading(true);
    if (props.row.id && props.row && props.row.id) {
      getChartDetails(props.row.id);
    }
    setOpenPopOver(true);
  };
  const handlePopClose = () => {
    setOpenPopOver(false);
  };

  const getChartDetails = async (id) => {
    // setIsLoading(true)
    const payload = {
      label: "announcements",
      properties: {
        id: id,
      },
    };
    const response = await httpCall.httpPost(
      `/CRMData/getRecordByProperty`,
      payload
    );
    let apiRes = await response;
    if (apiRes && apiRes.length > 0 && apiRes[0] && apiRes[0].properties) {
      let chartDetail = apiRes[0].properties;
      let fin = { ...chartDetail, author: props.row.author };
      setChartDetails(fin);
    }
    setLoading(false);
  };
  return (
    <>
      <Box>
        {props.currentPage &&
        props.currentPage.pageConfig &&
        props.currentPage.pageConfig.pageSecurity &&
        props.currentPage.pageConfig.pageSecurity.Full === true ? (
          props.edit && props.delete ? (
            <span>
              <IconButton
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
              >
                <MoreVert></MoreVert>
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <List>
                  {props.edit && (
                    <>
                      <ListItem
                        className="list-item"
                        onClick={() => {
                          handleClose();
                          handleEdit();
                        }}
                      >
                        <ListItemIcon>
                          <Edit />
                        </ListItemIcon>
                        <ListItemText className="List-Font" primary="Edit" />
                      </ListItem>
                      {props.currentPage.pageConfig.pageSecurity.Full ? (
                        <Divider />
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  {props.delete && (
                    <>
                      <ListItem
                        className="list-item"
                        onClick={() => {
                          handlePopup();
                          handleClose();
                        }}
                      >
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText className="List-Font" primary="Delete" />
                      </ListItem>
                    </>
                  )}
                  {props.preview && (
                    <>
                      <ListItem
                        className="list-item"
                        onClick={() => {
                          handlePreview();
                          handleClose();
                        }}
                      >
                        <ListItemIcon>
                          <VisibilityOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText className="List-Font" primary="Preview" />
                      </ListItem>
                    </>
                  )}
                </List>
              </Popover>
            </span>
          ) : props.edit ? (
            <span>
              <IconButton
                onClick={() => {
                  handleClose();
                  handleEdit();
                }}
              >
                {" "}
                <Edit />
              </IconButton>
            </span>
          ) : props.delete ? (
            <span>
              <IconButton
                onClick={() => {
                  handlePopup();
                  handleClose();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          ) : (
            ""
          )
        ) : props.currentPage &&
          props.currentPage.pageConfig &&
          props.currentPage.pageConfig.pageSecurity &&
          props.currentPage.pageConfig.pageSecurity.Update &&
          props.edit === true ? (
          <span>
            {props.edit && (
              <IconButton
                onClick={() => {
                  handleClose();
                  handleEdit();
                }}
              >
                <Edit />
              </IconButton>
            )}
          </span>
        ) : (
          ""
        )}

        {openEditForm && (
          <M_ManageEntityForm
            crmData={cardData}
            isEditable={true}
            entity={paramEntity}
            open={openEditForm}
            editChart={true}
            card={card}
            edgeId={edgeId}
            onClose={() => handleCloseForm()}
            chartConfigurations={props.chartConfigurations}
          />
        )}
        <A_SimpleDialog
          open={isOpen}
          title={message.DELETE_WARNING}
          fullScreen={false}
          height="400px"
          fullwidth={true}
          maxWidth={"xs"}
          onClose={handlePopup}
          closePopUp={handlePopup}
          dialogContent={renderConfirmPopup(
            card && card === cardType.DEAL_TEAM ? edgeId : props.id
          )}
        />
        {openPopOver && props.row && (
          <AccouncementDetail
            openPopOver={openPopOver}
            handlePopClose={handlePopClose}
            detailsData={chartDetails}
            loading={loading}
          />
        )}
      </Box>
    </>
  );
}

function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, managePageActions, toastActions),
    dispatch
  );
}

M_ActionGroup.propTypes = {
  onDelete: propTypes.func,
  label: propTypes.string,
  handleDataChange: propTypes.any,
  content: propTypes.object,
  id: propTypes.string,
  edit: propTypes.any,
  delete: propTypes.any,
  currentPage: propTypes.any,
  modify: propTypes.bool,
  hasContainer: propTypes.any,
  updatePayloadDocumentDb: propTypes.any,
  loggedIn: propTypes.object,
  setToast: propTypes.func,
  data: propTypes.any,
  row: propTypes.any,
  preview: propTypes.any,
  card: propTypes.any,
  edgeId: propTypes.any,
  excelProcessDates: propTypes.any,
  chartConfigurations: propTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(M_ActionGroup);
