import React from "react";
import {
  epochToDateFormatHelper,
  toEpochFormat,
} from "../../../../../Utils/Helpers/dateUtils";
import M_NewsPortal from "../../../Molecules/Common/NewsPortal/M_NewsPortal";
import { newsFields } from "./newsportal.constant";
import { newsContentBasedOnType } from "./newPortal.helper";
import { httpGet } from "../../../../../Utils/apis/apis";
import { TablePagination } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
import propTypes from "prop-types";
const handleDate = (date) => {
  return epochToDateFormatHelper(
    Number(toEpochFormat(new Date(date))),
    "DD/MM/YYYY"
  );
};
const convertResponseToTemplateStructure = (responseJSON) => {
  try {
    let renderingResponse = [];
    let headlines = responseJSON.Headlines ? responseJSON.Headlines : [];
    for (let responseHeadlineIndex in headlines) {
      let renderingObject = {};
      renderingObject[newsFields.LINK] = headlines[responseHeadlineIndex][
        newsFields.LINK
      ]
        ? headlines[responseHeadlineIndex][newsFields.LINK]
        : "";
      renderingObject[newsFields.TITLE] = headlines[responseHeadlineIndex][
        newsFields.TITLE
      ]
        ? headlines[responseHeadlineIndex][newsFields.TITLE].map((title) =>
            title[newsFields.ITEMS].map((item) => {
              return newsContentBasedOnType(item);
            })
          )
        : [];
      renderingObject[newsFields.SNIPPET] =
        headlines[responseHeadlineIndex][newsFields.SNIPPET] &&
        headlines[responseHeadlineIndex][newsFields.SNIPPET][newsFields.ITEMS]
          ? headlines[responseHeadlineIndex][newsFields.SNIPPET][
              newsFields.ITEMS
            ].map((item) => newsContentBasedOnType(item))
          : [];
      renderingObject[newsFields.CATEGORY] = [
        headlines[responseHeadlineIndex][newsFields.SOURCENAME]
          ? headlines[responseHeadlineIndex][newsFields.SOURCENAME]
          : "",
        handleDate(
          headlines[responseHeadlineIndex][newsFields.MODIFICATIONDATETIME]
        ),
      ];
      renderingObject[newsFields.ARTICLEREF] = headlines[responseHeadlineIndex][
        newsFields.ARTICLEREF
      ]
        ? headlines[responseHeadlineIndex][newsFields.ARTICLEREF]
        : "";

      renderingResponse.push(renderingObject);
    }
    return renderingResponse;
  } catch (error) {
    return [];
  }
};
export default function O_NewsPortal({ content }) {
  const [renderingData, setRenderingData] = React.useState([]);
  const [finalData, setFinalData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [keyword, setKeyword] = React.useState("");
  const getNewsData = async (keyword = null) => {
    setIsLoading(true);
    try {
      const response = await httpGet(
        `/NewsPortal/searchContentByQueryString?queryString=${
          content && content.type
            ? keyword && keyword.trim() !== ""
              ? keyword
              : content.type
            : ""
        }`
      );
      let finalResponse = convertResponseToTemplateStructure(response);
      setRenderingData(finalResponse);
      setFinalData(finalResponse);
      setIsLoading(false);
      return finalResponse;
    } catch (error) {
      setIsLoading(false);
      return [];
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const filteredData = async () => {
    let renderingTempData = [...finalData];
    let newsData = renderingTempData.filter((news) => {
      return (
        news[newsFields.TITLE]
          .join()
          .toLowerCase()
          .includes(keyword.toLowerCase()) ||
        news[newsFields.SNIPPET]
          .join()
          .toLowerCase()
          .includes(keyword.toLowerCase())
      );
    });
    if (newsData.length === 0 || keyword === "") {
      newsData = await getNewsData(keyword);
    }
    setRenderingData(newsData);
    return newsData;
  };
  const handleInputChange = (event) => {
    setKeyword(event.target.value);
  };
  React.useEffect(() => {
    filteredData(keyword);
  }, [keyword]);
  return (
    <div>
      <A_TextField label={"Search"} onChange={handleInputChange} />
      {isLoading ? (
        <>
          <Skeleton variant="text" width={80} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="rect" width={"100%"} height={118} />
        </>
      ) : (
        ""
      )}
      {!isLoading ? (
        <M_NewsPortal
          newsData={renderingData.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )}
        ></M_NewsPortal>
      ) : (
        ""
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={renderingData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

O_NewsPortal.propTypes = {
  content: propTypes.object,
};