import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SearchIcon from "@material-ui/icons/Search";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    background: "#f1f1f1",
    position: "fixed",
    overflow: "auto",
    maxHeight: "84vh",
    borderRadius:"10px",
    top: "65px",
    right: "10%",
    zIndex: "99999",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100vw",
      width: "100%",
      maxWidth: 360,
      background: "#f1f1f1",
      overflow: "auto",
      maxHeight: "93vh",
      top: "65px",
      right: "0%",
      position: "fixed",
    },
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  crmSubheader: {
    background: "rgb(34, 34, 34, 14%)",
    color: "#212529",
    fontWeight: "bold",
    textTransform: "capitalize",
  },

  crmHeader: {
    "& > div": {
      minWidth: "30px",
    },
    "& > span": {
      fontSize: "12px",
      "& > b": {
        fontSize: "14px",
        marginRight: "8px",
      },
    },
  },
  crmListItem: {
    background: "#f1f1f1",
    cursor: "pointer",
    "&:hover": {
      background: "rgb(34, 34, 34, 10%)",
    },
  },
  showMoreBtn: {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "13px",
    marginRight: "10px",
    textDecoration: "underline",
    color: "inherit",
  },
}));

const M_ScrollView = (props) => {
  const classes = useStyles();
  const {
    allCrmResult,
    setIsFullPage,
    searchKey,
    handleClick,
    isLoading,
    setSearchLimit,
  } = props;
  return (
    <Box boxShadow={1} className={classes.root} id="SearchScrollView">
      {allCrmResult.length > 0 ? (
        <List>
          <ListItem
            button
            className={classes.crmHeader}
            onClick={() => setIsFullPage(true)}
          >
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <span>
              <b>{searchKey}</b>&nbsp;Click here to view all result
            </span>
          </ListItem>
          {allCrmResult.map((item) => (
            <li key={item.crmRecordType}>
              <ul className={classes.ul}>
                <ListSubheader disableSticky className={classes.crmSubheader}>
                  {item.crmRecordType}
                </ListSubheader>
                {item.crmRecordDetails.slice(0, setSearchLimit).map((list) => (
                  <ListItem
                    key={list.id}
                    onClick={() => handleClick(list)}
                    className={classes.crmListItem}
                  >
                    <ListItemAvatar>
                      <Avatar alt={list.title} src={list.link} />
                    </ListItemAvatar>
                    <ListItemText primary={list.title} />
                  </ListItem>
                ))}
              </ul>
            </li>
          ))}
        </List>
      ) : (
        <List subheader={<li />}>
          <ListItem className={classes.crmHeader}>
            {isLoading ? (
              <span>Loading...</span>
            ) : (
              <span>
                <b>{searchKey}</b>&nbsp;No results found
              </span>
            )}
          </ListItem>
        </List>
      )}
    </Box>
  );
};

M_ScrollView.propTypes = {
  allCrmResult: PropTypes.array,
  setIsFullPage: PropTypes.bool,
  searchKey: PropTypes.string,
  handleClick: PropTypes.func,
  isLoading: PropTypes.bool,
  setSearchLimit: PropTypes.string,
};

export default M_ScrollView;
