import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import A_SimpleDialog from "../../../../../Atoms/feedback/dialogs/A_SimpleDialog";
import A_Button from "../../../../../Atoms/Inputs/Buttons/A_Button";
import { httpDelete } from "../../../../../../../Utils/apis/apis";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as toastActions from "../../../../../../../store/actions/toastActions";
import {
  toastSeverity,
  toastType,
} from "../../../../../../../Utils/config/toastConfig";
import { message } from "../../../../../../../Utils/config/messages";

import { useParams } from "react-router";
import M_RenderSkeletion from "../Contents/M_RenderSkeletion";
import PropTypes from "prop-types";
import { useHistory } from "react-router";

const useStyles = makeStyles(() => ({}));

const DeleteCRMRecord = (props) => {
  const history = useHistory();
  let { pageUrl, crmId } = useParams();
  const [loading, setLoading] = React.useState(false);

  const classes = useStyles();

  const closePopup = () => {
    props.handleDeleteEvent(false);
  };
  const handleDeleteRecord = async () => {
    setLoading(true);
    const res = await httpDelete(
      `/crmdata/delete?pageUrl=${pageUrl}&&crmId=${crmId}&&softDelete=true`
    );

    if (res) {
      setLoading(false);
      setTimeout(
        props.setToast({
          type: toastType.DELETE_CRM,
          severity: toastSeverity.SUCCESS,
          message: message.DELETE_CRM_RECORD,
          status: true,
        }),
        closePopup(),
        history.push("/dashboard"),
        window.location.reload(),
        3000
      );
    }
  };

  return (
    <>
      <A_SimpleDialog
        open={true}
        title="Are you sure you want to delete ?"
        fullScreen={false}
        height="200px"
        fullwidth={false}
        maxWidth={"xs"}
        onClose={closePopup}
        closePopUp={closePopup}
        dialogContent={
          <div>
            {loading ? <M_RenderSkeletion classes={classes} /> : ""}

            <div style={{ float: "right" }}>
              <A_Button
                onClick={() => {
                  handleDeleteRecord();
                }}
                color="primary"
                label="Ok"
              />
              <A_Button onClick={closePopup} color="default" label="Cancel" />
            </div>
          </div>
        }
      />
    </>
  );
};

DeleteCRMRecord.propTypes = {
  setAlertStatus: PropTypes,
  handleDeleteEvent: PropTypes,
  filterName: PropTypes,
  setToast: PropTypes,
};

function mapStateToProps() {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, toastActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(DeleteCRMRecord);
