import PropTypes from "prop-types";
import React from "react";
import M_ChartCard from "../Renderers/Views/Charts/M_ChartCard";
import ErrorBoundary from "../error/ErrorBoundary";
import { useParams } from "react-router";
import { databaseType } from "../../../../../Utils/config/config";

function PopDealDetails(props) {
  let { crmId, templateId } = useParams();
  let content = {};
  const [templateTitle, setTemplateTitle] = React.useState("");
  localStorage.removeItem("chartStorageKeys");
  let popChartData = props.popChartData;

  let configuration = props && popChartData && popChartData.configurations;

  if (configuration && configuration.popUpChart) {
    content = JSON.parse(JSON.stringify(configuration.popUpChart));
  }
  if (content.header) {
    while (content.header.match(/<<(.+?)>>/) !== null) {
      let placeholder = content.header.match(/<<(.+?)>>/);
      if (placeholder[1]) {
        if (placeholder[1].toLowerCase() === "column") {
          var value = popChartData.key;
          content.header = content.header.replace(placeholder[0], value);
        } else {
          var value = popChartData.row[placeholder[1]];
          content.header = content.header.replace(placeholder[0], value);
        }
      }
    }
  }
  if (
    content &&
    content.chart &&
    content.chart.parameters &&
    content.chart.type &&
    content.chart.type.toLowerCase() === databaseType.SQL.toLowerCase()
  ) {
    let objectParameter = {};
    Object.keys(content.chart.parameters).map((item) => {
      objectParameter[item] = popChartData.row[content.chart.parameters[item]]
        ? popChartData.row[content.chart.parameters[item]]
        : objectParameter[content.chart.parameters[item]];
    });
    if (content.chart.column) {
      objectParameter[content.chart.column] = popChartData.key
        ? popChartData.key
        : "";
      delete content.chart.column;
    }
    content.chart.parameters = { ...objectParameter };
    delete content.chart.type;
  } else {
    if (content && content.chart && content.chart.parameters) {
      let objectParameter = {};
      Object.keys(content.chart.parameters).map((item) => {
        if (item.toLowerCase() === "column") {
          if (
            popChartData &&
            popChartData.commonKey &&
            popChartData.commonKey.column
          ) {
            objectParameter[content.chart.parameters[item]] = popChartData
              .commonKey.column
              ? popChartData.commonKey.column
              : "";
          } else {
            objectParameter[content.chart.parameters[item]] = popChartData.key
              ? popChartData.key
              : "";
          }
        } else {
          objectParameter[item] = popChartData.row[
            content.chart.parameters[item]
          ]
            ? popChartData.row[content.chart.parameters[item]]
            : content.chart.parameters[item];
        }
      });

      content.chart.parameters = { ...objectParameter };
    }
    if (content && content.chart && content.chart.content) {
      if (content.chart.content.sourceId.toLowerCase() === "=crmid") {
        content.chart.content.sourceId = crmId;
      } else {
        content.chart.content.sourceId =
          popChartData.row[content.chart.content.sourceId];
      }
      if (content.chart.content.filters) {
        let filterParameterValue = {};
        Object.keys(content.chart.content.filters).map((item) => {
          if (item.toLowerCase() === "column") {
            filterParameterValue[content.chart.content.filters[item]] = props
              .popChartData.key
              ? popChartData.key
              : "";
          } else {
            if (
              configuration.rowFormats &&
              configuration.rowFormats.hasOwnProperty(
                popChartData.row[content.chart.content.filters[item]]
              ) &&
              configuration.rowFormats[
                popChartData.row[content.chart.content.filters[item]]
              ].hasOwnProperty("filters")
            ) {
              content.chart.content.filters =
                configuration.rowFormats[
                  popChartData.row[content.chart.content.filters[item]]
                ].filters;
            } else {
              filterParameterValue[item] = popChartData.row[
                content.chart.content.filters[item]
              ]
                ? popChartData.row[content.chart.content.filters[item]]
                : filterParameterValue[content.chart.content.filters[item]];
            }
          }
        });
        content.chart.content.filters = { ...filterParameterValue };
      }
    }
  }

  React.useEffect(() => {
    if (props.templates) {
      const currentTemplate = props.templates.find(
        (item) => item.id === templateId
      );
      setTemplateTitle(currentTemplate && currentTemplate.name);
    }
  }, []);
  return (
    <ErrorBoundary>
      {content.header != "" && content.header ? (
        <div
          style={{
            position: "fixed",
            marginTop: 0,
            color: "#c8902a",
            width: "806px",
            fontWeight: "600",
            height: "28px",
            zIndex: 2,
            backgroundColor: "white",
          }}
        >
          <p style={{ marginTop: "2.4px" }}>{content.header}</p>
        </div>
      ) : (
        <div style={{ height: "28px", zIndex: 2 }}></div>
      )}
      <M_ChartCard
        header={content.header}
        content={
          content && content.chart
            ? {
                ...content.chart,
                chartId: content.chart.id,
                id: undefined,
              }
            : {}
        }
        excelDownload
        templateTitle={
          content && content.header ? content.header : templateTitle
        }
      />
    </ErrorBoundary>
  );
}
PopDealDetails.propTypes = {
  popChartData: PropTypes.object,
};
export default React.memo(PopDealDetails);
