/* eslint-disable */
import React, { useEffect, useState } from "react";
import { httpGet } from "../../../../../Utils/apis/apis";
import { Box } from "@material-ui/core";
import * as commonFunctions from "../../../../../Utils/Helpers/common";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  maindiv: {
    display: "grid",
    rowGap: "10px",
  },
  paragraph: {
    color: "#4d4d4d",
  },
}));
function GuideLines(props) {
  const [guideLineData, setGuideLineData] = useState();
  const [enabled, setEnabled] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    getGuideLines();
  }, []);

  let response;
  const getGuideLines = async () => {
    let config = commonFunctions.getHttpConfig();
    let WorkFlowID =
      props.content && props.content.workFlowID ? props.content.workFlowID : "";
    response = await httpGet(
      `${process.env.API_URL}/Common/GetDealWorkFlow?WorkFlowID=${WorkFlowID}`,
      config
    );
    
  
    setGuideLineData(response);
    setEnabled(true);
  };
  return (
    <Box maxHeight={"60vh"} overflow={"auto"}>
      {enabled && (
        <>
          {guideLineData && guideLineData.items
            ? guideLineData.items.map((i) => {
              {
                return (
                  <div className={classes.maindiv}>
                    <div>
                     
                      {!i.title ? "" : <div dangerouslySetInnerHTML={{ __html: i.title }}></div>}
                      {!i.item ? "" : <div dangerouslySetInnerHTML={{ __html: i.item }}></div>}
                      {!i.image ? "":<div dangerouslySetInnerHTML={{ __html: i.image }}></div>}
                    </div>
                  </div>
                );
              }
            })
            : "No Data Found."}
        </>
      )}
    </Box>
  );
}
export default GuideLines;
