/* eslint-disable */
import { esgRecordActionType } from "./actionTypes";

export const getEsgRecord = (payload) => {
  return {
    type: esgRecordActionType.GET_ESG_RECORD,
    payload,
  };
};
export const getEsgRecordById = (payload) => {
  return {
    type: esgRecordActionType.GET_ESG_RECORD_BY_ID,
    payload,
  };
};
export const getEsgRecordSucceeded = (payload) => {
  return {
    type: esgRecordActionType.GET_ESG_RECORD_SUCCEEDED,
    payload,
  };
};

export const getEsgRecordFailed = (payload) => {
  return {
    type: esgRecordActionType.GET_ESG_RECORD_FAILED,
    payload,
  };
};

export const insertEsgRecord = (payload) => {
  return {
    type: esgRecordActionType.INSERT_ESG_RECORD,
    payload,
  };
};
export const insertEsgRecordSucceeded = (payload) => {
  return {
    type: esgRecordActionType.INSERT_ESG_RECORD_SUCCEEDED,
    payload,
  };
};

export const insertEsgRecordFailed = (payload) => {
  return {
    type: esgRecordActionType.INSERT_ESG_RECORD_FAILED,
    payload,
  };
};
export const updateEsgRecord = (payload) => {
  return {
    type: esgRecordActionType.UPDATE_ESG_RECORD,
    payload,
  };
};
export const updateEsgRecordSucceeded = (payload) => {
  return {
    type: esgRecordActionType.UPDATE_ESG_RECORD_SUCCEEDED,
    payload,
  };
};

export const updateEsgRecordFailed = (payload) => {
  return {
    type: esgRecordActionType.UPDATE_ESG_RECORD_FAILED,
    payload,
  };
};

