/* eslint-disable */
const TableFunction = (num) => {
  if (num == 0 || num === "" || num === null || num=="NaN") {
    return (num = "-");
  } else {
    return num;
  }
};

export default TableFunction;
