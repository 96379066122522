/* eslint-disable */
import React from "react";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import { connect } from "react-redux";
import { coords } from "../../../../Utils/config/config";
const containerStyle = {
  width: "100%",
  height: "70%",
};
function MapGoogle(props) {
  
  const [position, setPosition] = React.useState({});
  const [zoom, setZoom] = React.useState(16);
  const [marker, setMarker] = React.useState(true);
  const findFromFilter = () => {
    let filteredElement = props.toolbarSelections.filters.filter(
      (element) =>
        element.data &&
        element.data.series &&
        element.data.series.lat &&
        element.data.series.lat
    );
    if (filteredElement.length > 0) {
      let { lat, lng } = filteredElement[0].data.series;
      setPosition({ lat, lng });
      setZoom(3);
      setMarker(false);
    } else {
      setPosition(coords);
      setZoom(3);
      setMarker(false);
    }
  };
  React.useEffect(() => {
    let geoLocation;
    try {
      geoLocation =
        props.data && props.data.geolocation
          ? JSON.parse(props.data.geolocation)
          : null;
    } catch (e) {}
    if (!geoLocation) {
      findFromFilter();
    } else {
      setPosition(geoLocation);
    }
  }, [props.toolbarSelections]);

  return (
    <LoadScript googleMapsApiKey="AIzaSyD2u77z6reMmpdZxeL1cDkrv58d7SOpE0U">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={position}
        zoom={zoom}
      >
        {marker ? <Marker position={position} /> : ""}
      </GoogleMap>
    </LoadScript>
  );
}
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    toolbarSelections: state.manageToolbarSelections,
  };
}

export default connect(mapStateToProps, undefined)(MapGoogle);
