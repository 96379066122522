/* eslint-disable */
import { take, put } from "redux-saga/effects";
import axios from "axios";
import * as commonFunctions from "../../Utils/Helpers/common";
import { loggedInUserIdActionTypes } from "../actions/actionTypes";
import { loadState } from "../../Utils/LocalState/localState";
import * as loggedInUserIdActions from "../actions/loggedInUserIdActions";

export function* getLoggedInUserSaga() {
  while (true) {

    const action = yield take(loggedInUserIdActionTypes.GET_LOGGED_IN_USER_TYPE);
   
    let config = commonFunctions.getHttpConfig();
    
    try {
        let user = null;
        
        const appState = loadState();
        if (appState) {
          user = appState.oidc.user;
        }
        if (user === null) {
            return {};
          }
          let email = (user.profile.email.toLowerCase())
        let convertedFormData = {
            label: "contact",
            properties: {
            email: email
            }
          };
      const res = yield axios.post(
        `${process.env.API_URL}/CRMData/getRecordByProperty`,convertedFormData,
        config
      );
     
      
       let data = res.data === "Empty" ? [] : res.data;
      yield put(
        loggedInUserIdActions.getLoggedInUserSucceeded({
        
          data: data,
          getLoggedInUserStarted: false,
        })
      );
    } catch (e) {
        loggedInUserIdActions.getLoggedInUserFailed({
        msg: "Some error occurred",
        getLoggedInUserStarted: false,
      });
    }
  }
}
