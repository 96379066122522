
import "date-fns";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Moment from "moment";
import { toEpochFormat, FromEpochFormat } from "../../../../../Utils/Helpers/dateUtils";
import theme from "../../../../Themes/defaultTheme";
import { dateConfig } from "../../../../../Utils/config/config";

const A_DatePicker = (props) => {
  const [selectedDate, setSelectedDate] = useState(props.defaultValue ? FromEpochFormat(props.defaultValue) : "");
  const [error, setError] = useState(false);
  const [helpertext, setHelpertext] = useState(props.helperText ? props.helperText : "");

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date && date.toString() === "Invalid Date") {
      setError(true);
      setHelpertext("Invalid date format");
      props.isValidDate ? props.isValidDate(true) : "";
    }
    else {
      let currDate = new Date();
      setError(false);
      setHelpertext("");
      if((props.disableFuture && toEpochFormat(date) > toEpochFormat(currDate)) || (props.disablePast && toEpochFormat(date) < toEpochFormat(currDate))){
        date = currDate;
        setSelectedDate(currDate);
      }
      date === null ? props.onChange(0, props.id) : props.onChange(toEpochFormat(date), props.id);
      props.isValidDate ? props.isValidDate(false) : "";
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          clearable={props.clearable}
          id={props.id}
          autoOk={props.autoOk}
          disableToolbar={props.disableToolbar}
          views={props.views}
          variant={props.variant}
          format={
            props.format && props.format == dateConfig.format
              ? undefined
              : props.format
          }
          margin={props.margin}
          label={props.label}
          value={
            props.lettersAllowed
              ? selectedDate
              : selectedDate
              ? Moment(selectedDate).toDate()
              : null
          }
          defaultValue={props.defaultValue}
          disableFuture={props.disableFuture}
          disablePast={props.disablePast}
          placeholder={props.placeholder}
          helperText={helpertext ? helpertext : props.helperText}
          error={error ? error : props.error}
          KeyboardButtonProps={{
            "aria-label": props.ariaLabel,
          }}
          onChange={(date) => handleDateChange(date)}
          className={props.className}
          required={props.required}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};
A_DatePicker.defaultProps = {
  id: "date-picker",
  disableToolbar: false,
  variant: "inline",
  format: "dd/MM/yyyy",
  margin: "normal",
  label: "Select Date",
  value: new Date(),
  ariaLabel: "change date",
  views: ["date"],
  defaultValue: new Date(),
  helperText: "",
  clearable: true,
  className: {},
  minDate: new Date(0),
  lettersAllowed: false,
  placeholder: "Select Date",
  autoOk: true,
  disableFutureDate: false,
  disableFuture : false,
  disablePast : false
};
A_DatePicker.propTypes = {
  variant: PropTypes.oneOf(["inline"]),
  //color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  //size: PropTypes.oneOf(["small", "medium"]),
  margin: PropTypes.oneOf(["normal", "medium"]),
  onChange: PropTypes.func,
  ariaLabel: PropTypes.string,
  defaultValue: PropTypes.date,
  helperText: PropTypes.string,
  isValidDate: PropTypes.func,
  id: PropTypes.id,
  clearable: PropTypes.bool,
  views: PropTypes.array,
  disableToolbar: PropTypes.bool,
  format: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.object,
  minDate: PropTypes.date,
  lettersAllowed: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.any,
  error: PropTypes.any,
  autoOk: PropTypes.bool,
  disableFutureDate : PropTypes.bool,
  disablePast : PropTypes.bool,
  disableFuture : PropTypes.bool
};
export default A_DatePicker;
