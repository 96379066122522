import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageContext from "../../../../../../contexts/pageContext";
import M_TemplatePageTitle from "../TemplatePageTitle/M_TemplatePageTitle";
import M_PageToolbar from "../Toolbar/M_PageToolbar";
import M_ToolToolbarFilterContainer from "../Toolbar/M_ToolToolbarFilterContainer";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useParams } from "react-router";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: "auto",
    width: "auto",
    elevation: 0,
  },
  secondaryMenuButton: {
    marginLeft: "-19px",
  },
  header: {
    background: "transparent",
  },
  pageToolbarFIlter: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    // paddingTop: "5px",
    paddingLeft: "2.5%",
  },
  newHeight: {
    height: "96px !important",
  },
  /*   FundDropdown:{
    color:"white"
  } */
}));

const M_TemplatePagesHeader = (props) => {
  const classes = useStyles();
  const headerLogo = props.logo || "company-default.jpg";
  const [heightData, setHeight] = useState(null);
  const { pageUrl, templateId, crmId } = useParams();
  const {onReload} = props
  const stageCanvasRef = useRef(null);

  useEffect(() => {
    if (stageCanvasRef.current) {
      let h = +stageCanvasRef.current.offsetHeight;
      setHeight(h);
    }
  }, [stageCanvasRef]);

  return (
    <PageContext.Consumer>
      {({ pageConfig }) => {
        let toolbar =
          pageConfig &&
          pageConfig.toolbar !== "" &&
          JSON.parse(pageConfig.toolbar);

        //let toolbar = mockToolbar;
        const optionSets =
          pageConfig && pageConfig.optionSets ? pageConfig.optionSets : [];
        let pageFilters;
        if (toolbar && toolbar.primary && toolbar.primary.filters) {
          const inScopeFilters = toolbar.primary.filters.filter(
            (item) =>
              item.templateIds && item.templateIds.indexOf(templateId) > -1
          );
          if (inScopeFilters && inScopeFilters.length > 0) {
            pageFilters = inScopeFilters;
          } else {
            pageFilters = undefined;
          }
        }

        if (!toolbar && !props.title) {
          return <div className="header-container">Loading...</div>;
        }
        return (
          <>
            <div
              className={classes.header}
              style={
                pageFilters && pageFilters !== undefined
                  ? { paddingBottom: "110px" }
                  : { paddingBottom: `${heightData}px` }
              }
            >
              <div
                className="header-container"
                style={{ height: "auto" }}
                ref={stageCanvasRef}
              >
                <div className="grid-item page-title">
                  <M_TemplatePageTitle
                    title={props.title}
                    subtitle={props.subtitle}
                    logo={headerLogo}
                    toolbar={toolbar}
                  ></M_TemplatePageTitle>
                  <div className={classes.pageToolbarFIlter}>
                    {toolbar && (
                      <M_ToolToolbarFilterContainer
                        key="page_toolbarContainer"
                        toolbar={toolbar}
                        optionSets={optionSets}
                      />
                    )}
                  </div>
                </div>

                <div className="grid-item " style={{ margin: "auto 0" }}>
                  {/*Toolbar starts here */}
                  {toolbar && (
                    <M_PageToolbar
                      key="page_toolbar"
                      title={props.title}
                      toolbar={toolbar}
                      optionSets={optionSets}
                      params={{ pageUrl, crmId, templateId }}
                      downloadExcelTemplatePopUp={
                        props.downloadExcelTemplatePopUp
                      }
                      onReload={onReload}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        );
      }}
    </PageContext.Consumer>
  );
};
M_TemplatePagesHeader.propTypes = {
  logo: PropTypes.any,
  title: PropTypes.any,
  downloadExcelTemplatePopUp: PropTypes.any,
  subtitle: PropTypes.any,
  onReload: PropTypes.func,
};
export default withRouter(M_TemplatePagesHeader);
