import React from "react";
import { Grid, Typography } from "@material-ui/core";
import A_CheckBox from "../../Inputs/Checkboxes/A_Checkbox";
import A_Button from "../../Inputs/Buttons/A_Button";
import propTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
const useStyles = makeStyles({
  approvalMargin: {
    margin: "2rem 0",
  },
  checkBox: { display: "flex" },
  required: {
    fontWeight: "bold",
    color: "red",
  },
});
export default function A_TableApproval({
  checkBox,
  isEnable,
  btn,
  onApprove,
  edgeLabel,
  properties,
}) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(
    checkBox && checkBox.defaultValue ? checkBox.defaultValue : false
  );
  React.useEffect(() => {
    setChecked(false);
  }, [isEnable]);
  let loggedInUserDetail =   JSON.parse(localStorage.getItem('contact_crm'));
  const userid = useSelector((state) =>
    state &&
    state.getLoggedInUserReducer &&
    state.getLoggedInUserReducer.data &&
    state.getLoggedInUserReducer.data[0] &&
    state.getLoggedInUserReducer.data[0].id
      ? state.getLoggedInUserReducer.data[0].id
      : loggedInUserDetail &&  loggedInUserDetail.properties && loggedInUserDetail.properties.IsUserPreferenceActivated ? loggedInUserDetail.properties.IsUserPreferenceActivated:""
  );
  const handleChecked = (value) => {
    setChecked(value);
  };
  const handleClick = () => {
    onApprove(userid, edgeLabel, properties);
  };
  return (
    <div>
      <div className={classes.approvalMargin}>
        <div className={classes.checkBox}>
          <span>
            {checkBox && checkBox.show ? (
              isEnable ? (
                <A_CheckBox
                  key={"enabled"}
                  required={checkBox.required ? checkBox.required : false}
                  checked={checked}
                  onChange={handleChecked}
                  disabled={!isEnable}
                />
              ) : (
                <A_CheckBox disabled={!isEnable} key="disabled" />
              )
            ) : (
              ""
            )}
          </span>
          <span>
            <span className={classes.required}>*</span>
            {checkBox && checkBox.show && checkBox.description
              ? checkBox.description
              : ""}
          </span>
        </div>
      </div>
      {btn ? (
        <A_Button
          disabled={
            checkBox && checkBox.show ? (isEnable ? !checked : true) : false
          }
          label={btn.label}
          color="primary"
          onClick={handleClick}
        ></A_Button>
      ) : (
        ""
      )}
    </div>
  );
}
A_TableApproval.propTypes = {
  checkBox: propTypes.object,
  label: propTypes.string,
  btn: propTypes.object,
};
