/* eslint-disable */
import React from "react";
import userManager from "../../../../utils/oidc/userManager";
import M_RedirectToLogin from "../../Molecules/Common/Navigations/M_RedirectToLogin";
import M_UnsupportedBrowser from "../../molecules/common/error/M_UnsupportedBrowser";
import ErrorBoundary from "../../molecules/common/error/ErrorBoundary";
import { isUnsupportedBrowser } from "../../../../utils/helpers/common";
class LoginPage extends React.Component {
  state = { isUnsupported: isUnsupportedBrowser() };
  componentDidMount() {
    let pathname =
      this.props.history.location.state &&
      this.props.history.location.state.referrer &&
      this.props.history.location.state.referrer.pathname
        ? this.props.history.location.state.referrer.pathname
        : "";
    if (!this.state.isUnsupported) {
      userManager.signinRedirect({ data: { pathname: pathname } });
    }
  }

  render() {
    console.log("isUnsupported: ", this.state.isUnsupported);
    if (this.state.isUnsupported) {
      return (
        <div style={styles.root}>
          <M_UnsupportedBrowser />
        </div>
      );
    }
    return (
      <div style={styles.root}>
        <ErrorBoundary>
          <M_RedirectToLogin />
        </ErrorBoundary>
      </div>
    );
  }
}

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundImage: "linear-gradient(#222,#234)",
    height: "100vh",
    //backgroundColor: "#000",
  },
};

export default LoginPage;
