/* eslint-disable */
export const verifyValidChartRequest = (chartRequest) => {
  let isChartRequestValid = true;
  const paramCount =
    chartRequest && chartRequest.parameters
      ? Object.keys(chartRequest.parameters).length
      : 0;
  let chartNameIndex = 0;
  if (paramCount >= 2) {
    chartNameIndex = 1;
  }
  let chartKey = JSON.stringify(chartRequest);
  let storedChartKeys = localStorage.getItem("chartStorageKeys");
  let chartKeysArr = storedChartKeys ? storedChartKeys.split("__") : [];
  if (chartKeysArr.indexOf(chartKey) > -1) {
    isChartRequestValid = false;
  } else {
    //ChartStorageKey should be config driven in whole app
    localStorage.setItem("chartStorageKeys", storedChartKeys + "__" + chartKey);
  }
  return isChartRequestValid;
};

export const hasPageFilter = (currentPage) => {
  // check if page has the parameters
  let result = true;
  if (currentPage && currentPage.pageConfig && currentPage.pageConfig.toolbar) {
    let toolbar = JSON.parse(currentPage.pageConfig.toolbar);
    let filters =
      toolbar && toolbar.primary && toolbar.primary.filters
        ? toolbar.primary.filters
        : [];
    if (!(filters.length > 0)) {
      result = false;
    }
  } else {
    result = false;
  }
  return result;
};

export const toolbarFilterData = (props, templateId) => {
  let toolbarFilters = [];
  try{
    if(props.currentPage && 
      props.currentPage.pageConfig && 
      props.currentPage.pageConfig.toolbar && 
      typeof props.currentPage.pageConfig.toolbar === "string"){
        let toolbar = JSON.parse(props.currentPage.pageConfig.toolbar);
        toolbarFilters = toolbar && toolbar.primary && toolbar.primary.filters ?
          toolbar.primary.filters.filter(filter => {
            if(filter.templateIds) {  
              let templatePresent = false; 
              filter.templateIds.map(filtertemplateId => {
                if(filtertemplateId === templateId){ 
                  templatePresent = true;
                  return;
                }
              })
              return templatePresent;
            }
            else {
              return false;
            }
        })
        : []
    }
  }
  catch(e){
    toolbarFilters = [];
  } 
  return toolbarFilters;
}
