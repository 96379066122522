import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import propTypes from "prop-types";
import "./M_Tabs.css";
import {
  handleOptionSetValue,
  currency,
} from "../../../../../Utils/Helpers/optionSet.helper";

export default function M_TabsView(props) {
  var currencyValue, newArray;
  try {
    try {
      newArray = props.viewData.filter(function (el) {
        return el.label.toLowerCase() == "currency";
      });
      currencyValue = JSON.parse(newArray);
      currencyValue = currencyValue[0].title.toLowerCase();
    } catch (e) {
      currencyValue = newArray[0].value.toLowerCase();
    }
  } catch (e) {
    currencyValue = "";
  }

  function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }
  var newVal = handleOptionSetValue(currencyValue, false);

  const getValues = (element) => {
    var label = element.value;
    try {
      let data = "";
      if (label === undefined || label === "") {
        data = "";
      } else {
        data = String(label);
      }
      let newString = stripHtml(data);
      if (newString.toLowerCase() === "true" || newString === true) {
        return "Yes";
      }
      if (newString.toLowerCase() === "false" || newString === false) {
        return "No";
      }
      if (element.type == "Financial" || element.type == "financial") {
        try {
          let orgValue = newString;
          if (!isNaN(newString)) {
            newString = newString
              ? Number(newString).toLocaleString("en-GB")
              : "";
          } else {
            newString = orgValue;
          }
        } catch {}
        let icon = currency[newVal] == undefined ? "" : currency[newVal];
        return `${newString ? icon : ""} ${newString}`;
      }
      return newString;
    } catch (e) {
      if (label === undefined || label === "") {
        return label;
      } else {
        return String(label);
      }
    }
  };
  return (
    <div>
      <Table
        className="minwidth-full table-alignment"
        size="small"
        aria-label="a dense table"
      >
        <TableBody>
          {props.viewData.map((element, index) => {
            return (
              <TableRow key={index}>
                {element.label && (
                  <TableCell
                    style={{ fontWeight: "600" }}
                    component="th"
                    scope="row"
                  >
                    {element.label}
                  </TableCell>
                )}
                <TableCell colSpan={element.label ? 1 : 2} align="left">
                  <div>{getValues(element)}</div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: element.html,
                    }}
                  ></div>
                  {element.link ? (
                    <a href={element.link} target="_blank" rel="noreferrer">
                      {element.link}
                    </a>
                  ) : (
                    ""
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

M_TabsView.propTypes = {
  viewData: propTypes.any,
};
