/* eslint-disable */
import { take, put, select } from "redux-saga/effects";
import axios from "axios";
import { manageExcelTemplateActionType } from "../actions/actionTypes";
import * as manageExcelTemplateActions from "../actions/manageExcelTemplateActions";

import { manageExcelTemplateDownloadActionType } from "../actions/actionTypes";
import * as manageExcelTemlateDownloadActions from "../actions/manageExcelTemlateDownloadActions";
import * as man from "../actions/manageExcelTemplateActions";
import * as commonFunctions from "../../Utils/Helpers/common";

export function* saveTabSaga() {
  while (true) {
    const action = yield take(manageExcelTemplateActionType.SAVE_TABS);
    const { data , investmentId} = action.payload;
    console.log("save excel template called is called:", action.payload);

    const state = yield select();
    let config = commonFunctions.getHttpConfig();
    try {
      const res = yield axios.post(
        `${process.env.PortalAdminAPIUrl}/api/ExcelTemplates/update/${investmentId}`,
        data,
        config
      );

      // yield put(

      //     manageExcelTemplateActions.setTabs(res.data)
      // );
      yield put(
        manageExcelTemplateActions.saveTabsSuccessed({
          data: res.data,
          saveTabsSuccessed: true,
        })
      );
    } catch (e) {
      // manageExcelTemplateActions.saveTabsFailed({msg: 'Some error occured',currentPageSaved: true})
      // console.log("can not save template.");
      // console.log(e.message);

      yield put(
        manageExcelTemplateActions.saveTabsFailed({
          data: data,
          saveTabsSuccessed: true,
          errorMessage: e && e.response && e.response.data && e.response.data.Message,
        })
      );
    }
  }
}

export function* getTabsSaga() {
  while (true) {
    const action = yield take(manageExcelTemplateActionType.GET_TABS);
    const { investmentId } = action.payload;
    console.log("get excel template called is called:", action.payload);

    const state = yield select();
    let config = commonFunctions.getHttpConfig();
    try {
      const res = yield axios.get(
        `${process.env.PortalAdminAPIUrl}/api/ExcelTemplates/${investmentId}`,
        config
      );

      yield put(manageExcelTemplateActions.setTabs(res.data));
      yield put(manageExcelTemplateActions.getTabsSuccessed(res.data));
    } catch (e) {
      manageExcelTemplateActions.getTabsFailed({
        msg: "Some error occurred",
        currentPageSaved: true,
      });
      console.log("can not save template.");
      console.log(e.message);
    }
  }
}

export function* downloadExcelSaga() {
  
  while (true) {
    const action = yield take(
      manageExcelTemplateDownloadActionType.DOWNLOAD_EXCEL
    );
    const { series, fromYear, incrementMonth, dealId ,startMonth , requestPayload, InvestmentName} = action.payload;

    console.log("get excel template called is called:", action.payload);

    const state = yield select();
    let config = commonFunctions.getHttpConfig();
    try {
      const res = yield axios.post(
        `${process.env.PortalAdminAPIUrl}/api/ExcelTemplates/GetExcelTemplate`,
        {
          ...requestPayload, series, year:fromYear, increment:incrementMonth, investmentId:dealId ,startmonth:startMonth, InvestmentName : InvestmentName
        },{ ...config, responseType: "blob" }
      );

      // var blob = new Blob([res.data],{type:headers['application/xlsx']});
      // var link = document.createElement('a');
      // link.href = window.URL.createObjectURL(blob);
      // link.download = "Your_file_name";
      // link.click();

      yield put(
        manageExcelTemlateDownloadActions.downloadExcelSuccessed({
          year : fromYear,
          data: res.data,
          downloadExcelSuccessed: true,
          InvestmentName : InvestmentName
        })
      );
    } catch (e) {
      yield put(
        manageExcelTemlateDownloadActions.downloadExcelFailed({
          msg: "Some error occurred",
          downloadFailed: true,
        })
      );
    }
  }
}
