/* eslint-disable */
import { take, put } from "redux-saga/effects";
import axios from "axios";
import * as commonFunctions from "../../Utils/Helpers/common";
import { checklistActionTypes } from "../actions/actionTypes";
import * as checklistActions from "../actions/checklistActions";
export function* getChecklistSaga() {
  while (true) {
    const action = yield take(checklistActionTypes.GET_CHECKLIST);
    const { pageUrl} = action.payload;
    let config = commonFunctions.getHttpConfig();
    
    try {
        
      const res = yield axios.post(
        `${process.env.API_URL}/CRMChartData/getChecklistMasterData`,null,
        config
      );
      let convertedData = res.data === "Empty" ? [] : res.data;
      const fields = yield axios.get(
        `${process.env.API_URL}/Common/getChecklistMetaData?type=checklist-workflow-config`,
        config
      );
      let payload = {
        sourceId: "",
        sourceLabel: pageUrl,
        label: pageUrl,
        fields: [pageUrl],
      };
      const itemsResponse=yield axios.post(`${process.env.API_URL}/CRMChartData/getGenericCRMData`,payload,config);
      let item=itemsResponse.data==="Empty"?[]:itemsResponse.data;
       let workFlowData = fields.data === "Empty" ? [] : fields.data;
      yield put(
        checklistActions.getChecklistSucceeded({
        
          data: convertedData,
          workFlow:workFlowData,
          itemResponse:item,
          getChecklistStarted: false,
        })
      );
    } catch (e) {
      checklistActions.getChecklistFailed({
        msg: "Some error occured",
        getChecklistStarted: false,
      });
    }
  }
}
