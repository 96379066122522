import React from "react";
import Default_Avatar from "../../../../../Assets/Images/Defaults/defLogo.png";
import {
  Box,
  ListItem,
  ListItemText,
  Typography,
  List,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import "./D_Deals.scss";
import Card from "@material-ui/core/Card";
import {
  getFormattedDate,
  FromEpochFormat,
} from "../../../../../Utils/Helpers/dateUtils";
import { tagTitle } from "../../../../../Utils/config/config";
import propTypes from "prop-types";
const useStyles = makeStyles(() => ({
  mutiTags: {
    width: "auto",
    padding: "3px 6px 5px",
    height: "27px",
    borderRadius: "6px",
    marginRight: "3px",
    fontSize: "9px",
    color: "black",
    fontWeight: "500",
    backgroundColor: "#d3d3d387",
  },
  mutiTagsContailner: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    marginBottom: "12px",
    paddingLeft: "16px",
  },
  boxContainer: {
    marginBottom: "10px",
  },
  cardCotainer: {
    marginBottom: "5px",
  },
  gridMainHead: {
    textAlign: "center",
    marginBottom: "15px",
  },
  innerHeading: {
    fontWeight: "500",
  },
  customPad: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  PipeLineContainer: {
    maxHeight: "100vh",
    overflow: "auto",
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
  },
}));

export default function O_Pipeline(props) {
  const classes = useStyles();

  const itemSecond = {
    marginLeft: "10px",
  };

  const handleBrokenImage = (e) => (e.target.src = Default_Avatar);

  var isJsonParsable = (string) => {
    try {
      JSON.parse(string);
    } catch (e) {
      return false;
    }
    return true;
  };

  return (
    <>
      {!props.dealData ? (
        <>
          <Skeleton variant="text" width={80} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="rect" width={"100%"} height={118} />
        </>
      ) : (
        <Typography
          variant="h6"
          className={"cardHeaderDesign " + classes.gridMainHead}
        >
          {props.item && props.item.header && props.item.header.title}
        </Typography>
      )}

      <Box className={classes.PipeLineContainer} id="dealView">
        {props.dealData &&
        props.dealData.length > 0 &&
        props.item &&
        props.item.content &&
        props.item.content.dealStatus ? (
          props.dealData.filter(
            (items) => items.currentStatus == props.item.content.dealStatus
          ).length > 0 ? (
            props.dealData
              .filter(
                (items) => items.currentStatus == props.item.content.dealStatus
              )
              .map((item, index) => {
                let imageAsUrl = "";
                let imagesData =
                  props.imageData &&
                  props.imageData.length > 0 &&
                  props.imageData.filter(
                    (images) => images.file === "profile_" + item.id
                  );
                if (imagesData && imagesData.length > 0 && imagesData[0].link) {
                  imageAsUrl = imagesData[0].link;
                }
                return (
                  <Card key={index} className={classes.cardCotainer}>
                    {/* <CardContent> */}
                    <a
                      href={item.url}
                      style={{ color: "inherit", textDecoration: "inherit" }}
                      key={item.id}
                    >
                      <Box className={classes.boxContainer}>
                        <ListItem className={classes.customPad}>
                          <ListItemText>
                            <Box style={{ display: "flex" }}>
                              <Box>
                                <img
                                  onError={handleBrokenImage}
                                  className="img-responsive"
                                  src={imageAsUrl}
                                  alt={
                                    props.item.content &&
                                    props.item.content.content &&
                                    props.item.content.content.field &&
                                    props.item.content.content.field.title
                                      ? item[
                                          props.item.content.content.field.title
                                        ]
                                      : ""
                                  }
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </Box>
                              <Box style={itemSecond}>
                                <Typography
                                  className={
                                    classes.innerHeading + " dealName1"
                                  }
                                >
                                  {props.item.content &&
                                  props.item.content.content &&
                                  props.item.content.content.field &&
                                  props.item.content.content.field.title
                                    ? item[
                                        props.item.content.content.field.title
                                      ]
                                    : ""}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  className="dealName2"
                                  style={{ color: "#3a3a3aa3" }}
                                >
                                  {props.item.content &&
                                  props.item.content.content &&
                                  props.item.content.content.field &&
                                  props.item.content.content.field.subtitle
                                    ? item[
                                        props.item.content.content.field
                                          .subtitle
                                      ]
                                    : ""}
                                </Typography>
                              </Box>
                            </Box>
                            {/* <Box className="dealsCustom">
                        <Typography
                          variant="body2"
                          style={{ color: "red !important" }}
                          dangerouslySetInnerHTML={{
                            __html: item.dealDescription,
                          }}
                        >
                        </Typography>
                      </Box> */}
                          </ListItemText>
                        </ListItem>
                        <List className={classes.mutiTagsContailner}>
                          {props.item.content &&
                          props.item.content.content &&
                          props.item.content.content.field &&
                          props.item.content.content.field.tags &&
                          Array.isArray(props.item.content.content.field.tags)
                            ? props.item.content.content.field.tags.map(
                                (tag) => {
                                  if (tag.title === tagTitle.MODIFIED_DATE) {
                                    let dateObject = FromEpochFormat(
                                      isNaN(item[tag.title])
                                        ? item[tag.title]
                                        : parseInt(item[tag.title])
                                    );
                                    if (dateObject) {
                                      let tagTitleItem = getFormattedDate(
                                        dateObject.toString(),
                                        "DD/MM/YYYY"
                                      );

                                      return (
                                        <ListItem className={classes.mutiTags}>
                                          {tagTitleItem}
                                        </ListItem>
                                      );
                                    }
                                  } else if (
                                    tag.title !== tagTitle.MODIFIED_DATE &&
                                    item[tag.title]
                                  ) {
                                    return (
                                      <ListItem className={classes.mutiTags}>
                                        {isJsonParsable(item[tag.title])
                                          ? item[tag.title][0] &&
                                            item[tag.title][0].title
                                            ? JSON.parse(item[tag.title])[0]
                                                .title
                                            : item[tag.title] &&
                                              item[tag.title].label &&
                                              JSON.parse(item[tag.title]).label
                                          : item[tag.title]}
                                      </ListItem>
                                    );
                                  } else {
                                    return "";
                                  }
                                }
                              )
                            : ""}

                          {/* {getTags(item.tags)} */}
                        </List>

                        {/* {index !== dealData.length - 1 ? <Divider /> : ""} */}
                      </Box>
                    </a>
                    {/* </CardContent>      */}
                  </Card>
                );
              })
          ) : (
            <div className={classes.noData}>
              <p>WE ARE SORRY, NO CONTENT FOUND!</p>
            </div>
          )
        ) : (
          ""
        )}
        {props.dealData && props.dealData.length <= 0 ? (
          <>
            {
              <div className={classes.noData}>
                <p>WE ARE SORRY, NO CONTENT FOUND!</p>
              </div>
            }
          </>
        ) : (
          ""
        )}
      </Box>
    </>
  );
}

O_Pipeline.propTypes = {
  dealData: propTypes.any,
  item: propTypes.any,
  imageData: propTypes.any,
};
