/* eslint-disable */
import React, { useEffect, useState } from "react";
import A_EnhancedTable from "../../../Atoms/DataDisplay/DataGrid/A_EnhancedTable";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { Box } from "@material-ui/core";
import * as httpCall from "../../../../../Utils/apis/apis";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import M_MultiValueList from "../../../Molecules/Common/DealAdministartion/M_MultiValueList";
import defLogo from "../../../../../Assets/Images/Defaults/meetingDef.jpg";
const useStyles = makeStyles({
  PreviousIRC: {
    overflow: "hidden",
    minWidth: "100%",
  },
  table: {
    minWidth: "100%",
  },
  marginTop: {
    marginTop: "1rem",
  },
});

export default function O_Historychart(props) {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [selectedDeal,setSeletedDeal] = useState()
  let redirectTo = (pageUrl, crmId, templateId) => {
    history.push(`/${pageUrl}/${crmId}/${templateId}`);
    window.location.reload();
  };
  let changeDateFormat = (i) => {
    var options = { year: "numeric", month: "long", day: "numeric" };
    var today = new Date(i);
    return today.toLocaleDateString("en-US", options);
  };
  const handleID = (id,item) => {
    setSeletedDeal(id);
    props.callBack(id);
  };
  useEffect(() => {
    setComponentData();
  }, []);
  const setComponentData = async () => {
    let newData = [];
    let payload =
      props.content && props.content.payload ? props.content.payload : "";
    const response = await httpCall.httpPost(`/IRC/getIRCRecord`, payload);
    let outJSON = await response;
    let dim = [];
    for (let i in outJSON) {
      if (outJSON[i].ircDate) {
        outJSON[i].ircDate = outJSON[i].ircDate.split(" ")[0];
        dim.push(outJSON[i]);
      }
    }
    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    const newD = dim.sort(function (a, b) {
      return new Date(b.ircDate) - new Date(a.ircDate);
    });
    // Todo- required for future development
    // let groubedByDate = groupBy(newD, "ircDate");
    for (let i in newD) {
      let datastring = "";
      if(i==0){
        handleID(newD[i].id,newD[i])
      }
      // Todo- required for future development
      // for (let j in groubedByDate[i]) {
      //   datastring +=
      //     (groubedByDate[i][j].deal ? groubedByDate[i][j].deal : "") + " ";
      // }

      let detail = (
        <Box
        key={i}
        onClick={() => {
          handleID(newD[i].id, newD[i]);
        }}
      >
        <M_MultiValueList
          heading={newD[i].deal ? newD[i].deal : ""}
          subHeading={newD[i].ircDate}
          title={defLogo}
          selected={selectedDeal&&selectedDeal===newD[i].id?true:false}
          
        />
        </Box>
      );
      let createdData = {
        Detail: detail,
        Data: newD[i].ircDate,
        Deal:newD[i].deal
      };
      newData.push(createdData);
    }
    setData(newData);
  };
  let colmunConfiguration = {
    title: "",
    noDataText: "No data available.",
    paginate: true,
    searchable: true,
    sortable: false,
    striped: true,
    dragable: false,
    columnConfig: {
     
      Detail: {
        id: "Detail",
        label: "",
        sorting: false,
        hide: false,
      },
      Deal:{
        id: "Deal",
        label: "",
        sorting: false,
        hide: true,
      },
      Data: {
        id: "Data",
        label: "",
        sorting: false,
        hide: true,
      },
    },
  };

  return (
    <Box
      style={{
        maxHeight: props.maxHeight ? props.maxHeight : "60vh",
        overflowY: "auto",
      }}
    >
      <A_EnhancedTable
        className={classes.table}
        minWidth={classes.table}
        configurations={colmunConfiguration}
        data={data}
      ></A_EnhancedTable>
    </Box>
  );
}
