import * as React from "react";
import A_DatePicker from "../../Atoms/Inputs/DateTimePickers/A_DatePicker";
import theme from "../../../Themes/defaultTheme";
import { ThemeProvider } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  formFieldsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "50%",
  },
  formLabelField: {
    maxWidth: "140px",
    paddingTop: "20px",
    paddingLeft: "5px",
    width: "155px",
    wordBreak: "break-word",
  },
  dateValueField: {
    width: "155px",
    minWidth: "155px",
  },
}));

const M_FormDateField = (props) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.formFieldsWrapper}>
        <div className={classes.formLabelField}>{props.label}</div>
        <div className={classes.dateValueField}>
          <A_DatePicker
            label=""
            onChange={props.onChange}
            isDateValidOrNot={
              props.isDateValidOrNot ? props.isDateValidOrNot : ""
            }
            defaultValue={props.defaultValue}
          ></A_DatePicker>
        </div>
      </div>
    </ThemeProvider>
  );
};
M_FormDateField.propTypes = {
  onChange: PropTypes.func,
  isDateValidOrNot: PropTypes.bool,
  defaultValue: PropTypes.date,
  label: PropTypes.string,
};
export default M_FormDateField;
