import { httpPost, httpPut } from "../../apis/apis";
//Add
export const CreateDestinationVertex = async (payload) => {
  const res = await httpPost(`/CRMData/insert`, payload);
  return res;
};
//Edit
export const EditDestinationVertex = (payload) => {
  httpPost(`/CRMData/edit`, payload);
};
//addEdge
export const AddEdge = async (payload) => {
  const res = await httpPut(`/CRMData/addEdge`, payload);
  return res;
};
