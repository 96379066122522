import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { ListItemText } from "@material-ui/core";
import * as httpCall from "../../../../../../../Utils/apis/apis";
import Skeleton from "@material-ui/lab/Skeleton";
import { useParams } from "react-router";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import axios from "axios";
import excel from "../../../../../../../Assets/Images/Defaults/excel.png";
import * as commonFunctions from "../../../../../../../Utils/Helpers/common";
import { getFormattedDate } from "../../../../../../../Utils/Helpers/dateUtils";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import M_ActionGroup from "../../../ActionGroup/M_ActionGroup";

const useStyles = makeStyles(() => ({
  CustomCard: {
    maxHeight: "52vh",
    overflow: "scroll",
    overflowX: "hidden",
  },
  list: {
    borderBottom: "1px solid lightgrey",
    "&:hover": {
      backgroundColor: "#f9f9f9",
      cursor: "pointer",
    },
  },
  avatar: {
    margin: "auto",
    paddingLeft: "10px",
  },
  heading: {
    marginTop: "10px",
  },
  ActionGroup: {
    paddingTop: "25px",
  },
  ListFlex: {
    display: "flex",
  },
}));

const PreviousTemplate = (props) => {
  const classes = useStyles();
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let { crmId } = useParams();
  const investmentId = crmId ? crmId : "4E6A21C1-CEEA-E811-80D3-005056BE5CE6";
  const fileListWithDisplayName = (fileList) => {
    let listwithdisplayname = [];
    if (fileList) {
      fileList.length > 0 &&
        fileList.map((file) => {
          let fileArr = file.file.split("/");
          if (fileArr.length > 3) {
            file = { ...file, fileDisplayName: fileArr[3] };
            listwithdisplayname.push(file);
          }
        });
    }
    return listwithdisplayname;
  };
  React.useEffect(() => {
    const getReports = async () => {
      setIsLoading(true);
      let url = "";
      if (investmentId !== "0") {
        url = `${process.env.API_URL}/DimSource/getDimSourceWithInvestmentId/${investmentId}`;
        const result = await httpCall.httpGet(url);
        if (result) {
          let filesList = fileListWithDisplayName(result);
          setIsLoading(false);
          setReports(filesList);
        }
      }
    };
    getReports();
  }, []);
  const handleClick = async (e) => {
    const documentName = e.file;
    const url = `${process.env.DOCUMENT_HELPER}`;
    const httpConfig = commonFunctions.getHttpConfig();
    //important
    /* let subdomain =
      "dev";  new URL(window.location.href).hostname.split(".")[0] */
    let subdomain = new URL(window.location.href).hostname.split(".")[0];
    let header = {
      investmentId:crmId,
      filename: documentName,
      type: "excel",
      "file-operation": "download",
      "process-file": "yes",
      "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
      subdomain: subdomain,
      Authorization: httpConfig.headers.Authorization,
    };
    axios
      .post(
        url,
        {
          method: "GET",
        },
        { responseType: "blob", headers: header }
      )
      .then((response) => {
        const url1 = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url1;
        link.setAttribute("download", e.fileDisplayName);
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <>
      <div className={classes.heading}>
        <h5>Previous Uploads</h5>
      </div>
      <div className={classes.CustomCard}>
        {isLoading && (
          <>
            <Skeleton variant="text" width={80} height={80} />
            <Skeleton variant="text" width={"100%"} height={80} />
            <Skeleton variant="rect" width={"100%"} height={118} />
          </>
        )}
        {reports && reports.length > 0
          ? reports.map((item,index) => (
              <ListItem key={index} className={classes.list}>
                <Grid container spacing={2}>
                  <Grid xs={11}>
                    <List
                      button
                      onClick={() => handleClick(item)}
                      className={classes.ListFlex}
                    >
                      <ListItemAvatar className={classes.avatar}>
                        <Avatar src={excel} variant="square" />
                      </ListItemAvatar>
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={item.fileDisplayName}
                          secondary={
                            "Uploaded on " +
                            getFormattedDate(item.dateLoaded, "DD-MM-YYYY")
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  {props.currentPage &&
                  props.currentPage.pageConfig &&
                  props.currentPage.pageConfig.pageSecurity &&
                  props.currentPage.pageConfig.pageSecurity.Full == true ? (
                    <Grid xs={1} className={classes.ActionGroup}>
                      <div>
                        <M_ActionGroup delete={props.content.delete} />
                      </div>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </ListItem>
            ))
          : ""}
      </div>
    </>
  );
};

PreviousTemplate.propTypes = {
  delfile: PropTypes.func,
  currentPage: PropTypes.object,
  content: PropTypes.any,
};

export default PreviousTemplate;
