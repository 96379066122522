 /* eslint-disable */
import * as React from "react";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 15;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      maxWidth: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function A_MultiselectChip(props) {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState(props.names);
  const width = props.width ? props.width + "px" : "300px";
  const minWidth = props.width ? props.width + 38 + "px" : "300px";
  const [error, setError] = React.useState(props.error ? props.error : "");
  const isRequired = props.required;
  const message = props.message ? props.message :"";
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    props.handleTabsSeletion(
      typeof value === "string" ? value.split(",") : value
    );
    if (isRequired && (!value || value.length < 1)) {
      setError(props.error ? props.error : message);
    } else if (isRequired && value.length > 0) {
      setError("");
    }
  };

  return (
    <div>
      <FormControl style={{ minWidth: minWidth }} sx={{ m: 1, width: 300 }}>
        <InputLabel
          id="demo-multiple-chip-label"
          style={{ paddingLeft: "10px", transformOrigin: "top" }}
        >
          {props.label}
        </InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id={props.id ? props.id : "1"}
          multiple
          value={personName}
          onChange={handleChange}
          input={
            <OutlinedInput id={props.id ? props.id : "1"} label={props.label} />
          }
          renderValue={(selected) => (
            <Box
              style={{
                maxWidth: width,
                minWidth: width,
                height: "auto",
                flexWrap: "wrap",
                display: "flex",
                gap: "0.5",
              }}
              // sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
            >
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {props.names && props.names.length > 0
            ? props.names.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, personName, theme)}
                >
                  {name}
                </MenuItem>
              ))
            : ""}
        </Select>
        {error ? (
          <span
            style={{
              color: "red",
              maxWidth: width,
              minWidth: width,
              height: "auto",
              flexWrap: "wrap",
              display: "flex",
              gap: "0.5",
            }}
          >
            {error}
          </span>
        ) : (
          ""
        )}
      </FormControl>
    </div>
  );
}
