import * as httpCall from "../../../../../../../Utils/apis/apis";
const getTemplate = async () => {
    let res = await httpCall.httpGet("/CRMData/getSearchTemplate");
    return res;
};
const getSearchData = async (keyword) => {
    let res = await httpCall.httpGet(
        `/CRMData/perspicaciousSearch?keyword=${keyword}`
    );
    return res;
};

export const getNewSearchResult = async (value) => {
    let template = {};
    if (localStorage.getItem("searchTemplateNewData")) {
        try {
            template = JSON.parse(localStorage.getItem("searchTemplateNewData"));
        } catch (e) {
            let tempTemplate = await getTemplate();
            template = tempTemplate.searchResultTemplate;
            localStorage.setItem("searchTemplateNewData", JSON.stringify(template));
        }
    } else {
        let tempTemplate = await getTemplate();
        template = tempTemplate.searchResultTemplate;
        localStorage.setItem("searchTemplateNewData", JSON.stringify(template));
    }
    let searchResult = await getSearchData(value);
    let finalSearchResult = [];
    for (let searchData in searchResult) {
        let searchSelectedTemplates = template.filter((item) => {
            return item.label.toLowerCase() === searchResult[searchData].label.toLowerCase();
        });
        if (searchSelectedTemplates.length == 0) {
            continue;
        }
        let title = searchSelectedTemplates
            .map((templateData) => {
                return templateData.title
                    .map((titleName) => {
                        return searchResult[searchData][titleName];
                    })
                    .join(" ");
            })
            .join(" ");
        let subtitle = searchSelectedTemplates
            .map((templateData) => {
                return templateData.subtitle
                    .map((titleName) => {
                        return searchResult[searchData][titleName];
                    })
                    .join(" ");
            })
            .join(" ");
        let id = searchResult[searchData]["id"];
        let header = searchSelectedTemplates[0]["header"] ? searchSelectedTemplates[0]["header"] : "";
        let pageUrl = searchSelectedTemplates[0]["label"] ? searchSelectedTemplates[0]["label"] : "";
        let linkValue = searchResult[searchData]["link"];
        let pageId = searchSelectedTemplates[0]["pageId"] ? searchSelectedTemplates[0]["pageId"] : "";
        let resultTemplate = {
            title: title,
            email: subtitle,
            header:header,
            id: id,
            pageId: pageId,
            pageUrl: pageUrl,
            link: linkValue,
        };
        finalSearchResult.push(resultTemplate);
    }
    return finalSearchResult;
};