/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import theme from "../../../../Themes/defaultTheme";
import { useParams } from "react-router";
// import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
import useStyles from "./useStyles";
import { httpPost } from "../../../../../Utils/apis/apis"; 
import * as commonFunctions from "../../../../../Utils/Helpers/common";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
const M_InvoiceSaveButton = React.memo((props) => {
  let { pageUrl, crmId } = useParams();
  const classes = useStyles();
  const handleInvoiceSave = async() => {
    const budgetLines = props.payloadBudgetlines.map((item)=>{return [item.id,item.AllocationThisInvoiced]});
    let newObj = budgetLines.map((item)=>{return Object.assign({}, item) })
    for (let i=0;i<newObj.length;i++){
      newObj[i].budgetId=newObj[i][0];
      newObj[i].budgetInvoiceAmount=newObj[i][1];
      delete newObj[i][0];
      delete newObj[i][1];
    }
    let payload ={
      "invoiceId":crmId,
      "budgets":newObj
    }
    try {
      let resUpdate = {};
      let config = commonFunctions.getHttpConfig();
      let urlforUpdate = `${process.env.API_URL}/Finance/updateBudgetLines`;
      resUpdate=await httpPost(urlforUpdate,payload,{ ...config,});
      resUpdate.then(props.getCurrentPage({ pageUrl, crmId }));
      return resUpdate;
      
    } catch (e) {
      return (resUpdate = { message: "Bad Request" });
    }
  };
  return (
    <ThemeProvider theme={theme}>
      {props.disabled !== null && (
        <div
          className={`${classes.saveButton} ${
            props.disabled
              ? classes.disableSaveButton
              : classes.enableSaveButton
          }`}
        >
          <div className="">
            {props.disabled ? (
              <span className={classes.diffrenceText}>
                Note: Total allocated must match the invoice amount. Difference:{" "}
                <span className={classes.bold}>{props.invoiceDiffrence}</span>
                
                
              </span>
            ) : (
              <span className={classes.messageBarEnabled}>Total Is Matched To The Invoice Amount</span>
            )}
          </div>
          <div className="">
            <A_Button
              label="Save"
              color="primary"
              onClick={handleInvoiceSave}
              disabled={props.disabled}
              // className={classes.button}
            />
          </div>
        </div>
      )}
    </ThemeProvider>
  );
});
export default M_InvoiceSaveButton;
