import * as React from "react";
import A_EnhancedTable from "../../../Atoms/DataDisplay/DataGrid/A_EnhancedTable";
import M_MultiValueList from "../../../Molecules/Common/DealAdministartion/M_MultiValueList";
import M_MultiListDetails from "../../../Molecules/Common/DealAdministartion/M_MultiListDetails";
import {
  getValueFromList,
  arrayOfObjectSortingByKey,
} from "./Helper/GenericList.Helper.js";
import meeting from "../../../../../Assets/Images/Defaults/meetingDef.jpg";
import email from "../../../../../Assets/Images/Defaults/emailicon.png";
import company from "../../../../../Assets/Images/Defaults/defLogo.png";
import * as httpCall from "../../../../../Utils/apis/apis";
import { useParams } from "react-router";
import { Skeleton } from "@material-ui/lab";
import propTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { css } from "./Helper/GenericList.css";
import _ from "lodash";
import { getBulkDocuments } from "../../../Molecules/Announcment/Helper/Announcement.helper";
import A_CustomTable from "./customTabel";
import { containerType } from "../../../../../Utils/config/config";
const useStyles = makeStyles(() => css);
export default function GenericList(props) {
  const { crmId } = useParams();
  const classes = useStyles();
  const [resultData, setResultData] = React.useState([]);
  const [selectedData, setSelectedData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [isNoData, setIsNoData] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [tableData, setTableData] = React.useState();
  const collectCrmIds = async (completeData) => {
    let NewDataSet = [...completeData];
    let items = [];
    let containers = [];
    for (let listData in NewDataSet) {
      if (
        NewDataSet[listData]["title"] === "document" &&
        NewDataSet[listData]["id"]
      ) {
        items.push(NewDataSet[listData]["id"]);
        if (
          !containers.includes(
            NewDataSet[listData]["container"]
              ? NewDataSet[listData]["container"]
              : containerType.GENERIC
          )
        ) {
          containers.push(
            NewDataSet[listData]["container"]
              ? NewDataSet[listData]["container"]
              : containerType.GENERIC
          );
        }
      }
    }
    let payload = containers.map((container) => ({
      items: items,
      type: container,
    }));
    let quickLinkImages = await getBulkDocuments(payload);
    containers.map((container) => {
      quickLinkImages &&
        quickLinkImages[container] &&
        Array.isArray(quickLinkImages[container]) &&
        quickLinkImages[container].map((documentImage) => {
          let BulkCrmId =
            documentImage.file && documentImage.file.split("/")[0];
          NewDataSet.map((listData) => {
            if (listData["id"] === BulkCrmId) {
              listData["title"] = documentImage.link;
            }
          });
        });
    });

    setResultData(NewDataSet);
    fetchData(NewDataSet);
  };
  const fetchResultTemplateData = async () => {
    try {
      setIsLoading(true);
      let finalResultData = [];

      let allPayloads = _.get(props, "content.genericPayloads", []);
      allPayloads = Array.isArray(allPayloads) ? allPayloads : [];
      for (let payloadIndex in allPayloads) {
        let payload = allPayloads[payloadIndex];
        payload = getValueFromList(payload, { crmId: crmId });
        let responseResultData = await httpCall.httpPost(
          "/IRC/getIRCRecord",
          payload
        );
        let templateString = _.get(
          responseResultData,
          "template.templateJson",
          "{}"
        );
        let templateJSON = JSON.parse(templateString);
        let resultData =
          responseResultData && responseResultData.record
            ? responseResultData.record.map((item) =>
                getValueFromList(templateJSON, {
                  ...item,
                  crmId: crmId,
                  dateFormat: templateJSON.dateFormat,
                })
              )
            : [];
        finalResultData = [...finalResultData, ...resultData];
        if (payloadIndex == allPayloads.length - 1) {
          finalResultData = arrayOfObjectSortingByKey(
            finalResultData,
            "sortby"
          ).reverse();
          setResultData(finalResultData);
          collectCrmIds(finalResultData);
          if (finalResultData.length > 0) {
            setSelectedData(finalResultData[0]);
          } else {
            setIsNoData(true);
          }

          setIsLoading(false);
        }
      }
      if (allPayloads.length === 0) {
        setResultData(finalResultData);
        if (finalResultData.length > 0) {
          setSelectedData(finalResultData[0]);
        } else {
          setIsNoData(true);
        }

        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      setIsNoData(true);
    }
  };
  React.useEffect(() => {
    fetchResultTemplateData();
  }, [reload]);

  const handleSelectData = (itemid, isDefault = false) => {
    return selectedData.id === undefined
      ? isDefault
      : selectedData.id === itemid;
  };
  const handleReload = () => {
    setReload(!reload);
  };
  const fetchData = (resultData) => {
    let tableTempData = [];
    for (let item in resultData) {
      let createdData = {
        Data: (
          <M_MultiValueList
            selected={handleSelectData(resultData[item].id, item === "0")}
            {...resultData[item]}
            title={renderIcons(resultData[item].title)}
            data={resultData[item]}
            onClick={handleClick}
            handleDataChange={handleReload}
            content={props.content}
            moreOptions={true}
          ></M_MultiValueList>
        ),
        Heading: resultData[item].heading,
        Subheading: resultData[item].subHeading,
      };
      tableTempData.push(createdData);
    }
    setTableData(tableTempData);
  };
  const handleClick = (record) => {
    setSelectedData(record);
  };
  const renderIcons = (image) => {
    let val;
    switch (image) {
      case "meeting":
        val = meeting;
        break;
      case "company":
        val = company;
        break;
      case "email":
        val = email;
        break;
      default:
        val = image;
        break;
    }
    return val;
  };
  let columnConfiguration = {
    title: "",
    noDataText: "No data available.",
    paginate:
      props.content && props.content.paginate ? props.content.paginate : false,
    searchable:
      props.content && props.content.search ? props.content.search : false,
    sortable: false,
    striped: true,
    dragable: false,

    columnConfig: {
      Data: {
        id: "Data",
        label: "",
        sorting: false,
        hide: false,
        style: {
          padding: "0",
        },
      },
      Heading: {
        id: "Heading",
        label: "",
        sorting: false,
        hide: true,
      },
      Subheading: {
        id: "Subheading",
        label: "",
        sorting: false,
        hide: true,
      },
    },
  };
  React.useEffect(() => {
    fetchData(resultData);
  }, [selectedData]);
  const reloadList = () => {
    fetchResultTemplateData();
  };
  return (
    <>
      {isLoading ? (
        <>
          <Skeleton variant="text" width={80} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="rect" width={"100%"} height={118} />
        </>
      ) : (
        <>
          {isNoData ? (
            <div className={classes.noData}>
              <p>WE ARE SORRY, NO CONTENT FOUND!</p>
            </div>
          ) : (
            <div className={classes.list}>
              <div
                className={
                  props.content && props.content.hideDetails
                    ? classes.fullWidth
                    : classes.activeWidth
                }
              >
                {props.content && props.content.search ? (
                  <A_EnhancedTable
                    minWidth={{ minWidth: "100%" }}
                    configurations={columnConfiguration}
                    data={tableData}
                  ></A_EnhancedTable>
                ) : (
                  <A_CustomTable
                    minWidth={{ minWidth: "100%" }}
                    configurations={columnConfiguration}
                    data={tableData}
                  />
                )}
              </div>
              <div
                className={`${classes.detail} ${
                  props.content && props.content.hideDetails ? classes.hide : ""
                }`}
              >
                <M_MultiListDetails
                  content={props.content}
                  data={selectedData}
                  reload={reloadList}
                ></M_MultiListDetails>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
GenericList.propTypes = {
  content: propTypes.object,
};
