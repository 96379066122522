import React from "react";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import "./A_MultiselectCustom.css";
import { handleOptionSetValue } from "../../../../../Utils/Helpers/optionSet.helper";
export default function A_MultiselectCustom(props) {
  let defValues =
    props && props.data && props.data.defaultValue
      ? props.data.defaultValue
      : "";
  var parsedValue = defValues.replace(/'/g, '"');
  var result = [];
  if (parsedValue != "") {
    result = JSON.parse(parsedValue);
  }
  const [values, setValues] = React.useState(result);
  const [focusedValue, setFocusedValue] = React.useState(-1);
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropDownIsTop, setDropDownIsTop] = React.useState(false);

  const onFocus = (e) => {
    const dropDownIsTop =
      window.innerHeight - e.target.getBoundingClientRect().top - 64 < 240;
    setDropDownIsTop(dropDownIsTop);
  };
  const onBlur = (values) => {
    const { options, multiple } = props;
    if (multiple) {
      setFocusedValue(-1);
      setIsOpen(false);
    } else {
      const value = values[0];
      let focusedValue = -1;
      if (value) {
        focusedValue = options.findIndex((option) => option === value);
      }
      setFocusedValue(focusedValue);
      setIsOpen(false);
    }
  };

  const onKeyDown = (e, isOpen, values, focusedValue) => {
    const { options, multiple } = props;
    switch (e.key) {
      case " ":
        e.preventDefault();
        if (isOpen) {
          if (multiple) {
            if (focusedValue !== -1) {
              const [...valuesNew] = values;
              const value = options[focusedValue];
              const index = valuesNew.indexOf(value);

              if (index === -1) {
                valuesNew.push(value);
              } else {
                valuesNew.splice(index, 1);
              }
              setValues(valuesNew);
            }
          }
        } else {
          setIsOpen(true);
        }
        break;
      case "Escape":
      case "Tab":
        if (isOpen) {
          e.preventDefault();
          setIsOpen(false);
        }
        break;
      case "Enter":
        setIsOpen(!isOpen);

        break;
      case "ArrowDown":
        e.preventDefault();

        setIsOpen(!isOpen);
        break;
      case "ArrowUp":
        e.preventDefault();
        setIsOpen(!isOpen);
        break;
      default:
        setIsOpen(!isOpen);
        break;
    }
  };

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  const onHoverOption = (e) => {
    const { options } = props;
    const { value } = e.currentTarget.dataset;
    const index = options.findIndex((option) => option.value === value);
    setFocusedValue(index);
  };

  const onClickOption = (e, values) => {
    const { multiple, handleTabsSeletion } = props;
    const { value } = e.currentTarget.dataset;
    if (!multiple) {
      handleTabsSeletion([value], props.data.id);
      setValues([value]);
      setIsOpen(false);
    }
    const [...valuesNew] = values;
    const index = values.indexOf(value);
    if (index === -1) {
      valuesNew.push(value);
    } else {
      valuesNew.splice(index, 1);
    }
    handleTabsSeletion(valuesNew, props.data.id);
    setValues(valuesNew);
  };

  const renderValues = (values) => {
    const { multiple } = props;
    if (multiple) {
      return values.map((value) => {
        return (
          <Chip
            key={value}
            label={handleOptionSetValue(value)}
            className="multiselect-chip"
          />
        );
      });
    }
    return <div className="value">{handleOptionSetValue(values[0])}</div>;
  };

  const renderOptions = (isOpen, values, focusedValue) => {
    const { options } = props;
    if (!isOpen) {
      return null;
    }
    return (
      <div className="options">
        {options.map((option, index) => {
          const selected = values
            .map((item) => handleOptionSetValue(item, false))
            .includes(handleOptionSetValue(option.value));
          let className = "option";
          if (selected) className += " selected";
          if (index === focusedValue) className += " focused";
          return (
            <div
              key={option.Id}
              data-value={JSON.stringify(option)}
              className={className}
              onMouseOver={onHoverOption}
              onClick={(e) => onClickOption(e, values)}
            >
              {handleOptionSetValue(option.label)}
            </div>
          );
        })}
      </div>
    );
  };

  const { label } = props;
  return (
    <div
      className={
        values.length > 0
          ? "multiple-select-custom hasValues"
          : "multiple-select-custom"
      }
      tabIndex="0"
      onFocus={onFocus}
      onBlur={() => onBlur(values)}
      onKeyDown={(e) => onKeyDown(e, isOpen, values, focusedValue)}
    >
      <label className="label">{label}</label>
      <div
        className={dropDownIsTop ? "selection top" : "selection"}
        onClick={onClick}
      >
        {renderValues(values)}
        <span className="arrow">
          {isOpen ? <ChevronUp /> : <ChevronDown />}
        </span>
      </div>
      {renderOptions(isOpen, values, focusedValue)}
    </div>
  );
}

// A_MultiselectCustom propTypes;
A_MultiselectCustom.propTypes = {
  label: PropTypes.string.isRequired,
  multiple: PropTypes.element,
  options: PropTypes.array.isRequired,
  handleTabsSeletion: PropTypes.func,
  data: PropTypes.string,
};

// Other arrow components
const ChevronDown = () => (
  <svg viewBox="0 0 10 7">
    <path
      d="M2.08578644,6.5 C1.69526215,6.89052429 1.69526215,7.52368927 2.08578644,7.91421356 C2.47631073,8.30473785 3.10947571,8.30473785 3.5,7.91421356 L8.20710678,3.20710678 L3.5,-1.5 C3.10947571,-1.89052429 2.47631073,-1.89052429 2.08578644,-1.5 C1.69526215,-1.10947571 1.69526215,-0.476310729 2.08578644,-0.0857864376 L5.37867966,3.20710678 L2.08578644,6.5 Z"
      transform="translate(5.000000, 3.207107) rotate(90.000000) translate(-5.000000, -3.207107) "
    />
  </svg>
);
const ChevronUp = () => (
  <svg viewBox="0 0 10 8">
    <path
      d="M2.08578644,7.29289322 C1.69526215,7.68341751 1.69526215,8.31658249 2.08578644,8.70710678 C2.47631073,9.09763107 3.10947571,9.09763107 3.5,8.70710678 L8.20710678,4 L3.5,-0.707106781 C3.10947571,-1.09763107 2.47631073,-1.09763107 2.08578644,-0.707106781 C1.69526215,-0.316582489 1.69526215,0.316582489 2.08578644,0.707106781 L5.37867966,4 L2.08578644,7.29289322 Z"
      transform="translate(5.000000, 4.000000) rotate(-90.000000) translate(-5.000000, -4.000000) "
    />
  </svg>
);
