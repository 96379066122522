import * as React from "react";
import M_Tabs from "../../../../Molecules/Common/Tabs/M_Tabs";
import M_TabsRemark from "../../../../Molecules/Common/Tabs/M_TabsRemark";
import M_TabsView from "../../../../Molecules/Common/Tabs/M_TabsView";
import M_TabsResponse from "../../../../Molecules/Common/Tabs/M_TabsResponse";
import M_TabsDocument from "../../../../Molecules/Common/Tabs/M_TabsDocument";
import { epochToDateFormatHelper } from "../../../../../../Utils/Helpers/dateUtils";
import M_TabsCheckList from "../../../../Molecules/Common/Tabs/M_TabsCheckList";
import * as httpCall from "../../../../../../Utils/apis/apis";
import ResultDocDownload from "../../../../Molecules/Common/Renderers/Views/PreviousTemplate/ResultDocDownload";
import { handleOptionSetValue } from "../../../../../../Utils/Helpers/optionSet.helper";
import { dateFormat } from "../../../../../../Utils/config/config";
export const arrayOfObjectSortingByKey = (List, key) => {
  try {
    let sortedlist = Array.isArray(List)
      ? List.sort((pre, next) => {
          return pre[key] - next[key];
        })
      : List;
    return sortedlist;
  } catch (e) {
    return List;
  }
};
export const getValueFromList = (resultData, valueList) => {
  let finalValueList = Array.isArray(resultData)
    ? [...resultData]
    : typeof resultData === "object"
    ? {
        ...resultData,
      }
    : resultData;
  if (typeof finalValueList === "object" || Array.isArray(finalValueList)) {
    for (let item in finalValueList) {
      switch (typeof resultData[item]) {
        case "string":
          finalValueList[item] = handleStringValue(
            finalValueList[item],
            valueList
          );
          break;
        case "object":
          finalValueList[item] = getValueFromList(
            finalValueList[item],
            valueList
          );
          break;
        default:
          if (Array.isArray(resultData[item])) {
            finalValueList[item] = [
              ...getValueFromList(finalValueList[item], valueList),
            ];
          }
          break;
      }
    }
  }
  return finalValueList;
};

export const handleStringValue = (value, valueList) => {
  if (value.charAt(0) === "=") {
    let newValue = value.substring(1, value.length);
    if (newValue.toLowerCase().indexOf(".epochtodatewithtime") > -1) {
      newValue = newValue.substring(
        0,
        newValue.toLowerCase().indexOf(".epochtodate")
      );
      newValue = valueList && valueList[newValue] ? valueList[newValue] : "";
      newValue = epochToDateFormatHelper(
        newValue,
        valueList["dateFormat"]
          ? `${valueList["dateFormat"]} HH:mm`
          : dateFormat.defaultDateTime
      );
    } else if (newValue.toLowerCase().indexOf(".epochtodate") > -1) {
      newValue = newValue.substring(
        0,
        newValue.toLowerCase().indexOf(".epochtodate")
      );
      newValue = valueList && valueList[newValue] ? valueList[newValue] : "";
      newValue = epochToDateFormatHelper(
        newValue,
        valueList["dateFormat"]
          ? valueList["dateFormat"]
          : dateFormat.defaultDate
      );
    } else if (newValue.toLowerCase().indexOf(".optionset") > -1) {
      newValue = newValue.substring(
        0,
        newValue.toLowerCase().indexOf(".optionset")
      );
      newValue =
        valueList && valueList[newValue]
          ? handleOptionSetValue(valueList[newValue])
          : "";
    } else {
      newValue =
        valueList && valueList[newValue]
          ? handleOptionSetValue(valueList[newValue])
          : "";
    }
    return newValue;
  } else {
    return value;
  }
};
export const checkIsCheckListAvailable = async (id) => {
  try {
    if (id && id != 0) {
      let checklistContent = {};
      const response = await httpCall.httpGet(
        `/DealChecklist/GetDealChecklist/${id}?WorkFlow=OnBoarding`
      );
      let responseData = await response;

      let filterData =
        responseData && Array.isArray(responseData)
          ? responseData.filter((item) => {
              return (
                item.dealChecklist &&
                item.dealChecklist.checklistCompleted == false
              );
            })
          : [];
      checklistContent["hasCheckList"] =
        responseData && Array.isArray(responseData) && responseData.length > 0;
      checklistContent["isCompleted"] =
        filterData.length == 0 && responseData.length > 0;
      checklistContent["checklistData"] = responseData ? responseData : [];
      return checklistContent;
    } else {
      return {
        hasCheckList: false,
        isCompleted: false,
        checklistData: [],
      };
    }
  } catch (error) {
    return {
      hasCheckList: false,
      isCompleted: false,
      checklistData: [],
    };
  }
};
export const hasCheckList = (config) => {
  let response = false;
  for (let element in config) {
    if (element === "checklist") {
      response = true;
      break;
    }
  }
  return response;
};

export const getComponentFromConfig = (
  config,
  data = null,
  content = null,
  reload
) => {
  let componentBuffer = [];
  for (let element in config) {
    switch (element) {
      case "tabs":
        componentBuffer.push(
          <>
            <M_Tabs
              content={content ? content : {}}
              tabData={
                config[element]
                  ? config[element].filter((element) => {
                      if (
                        element &&
                        element.validate &&
                        Array.isArray(element.validate)
                      ) {
                        let isHide = element.validate.join("").trim() === "";
                        return !isHide;
                      } else {
                        return true;
                      }
                    })
                  : []
              }
              reload={reload}
            ></M_Tabs>
          </>
        );
        break;
      case "views":
        componentBuffer.push(
          <>
            <M_TabsView
              content={content ? content : {}}
              viewData={config[element]}
            ></M_TabsView>
          </>
        );
        break;
      case "remark":
        componentBuffer.push(
          <M_TabsRemark
            content={content ? content : {}}
            {...config}
          ></M_TabsRemark>
        );
        break;
      case "response":
        componentBuffer.push(
          <M_TabsResponse
            content={content ? content : {}}
            {...config}
          ></M_TabsResponse>
        );
        break;
      case "checklist":
        componentBuffer.push(
          <M_TabsCheckList
            content={content ? content : {}}
            checkListData={data}
            reload={reload}
            {...config}
          ></M_TabsCheckList>
        );
        break;
      case "document":
        componentBuffer.push(
          <ResultDocDownload
            content={content ? content : {}}
            {...config}
          ></ResultDocDownload>
        );
        break;
      case "attachments":
        componentBuffer.push(
          <M_TabsDocument
            content={content ? content : {}}
            {...config}
          ></M_TabsDocument>
        );
        break;
      default:
        componentBuffer.push(<div></div>);
        break;
    }
  }
  return componentBuffer;
};

export const recordSoftDelete = async (recordId, pageUrl, onDelete, modify) => {
  let resultMessage;
  if (modify == true) {
    onDelete && onDelete();
    return modify;
  } else {
    resultMessage = await httpCall.httpDelete(
      `/crmdata/delete?pageUrl=${pageUrl}&&crmId=${recordId}&&softDelete=true`
    );
    onDelete && onDelete();
    return resultMessage;
  }
};
