/* eslint-disable */
import { take, put, select } from "redux-saga/effects";
import axios from "axios";
import { manageMenusActionType } from "../actions/actionTypes";
import * as manageMenusAction from "../actions/manageMenusAction";
import * as commonFunctions from "../../Utils/Helpers/common";

export function* getMenusSagas() {
  while (true) {
    const action = yield take(manageMenusActionType.GET_MENUS);
    const state = yield select();
    let config = commonFunctions.getHttpConfig();

    if (
      (!config.headers.Authorization ||
        config.headers.Authorization == "Bearer undefined") &&
      action &&
      action.payload &&
      action.payload &&
      action.payload.access_token
    ) {
      config.headers.Authorization = "Bearer " + action.payload.access_token;
    }

    try {
      const apiUrl = `${process.env.API_URL}/menuItems/getUIMenu`;
      const res = yield axios.get(apiUrl, config);
      yield put(
        manageMenusAction.getMenusSucceeded({
          data: res.data.menu,
          getMenusSucceeded: true,
        })
      );
    } catch (e) {
      yield put(
        manageMenusAction.getMenusFailed({
          msg: "Some error occurred",
          getMenusFailed: true,
        })
      );
    }
  }
}
